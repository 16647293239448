import styled from 'styled-components/macro'

export const Row = styled.tr`
box-shadow: ${({ theme }) => theme.shadow1};
background: ${({ theme }) => theme.deprecated_bg2};
width: 100%;
margin: 0px;
`
export const Cell = styled.td`
&:first-child {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
&:last-child {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  width: 100px;
}
padding: 15px;
height: 65px;
`
export const YieldBox = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: space-between;
width: 150px;
`

export const BorrowBox = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: space-between;
`
export const BorrowField = styled.div`
display: flex;
flex-direction: row;
align-self: left;
justify-content: space-between;
align-items: space-between;
font-size: 14px;
max-width: 120px;
`

export const YieldRow = styled.div`
display: flex;
flex-direction: row;
align-self: left;
justify-content: space-between;
align-items: space-between;
font-size: 14px;
max-width: 120px;
`

export const Circle = styled.div<{ active: boolean }>`
height: 26px;
width: 26px;
border-radius: 50%;
transition: 100ms ease-in;
opacity: ${({ active }) => (active ? 1 : 0)};
display: flex;
align-items: center;
justify-content: center;
border-radius: 1px solid blue;
background: ${({ theme }) => theme.accentAction};
&:hover {
  opacity: ${({ active }) => (active ? 1 : 0.5)};
}
`

export const CheckboxWrapper = styled.div`
border-radius: 50%;
border: 1px solid ${({ theme }) => theme.accentActionSoft};
width: 26px;
height: 26px;
display: flex;
align-items: center;
justify-content: center;
transition: 100ms ease-in;
&:hover {
  border: 1px solid ${({ theme }) => theme.accentAction};
  cursor: pointer;
}
`
export const ToggleContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-end;
`
