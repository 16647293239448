import { BigNumber } from "ethers";
import { SupportedAssets, TokenMeta } from "types/1delta";
import { default as wethLogoUrl } from 'assets/svg/tokens/weth.svg'
import { default as daiLogoUrl } from 'assets/svg/tokens/dai.svg'
import { default as linkLogoUrl } from 'assets/svg/tokens/link.svg'
import { default as usdcLogoUrl } from 'assets/svg/tokens/usdc.svg'
import { default as wbtcLogoUrl } from 'assets/svg/tokens/wbtc.svg'
import { default as usdtLogoUrl } from 'assets/svg/tokens/usdt.svg'
import { default as aaveLogoUrl } from 'assets/svg/tokens/aave.svg'
import { default as eursLogoUrl } from 'assets/svg/tokens/eurs.svg'
import { default as wmaticLogoUrl } from 'assets/svg/tokens/wmatic.svg'

export const TEN = BigNumber.from(10)

export const ZERO_BN = BigNumber.from(0)

export const LTV_PRECISION = TEN.pow(8)

export const AAVE_PRICE_PRECISION = TEN.pow(8)


export const BPS_BN = BigNumber.from(10000)


export const TOKEN_META: { [key in SupportedAssets]: TokenMeta } = {
  [SupportedAssets.WETH]: { symbol: 'WETH', name: 'Wrapped Ethereum', decimals: 18 },
  [SupportedAssets.DAI]: { symbol: 'DAI', name: 'Dai', decimals: 18 },
  [SupportedAssets.LINK]: { symbol: 'LINK', name: 'ChainLink', decimals: 18 },
  [SupportedAssets.USDC]: { symbol: 'USDC', name: 'USD Coin', decimals: 6 },
  [SupportedAssets.WBTC]: { symbol: 'WBTC', name: 'Wrapped Bitcoin', decimals: 8 },
  [SupportedAssets.USDT]: { symbol: 'USDT', name: 'Tether', decimals: 6 },
  [SupportedAssets.AAVE]: { symbol: 'AAVE', name: 'AAVE', decimals: 18 },
  [SupportedAssets.EURS]: { symbol: 'EURS', name: 'EURS', decimals: 2 },
  [SupportedAssets.WMATIC]: { symbol: 'WMATIC', name: 'WMATIC', decimals: 18 },
  [SupportedAssets.AGEUR]: { symbol: 'AGEUR', name: 'AGEUR', decimals: 6 },
  [SupportedAssets.BAL]: { symbol: 'BAL', name: 'Balancer', decimals: 18 },
  [SupportedAssets.CRV]: { symbol: 'CRV', name: 'Curve', decimals: 18 },
  [SupportedAssets.DPI]: { symbol: 'DPI', name: 'DPI', decimals: 18 },
  [SupportedAssets.GHST]: { symbol: 'GHST', name: 'GHST', decimals: 18 },
  [SupportedAssets.JEUR]: { symbol: 'JEUR', name: 'JEUR', decimals: 18 },
  [SupportedAssets.SUSHI]: { symbol: 'SUSHI', name: 'SUSHI', decimals: 18 },
}


export const TOKEN_SVGS: { [key in SupportedAssets]: string } = {
  [SupportedAssets.WETH]: wethLogoUrl,
  [SupportedAssets.DAI]: daiLogoUrl,
  [SupportedAssets.LINK]: linkLogoUrl,
  [SupportedAssets.USDC]: usdcLogoUrl,
  [SupportedAssets.WBTC]: wbtcLogoUrl,
  [SupportedAssets.USDT]: usdtLogoUrl,
  [SupportedAssets.AAVE]: aaveLogoUrl,
  [SupportedAssets.EURS]: eursLogoUrl,
  [SupportedAssets.WMATIC]: wmaticLogoUrl,
  [SupportedAssets.AGEUR]: '',
  [SupportedAssets.BAL]: '',
  [SupportedAssets.CRV]: '',
  [SupportedAssets.DPI]: '',
  [SupportedAssets.GHST]: '',
  [SupportedAssets.JEUR]: '',
  [SupportedAssets.SUSHI]: '',
}

export const getTokenIcon = (asset: SupportedAssets): string => (TOKEN_SVGS[asset])

export const getAssets = (chainId: number): SupportedAssets[] => {
  switch (chainId) {
    case 5:
      return [
        SupportedAssets.WETH,
        SupportedAssets.DAI,
        SupportedAssets.LINK,
        SupportedAssets.USDC,
        SupportedAssets.WBTC,
        SupportedAssets.USDT,
        SupportedAssets.AAVE,
        SupportedAssets.EURS,
        // SupportedAssets.WMATIC
      ]
    case 80001:
      return [
        SupportedAssets.AAVE,
        SupportedAssets.AGEUR,
        SupportedAssets.BAL,
        SupportedAssets.CRV,
        SupportedAssets.DAI,
        SupportedAssets.DPI,
        SupportedAssets.EURS,
        SupportedAssets.GHST,
        SupportedAssets.JEUR,
        SupportedAssets.LINK,
        SupportedAssets.SUSHI,
        SupportedAssets.USDC,
        SupportedAssets.USDT,
        SupportedAssets.WBTC,
        SupportedAssets.WETH,
        SupportedAssets.WMATIC,
      ]
    default:
      return [
        SupportedAssets.WETH,
        SupportedAssets.DAI,
        SupportedAssets.LINK,
        SupportedAssets.USDC,
        SupportedAssets.WBTC,
        SupportedAssets.USDT,
        SupportedAssets.AAVE,
        SupportedAssets.EURS,
        // SupportedAssets.WMATIC
      ]
  }
}