import { useWeb3React } from '@web3-react/core'
import Modal from 'components/Modal'
import { getAssets, TEN } from 'constants/1delta'
import { BigNumber, BigNumberish, ethers } from 'ethers'
import { safeGetToken } from 'hooks/1delta/tokens'
import useMachineTimeMs from 'hooks/useMachineTime'
import { useEffect, useMemo, useState } from 'react'
import {
  fetchAAVEAggregatorDataAsync,
  fetchAAVELiquidityDataAsync,
  fetchAAVEPublicDataAsync,
  fetchAAVEReserveConfigDataAsync,
  fetchAAVEReserveDataAsync,
} from 'state/1delta/fetchAAVEPublicData'
import { fetchAAVEUserDataAsync, fetchAAVEUserReserveDataAsync } from 'state/1delta/fetchAAVEUserData'
import { calculateRiskChangeMarginTrade, useDeltaState, useGetAaveRiskParameters } from 'state/1delta/hooks'
import { useAppDispatch } from 'state/hooks'
import styled from 'styled-components/macro'
import { getAssetsOnSide, getMarginTradeType, getSinglePositionEntry, getTradeTypeDescription, handleSelectNewAsset, handleUnSelectAsset, MarginTradeState, OneDeltaTradeType, PositionDirection, positionEntriesAreMatching, PositionEntry, PositionSides, SupportedAssets } from 'types/1delta'

import { ReactComponent as CloseIcon } from '../assets/images/x.svg'
import { ButtonPrimary, ButtonSecondary } from '../components/Button'
import BorrowTableColumn from './MarketTable/BorrowTable'
import CollateralTableColumn from './MarketTable/CollateralTable'
import MarginTradeModal from './Swap/components/marginTradeModal'
import MarginTrade from './Swap/MarginTrade'
import MoneyMarketSwap from './Swap/MoneyMarket'
import SingleSideSwap from './Swap/SingleSideTrade'

const ModalContainer = styled.div`
  padding: 10px;
  padding-bottom: 20px;
  width: 100%;
`

const Close = styled.a`
  padding: 5px;
  color: ${({ theme }) => theme.accentActionSoft};
  transition: 150ms ease-in;
  &:hover {
    color: ${({ theme }) => theme.hoverState};
    cursor: pointer;
  }
`

const Container = styled.div`
  width: 100vw;
`

const TopContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const AccountContent = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin-bottom: 20px;
  max-width: 1000px;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex-direction: column;
  `};
`

const Column = styled.div`
  padding: 14px;
  display: flex;
  flex-direction: column;
  grid-gap: 28px;
  gap: 28px;
  width: 28%;
  height: 100%;
  justify-content: center;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    width: unset;
  `};
`

const AccountCard = styled.div`
  width: 100%;
  max-width: 300px;
  box-shadow: ${({ theme }) => theme.shadow1};
  border-radius: 7px;
  height: 50%;
  background: ${({ theme }) => theme.deprecated_bg2};
  text-align: left;
  padding: 1.75rem;
  display: flex;
  flex-direction: column;
  height: 140px;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    width: 95vw;
    height: 120px;
    max-width: unset;
    padding: 1.25rem;
  `};
`

const AccountCardSpacer = styled(AccountCard)`
  background: transparent;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    height: 0px;
    padding: 0px;
  `};
`

const AccountCardHeading = styled.span`
  color: ${({ theme }) => theme.textSecondary};
`

const AccountCardvalue = styled.span`
  color: ${({ theme }) => theme.textPrimary};
  opacity: 0.8;
  font-size: 24px;
  font-weight: 500;
  margin-top: 10px;
`

const ProgressWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
  height: 20px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.backgroundOutline};
  position: relative;
`

const Progress = styled.div<{ type: 'limit' | 'health' | 'ltv'; percentageString?: string }>`
  height: 20px;
  border-radius: 20px;
  background-color: ${({ theme, type }) =>
    type === 'health' ? theme.accentSuccess : type === 'limit' ? theme.accentWarning : theme.deprecated_primary1};
  width: ${({ percentageString }) => percentageString ?? '0%'};
`

const ProgressValue = styled.span`
  color: ${({ theme }) => theme.textPrimary};
  opacity: 0.8;
  font-size: 18px;
  font-weight: 500;
  padding-top: 7px;
`

const ButtonContainer = styled.div`
  width: 810px;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    width: 650px;
  `};
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    width: 95vw;
  `};
  display: flex;
  flex-direction: row;
`

const MarketTableSection = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex-direction: column;
  `};

  margin-top: 40px;
`
const MarketTableContainer = styled.div`
  width: 50vw;
  max-width: 600px;
  margin 10px;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    width: 95%;
    margin: auto;
    max-width: unset;
    margin-bottom: 30px
  `};
`

const MarketTableTitle = styled.div`
  margin: 10px 20px 0px 20px;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    margin: 10px 15px 0px 15px;
  `};
`
const MarketTableTitleText = styled.h2`
  text-align: center;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 24px;
  font-weight: 500;
  margin: 0;
`

const MarketTableWrapper = styled.div`
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

const ButtonTextBox = styled.div`
  letter-spacing: 2px;
  font-weight: bold;
  font-size: 14px;
`

function bigNumberToDecimal(bn: BigNumberish, decimals: number, displayDecimals = 4): number {
  const scalar = Math.pow(10, displayDecimals)
  return Math.round(Number(ethers.utils.formatEther(BigNumber.from(bn).mul(TEN.pow(18 - decimals)))) * scalar) / scalar
}

const defaultState: MarginTradeState = {
  [PositionDirection.To]: undefined,
  [PositionDirection.From]: undefined,
  isSingle: false
}

export default function Home() {
  const { chainId, account } = useWeb3React()
  const [isEditingPosition, setEditingPosition] = useState(false)
  const [position, setPosition] = useState<MarginTradeState>(defaultState)

  const [isSwapModalVisible, setSwapModalVisible] = useState(false)

  const deltaState = useDeltaState()

  const isPositionValid = position[PositionDirection.From] || position[PositionDirection.To]
  const handlePositionEditing = () => {
    if (!isEditingPosition) {
      return setEditingPosition(true)
    } else {
      if (isPositionValid) confirmPosition()
    }
  }

  const handleCancelPositionEditing = () => {
    setEditingPosition(false)
    setPosition(defaultState)
  }

  const handleToggleAsset = (type: PositionSides, asset: SupportedAssets) => {
    let newPos: MarginTradeState
    if (positionEntriesAreMatching({ asset, side: type }, position[PositionDirection.From]) ||
      positionEntriesAreMatching({ asset, side: type }, position[PositionDirection.To])) {
      newPos = handleUnSelectAsset(position, asset, type)
    } else {
      newPos = handleSelectNewAsset(position, asset, type)
    }
    setPosition(newPos)
  }

  const confirmPosition = () => {
    setSwapModalVisible(true)
    // set model visible
  }

  const handleSwapModelDismissed = () => {
    setSwapModalVisible(false)
  }

  const dispatch = useAppDispatch()

  useEffect(() => {
    // fetch debt tokens and public metadata
    if (!deltaState.loadingState.publicDataLoaded) {
      dispatch(fetchAAVEPublicDataAsync({ chainId: 5 }))
    }

    // fetch liquidity data
    if (!deltaState.loadingState.liquidityLoaded) {
      dispatch(fetchAAVELiquidityDataAsync({ chainId: 5 }))
    }
    // fetch prices
    if (!deltaState.loadingState.pricesLoaded) {
      dispatch(fetchAAVEAggregatorDataAsync({ chainId: 5 }))
    }
    // user info
    if (account && !deltaState.loadingState.userDataLoaded) {
      dispatch(fetchAAVEUserDataAsync({ chainId: 5, account }))
      dispatch(fetchAAVEUserReserveDataAsync({ chainId: 5, account, assetsToQuery: getAssets(5) }))
    }

    if (!deltaState.loadingState.configLoaded) {
      dispatch(fetchAAVEReserveConfigDataAsync({ chainId: 5, assetsToQuery: getAssets(5) }))
    }
    if (!deltaState.loadingState.reservesLoaded) {
      dispatch(fetchAAVEReserveDataAsync({ chainId: 5, assetsToQuery: getAssets(5) }))
    }
  })

  const [repeater, setRepeater] = useState(0)

  useEffect(() => {

    if (account) {
      dispatch(fetchAAVEUserDataAsync({ chainId: 5, account }))
      dispatch(fetchAAVEUserReserveDataAsync({ chainId: 5, account, assetsToQuery: getAssets(5) }))
    }

    setTimeout(() => setRepeater(prevState => prevState + 1), 50000);
  }, [repeater])


  const buttonText = useMemo(() => {
    return getTradeTypeDescription(position).toLocaleUpperCase()
  }, [position])

  const [marginTradeType, entryFrom, entryTo] = useMemo(() => {
    const tradeType = getMarginTradeType(position)
    if (tradeType === OneDeltaTradeType.None) {
      return [OneDeltaTradeType.None, {} as PositionEntry, {} as PositionEntry]
    }
    if (tradeType === OneDeltaTradeType.Single) {
      const singleEntry = getSinglePositionEntry(position)
      return [OneDeltaTradeType.Single, singleEntry as PositionEntry, singleEntry as PositionEntry]
    }
    const { [PositionDirection.To]: toEntry, [PositionDirection.From]: fromEntry } = position
    if (tradeType === OneDeltaTradeType.SingleSide) {
      return [OneDeltaTradeType.SingleSide, fromEntry as PositionEntry, toEntry as PositionEntry]
    }

    return [OneDeltaTradeType.MarginSwap, fromEntry as PositionEntry, toEntry as PositionEntry]
  },
    [position]
  )

  const healthFactor = useMemo(() => {
    return Math.round(bigNumberToDecimal(deltaState?.userState?.totals?.healthFactor, 18, 4) * 100) / 100
  }, [deltaState.userState?.totals?.healthFactor])

  const ltv = useMemo(() => {
    return Math.round(bigNumberToDecimal(deltaState.userState.totals.totalDebtBase, 8, 2) /
      bigNumberToDecimal(deltaState.userState.totals.totalCollateralBase, 8, 2) * 10000) / 100
  }, [deltaState.userState?.totals])


  const borrowLimit = useMemo(() => {
    return Math.round(Number(deltaState.userState.totals.ltv)) / 100
  }, [deltaState.userState?.totals])

  // redux devTools sometimes crashes - use this to see the state
  // console.log("ASSET DATA", deltaState.assets)
  return (
    <Container>
      <TopContainer>
        <AccountContent>
          <Column>
            <AccountCard>
              <AccountCardHeading>Your Supplied Balance</AccountCardHeading>
              <AccountCardvalue>
                ${bigNumberToDecimal(deltaState.userState.totals.totalCollateralBase, 8, 2)}
              </AccountCardvalue>
            </AccountCard>
            <AccountCard>
              <AccountCardHeading>Your Borrowed Balance</AccountCardHeading>
              <AccountCardvalue>
                ${bigNumberToDecimal(deltaState.userState.totals.totalDebtBase, 8, 2)}
              </AccountCardvalue>
            </AccountCard>
          </Column>
          <Column>
            <AccountCard>
              <AccountCardHeading>Borrow Limit</AccountCardHeading>
              <ProgressWrapper>
                <Progress type="limit" percentageString={`${borrowLimit}%`} />
              </ProgressWrapper>
              <ProgressValue>{borrowLimit}%</ProgressValue>
            </AccountCard>
            <AccountCard>
              <AccountCardHeading>Health Factor</AccountCardHeading>
              <ProgressWrapper>
                <Progress type="health" percentageString={`${(healthFactor - 1) / healthFactor * 100}%`} />
              </ProgressWrapper>
              <ProgressValue>{healthFactor}</ProgressValue>
            </AccountCard>
          </Column>
          <Column>
            <AccountCard>
              <AccountCardHeading>Loan-to-Value</AccountCardHeading>
              <ProgressWrapper>
                <Progress type="ltv" percentageString={`${ltv}%`} />
              </ProgressWrapper>
              <ProgressValue>{ltv}%</ProgressValue>{' '}
            </AccountCard>
            <AccountCardSpacer></AccountCardSpacer>
          </Column>
        </AccountContent>
        <ButtonContainer>
          {isEditingPosition && (
            <ButtonSecondary
              disabled={false}
              padding="16px 16px"
              width="220px"
              $borderRadius="12px"
              mt="1rem"
              marginRight="15px"
              onClick={handleCancelPositionEditing}
            >
              Cancel
            </ButtonSecondary>
          )}
          <ButtonPrimary
            disabled={isEditingPosition && !isPositionValid}
            padding="16px 16px"
            width="100%"
            $borderRadius="12px"
            mt="1rem"
            onClick={handlePositionEditing}
          >
            <ButtonTextBox>
              {!isEditingPosition ? 'START TRADING' : buttonText}
            </ButtonTextBox>
          </ButtonPrimary>
        </ButtonContainer>
      </TopContainer>
      <MarketTableSection>
        <MarketTableContainer>
          <MarketTableTitle>
            <MarketTableTitleText>Collateral Market</MarketTableTitleText>
          </MarketTableTitle>
          <MarketTableWrapper>
            <CollateralTableColumn
              assets={deltaState.assets}
              isEditing={isEditingPosition}
              toggleAsset={(asset: SupportedAssets) => handleToggleAsset(PositionSides.Collateral, asset)}
              position={getAssetsOnSide(position, PositionSides.Collateral)}
            />
          </MarketTableWrapper>
        </MarketTableContainer>
        <MarketTableContainer>
          <MarketTableTitle>
            <MarketTableTitleText>Borrow Market</MarketTableTitleText>
          </MarketTableTitle>
          <MarketTableWrapper>
            <BorrowTableColumn
              assets={deltaState.assets}
              isEditing={isEditingPosition}
              toggleAsset={(asset: SupportedAssets) => handleToggleAsset(PositionSides.Borrow, asset)}
              position={getAssetsOnSide(position, PositionSides.Borrow)}
            />
          </MarketTableWrapper>
        </MarketTableContainer>
      </MarketTableSection>
      <MarginTradeModal isOpen={isSwapModalVisible} onDismiss={handleSwapModelDismissed} maxHeight={90} minWidth={marginTradeType === OneDeltaTradeType.MarginSwap ? 900 : 450}>
        <ModalContainer>
          <Close onClick={handleSwapModelDismissed}>
            <CloseIcon />
          </Close>
          {
            // the variablews passed here are dummies
          }
          {(marginTradeType === OneDeltaTradeType.Single) &&
            <MoneyMarketSwap side={entryFrom.side} selectedAsset={safeGetToken(chainId ?? 5, entryFrom.asset)} />
          }
          {(marginTradeType === OneDeltaTradeType.SingleSide) &&
            <SingleSideSwap
              side={entryFrom.side}
              selectedAsset0={safeGetToken(chainId ?? 5, entryFrom.asset)}
              selectedAsset1={safeGetToken(chainId ?? 5, entryTo.asset)}
            />
          }
          {(marginTradeType === OneDeltaTradeType.MarginSwap) &&
            <MarginTrade
              selectedAssetCollateral={safeGetToken(chainId ?? 5, entryFrom.side === PositionSides.Collateral ? entryFrom.asset : entryTo.asset)}
              selectedAssetBorrow={safeGetToken(chainId ?? 5, entryFrom.side === PositionSides.Collateral ? entryTo.asset : entryFrom.asset)}
            />
          }
        </ModalContainer>
      </MarginTradeModal>
    </Container>
  )
}
