import { ethers } from "ethers"

export const formatPriceString = (price?: string) => (price ? formateDecimal(Number(price)) : '-')

export const formatAbbrivatedPrice = (amount?: number) => {
  if (!amount) return '-'
  const n = Number(amount)
  if (n < 1e3) return formateDecimal(n)
  if (n >= 1e3 && n < 1e6) return formateDecimal(n / 1e3) + 'K'
  if (n >= 1e6 && n < 1e9) return formateDecimal(n / 1e6) + 'M'
  if (n >= 1e9 && n < 1e12) return formateDecimal(n / 1e9) + 'B'
  if (n >= 1e12 && n < 1e15) return formateDecimal(n / 1e12) + 'T'
  if (n >= 1e15 && n < 1e18) return formateDecimal(n / 1e15) + 'P'
  if (n >= 1e18) return formateDecimal(n / 1e18) + 'E'
  return '-'
}

const formateDecimal = (amount: number) =>
  amount.toLocaleString('en-EN', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

export const formatAaveYield = (n: string): string => {
  return (Math.round(
    Number(ethers.utils.formatEther(
      ethers.BigNumber.from(n).div(1e7)
    )) * 100) / 100).toLocaleString()
}
