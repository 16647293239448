import { useWeb3React } from '@web3-react/core'
import { DeltaState } from 'state/1delta/reducer'
import styled from 'styled-components/macro'
import { Asset, SupportedAssets } from 'types/1delta'

import MarketTableRow from './BorrowTableRow'

const Table = styled.table`
  border-spacing: 0 1em;
  width: 100%;
`
const TableHeader = styled.thead``
const TableHeaderRow = styled.tr``
const TableHeaderItem = styled.th<{ isEditing?: boolean }>`
  color: ${({ theme }) => theme.textSecondary};
  font-weight: 400;
  text-align: left;
  padding: 15px;
  &:first-child {
    width: ${({ isEditing }) => (isEditing ? '75px' : 'auto')};
  }
`
const TableBody = styled.tbody``

interface MarketTabProps {
  isEditing?: boolean
  assets: DeltaState['assets']
  toggleAsset: (asset: SupportedAssets) => void
  position: SupportedAssets[]
}

export default function BorrowTableColumn({ isEditing, assets, position, toggleAsset }: MarketTabProps) {
  // const test = CurrencyAmount.fromRawAmount(new Token(1, '', 6, 'USDC'), JSBI.BigInt(1e10))
  // test.toSignificant(6)
  const { account } = useWeb3React()
  const assetList: Asset[] = Object.keys(assets).map((key) => assets[key as any as SupportedAssets])
  return (
    <Table>
      <TableHeader>
        <TableHeaderRow>
          {isEditing && <TableHeaderItem isEditing>Select</TableHeaderItem>}
          <TableHeaderItem>Asset</TableHeaderItem>
          <TableHeaderItem>Yields</TableHeaderItem>
          {account && <TableHeaderItem>Borrowed</TableHeaderItem>}
          <TableHeaderItem>Liquidity</TableHeaderItem>
        </TableHeaderRow>
      </TableHeader>
      <TableBody>
        {assetList.map((asset, index) => (
          <MarketTableRow
            key={index}
            asset={asset}
            isEditing={isEditing}
            isCheckEnabled={position.includes(asset.id)}
            onCheckMarkToggle={() => toggleAsset(asset.id)}
          />
        ))}
      </TableBody>
    </Table>
  )
}
