import { Trans } from '@lingui/macro'
import { RouteV3, Trade } from '@uniswap/router-sdk'
import { Currency, CurrencyAmount, Token, TradeType } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { sendAnalyticsEvent } from 'analytics'
import { ElementName, Event, EventName, PageName, SectionName } from 'analytics/constants'
import { Trace } from 'analytics/Trace'
import { TraceEvent } from 'analytics/TraceEvent'
import { sendEvent } from 'components/analytics'
import CurrencyInputCustomListLeft from 'components/CurrencyInputPanel/CustomListInputPanel/CurrencyInputCustomListLeft'
import { NetworkAlert } from 'components/NetworkAlert/NetworkAlert'
import PriceImpactWarning from 'components/swap/PriceImpactWarning'
import SwapDetailsDropdown from 'components/swap/SwapDetailsDropdown'
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
import { MouseoverTooltip } from 'components/Tooltip'
import { isSupportedChain } from 'constants/chains'
import { ethers } from 'ethers'
import { NavBarVariant, useNavBarFlag } from 'featureFlags/flags/navBar'
import { RedesignVariant, useRedesignFlag } from 'featureFlags/flags/redesign'
import { getAaveTokens } from 'hooks/1delta/tokens'
import { getMarginTraderContract } from 'hooks/1delta/use1DeltaContract'
import { useApproveCallbackFromGeneralTrade, useDelegateBorrowCallbackFromGeneralTrade } from 'hooks/use1DeltaMarginSwapCallback'
import { useSwapCallback } from 'hooks/useSwapCallback'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import JSBI from 'jsbi'
import { useCurrencyBalances } from 'lib/hooks/useCurrencyBalance'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ReactNode } from 'react'
import { ArrowDown, ArrowLeft, ArrowRight, Box, CheckCircle, Circle, HelpCircle } from 'react-feather'
import { Text } from 'rebass'
import { fetchAAVEUserReserveDataAsync } from 'state/1delta/fetchAAVEUserData'
import { calculateRiskChangeMarginTrade, useDeltaAssetState, useGetAaveRiskParameters } from 'state/1delta/hooks'
import { useToggleWalletModal } from 'state/application/hooks'
import { useAppDispatch } from 'state/hooks'
import { TradeState } from 'state/routing/types'
import { AaveInterestMode, LendingProtocolInteraction, MarginTradeType, PositionSides, SupportedAssets } from 'types/1delta'

import AddressInputPanel from '../../../components/AddressInputPanel'
import { BaseButton, ButtonConfirmed, ButtonLight, ButtonPrimary } from '../../../components/Button'
import { GreyCard } from '../../../components/Card'
// import { AutoColumn } from '../../../components/Column'
import Loader from '../../../components/Loader'
import { AutoRow, RowFixed } from '../../../components/Row'
import confirmPriceImpactWithoutFee from '../../../components/swap/confirmPriceImpactWithoutFee'
import ConfirmSwapModal from '../../../components/swap/ConfirmSwapModal'
import { ArrowWrapper, HorizontalArrowWrapper, SwapCallbackError, SwapWrapper } from '../../../components/swap/styleds'
import { SwitchLocaleLink } from '../../../components/SwitchLocaleLink'
import { ApprovalState } from '../../../hooks/useApproveCallback'
import useENSAddress from '../../../hooks/useENSAddress'
import { useERC20PermitFromTrade, UseERC20PermitState } from '../../../hooks/useERC20Permit'
import useIsArgentWallet from '../../../hooks/useIsArgentWallet'
import { useIsSwapUnsupported } from '../../../hooks/useIsSwapUnsupported'
import { useStablecoinValue } from '../../../hooks/useStablecoinPrice'
import useWrapCallback, { WrapErrorText, WrapType } from '../../../hooks/useWrapCallback'
import { Field, switchCurrencies } from '../../../state/swap/actions'
import {
  useDerivedSwapInfoClientSideV3ProvidedCcy,
  useSwapActionHandlers,
  useSwapState,
} from '../../../state/swap/hooks'
import styled, { useTheme } from 'styled-components/macro'
import { useExpertModeManager } from '../../../state/user/hooks'
import { LinkStyledButton, ThemedText } from '../../../theme'
import { computeFiatValuePriceImpact } from '../../../utils/computeFiatValuePriceImpact'
import { maxAmountSpend } from '../../../utils/maxAmountSpend'
import { computeRealizedPriceImpact, warningSeverity } from '../../../utils/prices'
import { ArrowContainer, ArrowDownWrapper, ArrowUpWrapper, InputWrapper } from '../components/wrappers'
import { formatSwapQuoteReceivedEventProperties, getIsValidSwapQuote, largerPercentValue } from '../utils/formatters'
import { createMarginTradeCalldata } from '../utils/marginTradeMethodCreator'
import MarginTradeHeader from './MarginTradeHeader'
import CurrencyInputCustomListRight from 'components/CurrencyInputPanel/CustomListInputPanel/CurrencyInputCustomListRight'
import { AutoColumn } from 'components/Column'
import { AdvancedRiskDetails } from '../components/AdvancedRiskDetails'
import { formatAaveYield } from '1delta/MarketTable/utils/format'

const TRADE_STRING = 'SwapRouter'

const PanelBox = styled.div`
  width: 50%;
`

const ButtonBox = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
  align-self:center;
`


const PanelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: center;
  margin: 5px;
`

const YieldContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
`

const YieldColumn = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
`

export const YieldRowLeft = styled.div<{ marked: boolean }>`
  margin-right: 10px;
  flex-direction: row;
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${({ marked }) => marked ? `

  ` : ''}
`

export const SelfCenteredText = styled.div`
align-self:center;
`

export const YieldRowRight = styled.div<{ marked: boolean }>`
  margin-left: 10px;
  flex-direction: row;
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${({ marked }) => marked ? `

  ` : ''}
`

// ${({ isLast }) => isLast ? 'border-bottom: 2px solid   #737373 ;' : ''}
// border-top-left-radius: ${({ isFirst }) => isFirst ? '16px' : '0px'};
// border-top-right-radius: ${({ isFirst }) => isFirst ? '16px' : '0px'};
// border-bottom-left-radius: ${({ isLast }) => isLast ? '16px' : '0px'};
// border-bottom-right-radius: ${({ isLast }) => isLast ? '16px' : '0px'};
export const getField = (_marginTradeType: MarginTradeType, _position: PositionSides): Field => {
  if (_marginTradeType === MarginTradeType.Open)
    if (_position === PositionSides.Collateral) {
      return Field.OUTPUT
    } else {
      return Field.INPUT
    }
  else {
    if (_position === PositionSides.Borrow) {
      return Field.OUTPUT
    } else {
      return Field.INPUT
    }
  }
}

export interface MarginTradeInterface {
  selectedAssetCollateral: Token
  selectedAssetBorrow: Token
}

export default function MarginTrade({ selectedAssetCollateral, selectedAssetBorrow }: MarginTradeInterface) {

  const navBarFlag = useNavBarFlag()
  const navBarFlagEnabled = navBarFlag === NavBarVariant.Enabled
  const redesignFlag = useRedesignFlag()
  const redesignFlagEnabled = redesignFlag === RedesignVariant.Enabled
  const { account, chainId, provider } = useWeb3React()
  const [newSwapQuoteNeedsLogging, setNewSwapQuoteNeedsLogging] = useState(true)
  const [fetchingSwapQuoteStartTime, setFetchingSwapQuoteStartTime] = useState<Date | undefined>()


  const restrictedTokenList = useMemo(() => {
    return getAaveTokens(chainId ?? 5)
  }, [chainId])


  const dispatch = useAppDispatch()

  const [selectedBorrowInterestMode, setBorrowInterestMode] = useState(AaveInterestMode.VARIABLE)

  const theme = useTheme()

  // toggle wallet when disconnected
  const toggleWalletModal = useToggleWalletModal()

  // for expert mode
  const [isExpertMode] = useExpertModeManager()

  const [marginTradeType, switchMarginTradeType] = useState(MarginTradeType.Open)

  // inverse mapping: trade side to position side 
  const [positionState, switchPositions] = useState<{ sideIn: PositionSides, sideOut: PositionSides }>(
    {
      sideIn: PositionSides.Borrow,
      sideOut: PositionSides.Collateral
    }
  )

  // switches the trade type and reassigns sideIn and sideOut for route calculation
  const handleSwitchMarginTradeType = useCallback(() => {
    dispatch(switchCurrencies())
    if (marginTradeType === MarginTradeType.Open) {
      switchMarginTradeType(MarginTradeType.Trim)
    }
    else {
      switchMarginTradeType(MarginTradeType.Open)
    }
    switchPositions({ sideIn: positionState.sideOut, sideOut: positionState.sideIn })
  },
    [positionState]
  )

  const deltaAssets = useDeltaAssetState()

  const { assetCollateralSide, assetBorrowSide } = useMemo(() => {
    return {
      assetCollateralSide: deltaAssets[selectedAssetCollateral.symbol as SupportedAssets],
      assetBorrowSide: deltaAssets[selectedAssetBorrow.symbol as SupportedAssets]
    }
  },
    [deltaAssets]
  )

  const [fieldLeft, fieldRight] = useMemo(() => {
    return [getField(marginTradeType, PositionSides.Collateral), getField(marginTradeType, PositionSides.Borrow)]
  }, [marginTradeType])



  const [currencyAmounts, borrowedDebtType] = useMemo(() => {
    // default debt types
    let _borrowedDebtType = AaveInterestMode.NONE
    let _ccyAmounts = undefined;

    // we see which debt the user owns and assign it if there is any of a specific type
    // if the input type  has not type (NONE), it shall be editable by the user
    const stableDebtBorrowBalance = ethers.BigNumber.from(assetBorrowSide?.userData?.currentStableDebt ?? '0')
    const variableBorrowBalance = ethers.BigNumber.from(assetBorrowSide?.userData?.currentVariableDebt ?? '0')
    if (assetBorrowSide?.reserveData?.stableBorrowRateEnabled && stableDebtBorrowBalance.gt(0)) _borrowedDebtType = AaveInterestMode.STABLE
    if (variableBorrowBalance.gt(0)) _borrowedDebtType = AaveInterestMode.VARIABLE

    const borrowBalance = (selectedBorrowInterestMode === AaveInterestMode.STABLE ? stableDebtBorrowBalance.toString() : variableBorrowBalance.toString()) ?? '0'
    // for a collateral swap, debt types are irrelevant
    if (marginTradeType === MarginTradeType.Open) {
      // open means that the borrow side is ccy in for the trade
      const [currencyIn, currencyOut] = [selectedAssetBorrow, selectedAssetCollateral]
      _ccyAmounts = {
        [Field.INPUT]: CurrencyAmount.fromRawAmount(currencyIn, borrowBalance),
        [Field.OUTPUT]: CurrencyAmount.fromRawAmount(currencyOut, assetCollateralSide?.userData?.currentATokenBalance ?? '0'),
      }
    } else {
      // for trimming, we swap from the collateral to repay a borrow: ccyIn is on the collateral side
      const [currencyIn, currencyOut] = [selectedAssetCollateral, selectedAssetBorrow]

      // assign debt balances
      _ccyAmounts = {
        [Field.INPUT]: CurrencyAmount.fromRawAmount(currencyIn, assetCollateralSide?.userData?.currentATokenBalance ?? '0'),
        [Field.OUTPUT]: CurrencyAmount.fromRawAmount(currencyOut, borrowBalance),
      }
    }

    return [_ccyAmounts, _borrowedDebtType]
  },
    [assetCollateralSide, assetBorrowSide, selectedAssetCollateral, selectedAssetBorrow, marginTradeType, selectedBorrowInterestMode]
  )

  const hasStableBorrow = useMemo(() => {
    return assetBorrowSide.reserveData?.stableBorrowRateEnabled ?? false
  },
    [assetBorrowSide]
  )

  const currencies: { [field in Field]?: Currency | null } = useMemo(
    () => ({
      [Field.INPUT]: currencyAmounts[Field.INPUT].currency,
      [Field.OUTPUT]: currencyAmounts[Field.OUTPUT].currency,
    }),
    [currencyAmounts]
  )

  const rawUserBalances = useCurrencyBalances(
    account ?? undefined,
    useMemo(() => [currencies[Field.INPUT] ?? undefined, currencies[Field.OUTPUT] ?? undefined], [currencies])
  )


  const currencyUserBalances = useMemo(
    () => ({
      [Field.INPUT]: rawUserBalances[0],
      [Field.OUTPUT]: rawUserBalances[1],
    }),
    [rawUserBalances]
  )

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()
  const {
    trade: { state: tradeState, trade },
    allowedSlippage,
    parsedAmount,
    inputError: swapInputError,
  } = useDerivedSwapInfoClientSideV3ProvidedCcy(currencyAmounts)

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENSAddress(recipient)

  const parsedAmounts = useMemo(
    () =>
      showWrap
        ? {
          [Field.INPUT]: parsedAmount,
          [Field.OUTPUT]: parsedAmount,
        }
        : {
          [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
          [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
        },
    [independentField, parsedAmount, showWrap, trade]
  )

  const [routeNotFound, routeIsLoading, routeIsSyncing] = useMemo(
    () => [!trade?.swaps, TradeState.LOADING === tradeState, TradeState.SYNCING === tradeState],
    [trade, tradeState]
  )

  const fiatValueInput = useStablecoinValue(parsedAmounts[Field.INPUT])
  const fiatValueOutput = useStablecoinValue(parsedAmounts[Field.OUTPUT])
  const stablecoinPriceImpact = useMemo(
    () => (routeIsSyncing ? undefined : computeFiatValuePriceImpact(fiatValueInput, fiatValueOutput)),
    [fiatValueInput, fiatValueOutput, routeIsSyncing]
  )

  const { onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeLeft = useCallback(
    (value: string) => {
      onUserInput(fieldLeft, value)
    },
    [onUserInput, fieldLeft]
  )
  const handleTypeRight = useCallback(
    (value: string) => {
      onUserInput(fieldRight, value)
    },
    [onUserInput, fieldRight]
  )

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade<Currency, Currency, TradeType> | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  })

  const formattedAmounts = useMemo(
    () => {
      return {
        [independentField]: typedValue,
        [dependentField]: showWrap
          ? parsedAmounts[independentField]?.toExact() ?? ''
          : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
      }
    },
    [dependentField, independentField, parsedAmounts, showWrap, typedValue, marginTradeType]
  )

  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )

  const marginTraderContract = getMarginTraderContract(chainId ?? 5, provider, account)

  // check whether the user has approved the router on the input token
  const [approvalState, approveCallback] = useApproveCallbackFromGeneralTrade(
    trade,
    LendingProtocolInteraction.Withdraw,
    fieldLeft,
    marginTraderContract.address,
    allowedSlippage
  )

  // check whether the user has approved the router
  // if sideIn is collateral, i.e. increaseing position, 
  const [borrowApprovalState, approveBorrowCallback] = useDelegateBorrowCallbackFromGeneralTrade(
    trade,
    LendingProtocolInteraction.Borrow,
    fieldRight,
    marginTraderContract.address,
    allowedSlippage,
    selectedBorrowInterestMode
  )

  // for each side, only one position is relevant
  // if opening/increasing a margin position, then the borrow approval is required
  // if trimming a margin position, a withdrawal (aToken) approval is required
  const [approvalStateOfConcern, approveCallbackOfConcern] = useMemo(() => {
    if (marginTradeType === MarginTradeType.Trim) // means withdraw,
      return [approvalState, approveCallback]
    else return [borrowApprovalState, approveBorrowCallback]
  },
    [positionState.sideOut, approvalState, borrowApprovalState, assetCollateralSide.id, assetBorrowSide.id]
  )


  const handleSelectInterestMode = useCallback(() => {
    // if (borrowedDebtType !== AaveInterestMode.NONE) {
    //   setBorrowInterestMode(borrowedDebtType)
    //   return null // no selections shall be possible
    // }
    if (!hasStableBorrow) {
      setBorrowInterestMode(AaveInterestMode.VARIABLE)
      return null // no selections shall be possible
    }

    if (selectedBorrowInterestMode != AaveInterestMode.VARIABLE) return setBorrowInterestMode(AaveInterestMode.VARIABLE)
    return setBorrowInterestMode(AaveInterestMode.STABLE)

  },
    [selectedBorrowInterestMode, hasStableBorrow]
  )

  useEffect(() => {
    if (!hasStableBorrow) {
      setBorrowInterestMode(AaveInterestMode.VARIABLE)
    }
    // if (borrowedDebtType !== AaveInterestMode.NONE) {
    //   setBorrowInterestMode(borrowedDebtType)
    // }
  }, [borrowedDebtType])



  const transactionDeadline = useTransactionDeadline()
  const {
    state: signatureState,
    signatureData,
    gatherPermitSignature,
  } = useERC20PermitFromTrade(trade, allowedSlippage, transactionDeadline)

  const [approvalPending, setApprovalPending] = useState<boolean>(false)
  const handleApprove = useCallback(async () => {
    setApprovalPending(true)
    try {
      if (signatureState === UseERC20PermitState.NOT_SIGNED && gatherPermitSignature) {
        try {
          await gatherPermitSignature()
        } catch (error) {
          // try to approve if gatherPermitSignature failed for any reason other than the user rejecting it
          if (error?.code !== 4001) {
            await approveCallbackOfConcern()
          }
        }
      } else {
        await approveCallbackOfConcern()

        sendEvent({
          category: 'Swap',
          action: 'Approve',
          label: [TRADE_STRING, trade?.inputAmount?.currency.symbol].join('/'),
        })
      }
    } finally {
      setApprovalPending(false)
    }
  }, [signatureState, gatherPermitSignature, approveCallbackOfConcern, trade?.inputAmount?.currency.symbol])

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approvalStateOfConcern === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approvalStateOfConcern, approvalSubmitted])

  const maxInputAmount: CurrencyAmount<Currency> | undefined = useMemo(
    () => maxAmountSpend(currencyAmounts[Field.INPUT]),
    [currencyAmounts]
  )
  const showMaxButton = Boolean(maxInputAmount?.greaterThan(0) && !parsedAmounts[Field.INPUT]?.equalTo(maxInputAmount))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    trade,
    allowedSlippage,
    recipient,
    signatureData
  )
  const test = (trade?.routes[0] as RouteV3<Currency, Currency>)

  const handleSwap = useCallback(async () => {
    if (!swapCallback) {
      return
    }
    if (stablecoinPriceImpact && !confirmPriceImpactWithoutFee(stablecoinPriceImpact)) {
      return
    }
    const v3Route = (trade?.routes[0] as RouteV3<Currency, Currency>)
    const { args, method, estimate, call } = createMarginTradeCalldata(
      parsedAmounts,
      marginTraderContract,
      positionState.sideIn,
      selectedBorrowInterestMode,
      account,
      v3Route,
      trade?.tradeType
    )
    console.log("SWAP ARGS", args, v3Route.path)
    setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
    if (call)
      await call().then((txResponse) => {
        setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: txResponse.hash })
        sendEvent({
          category: 'Swap',
          action: 'transaction hash',
          label: txResponse.hash,
        })
        sendEvent({
          category: 'Swap',
          action:
            recipient === null
              ? 'Swap w/o Send'
              : (recipientAddress ?? recipient) === account
                ? 'Swap w/o Send + recipient'
                : 'Swap w/ Send',
          label: [TRADE_STRING, trade?.inputAmount?.currency?.symbol, trade?.outputAmount?.currency?.symbol, 'MH'].join(
            '/'
          ),
        })
        dispatch(fetchAAVEUserReserveDataAsync({
          chainId: chainId ?? 5,
          account: account ?? '',
          assetsToQuery: [assetBorrowSide.id, assetCollateralSide.id]
        })
        )
      })
        .catch((error) => {
          setSwapState({
            attemptingTxn: false,
            tradeToConfirm,
            showConfirm,
            swapErrorMessage: error.message,
            txHash: undefined,
          })
        })
  }, [
    swapCallback,
    stablecoinPriceImpact,
    tradeToConfirm,
    showConfirm,
    recipient,
    recipientAddress,
    account,
    trade?.inputAmount?.currency?.symbol,
    trade?.outputAmount?.currency?.symbol,
  ])


  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)
  const [swapQuoteReceivedDate, setSwapQuoteReceivedDate] = useState<Date | undefined>()

  // warnings on the greater of fiat value price impact and execution price impact
  const { priceImpactSeverity, largerPriceImpact } = useMemo(() => {
    const marketPriceImpact = trade?.priceImpact ? computeRealizedPriceImpact(trade) : undefined
    const largerPriceImpact = largerPercentValue(marketPriceImpact, stablecoinPriceImpact)
    return { priceImpactSeverity: warningSeverity(largerPriceImpact), largerPriceImpact }
  }, [stablecoinPriceImpact, trade])

  const isArgentWallet = useIsArgentWallet()

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow = // swap input error should be respected
    !isArgentWallet &&
    (approvalStateOfConcern === ApprovalState.NOT_APPROVED ||
      approvalStateOfConcern === ApprovalState.PENDING ||
      (approvalSubmitted && approvalStateOfConcern === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const maxCollateralAmount: CurrencyAmount<Currency> | undefined = useMemo(
    () => maxAmountSpend(currencyAmounts[fieldLeft]),
    [currencyAmounts, fieldLeft]
  )

  const handleMaxCollateral = useCallback(() => {
    maxCollateralAmount && onUserInput(fieldLeft, maxCollateralAmount.toExact())
    sendEvent({
      category: 'Swap',
      action: 'Max',
    })
  }, [maxCollateralAmount, onUserInput, fieldLeft])

  const maxBorrowAmount: CurrencyAmount<Currency> | undefined = useMemo(
    () => maxAmountSpend(currencyAmounts[fieldRight]),
    [currencyAmounts, fieldRight]
  )

  const handleMaxBorrow = useCallback(() => {
    maxBorrowAmount && onUserInput(fieldRight, maxBorrowAmount.toExact())
    sendEvent({
      category: 'Swap',
      action: 'Max',
    })
  }, [maxBorrowAmount, onUserInput, fieldRight])

  const swapIsUnsupported = useIsSwapUnsupported(currencies[Field.INPUT], currencies[Field.OUTPUT])

  const priceImpactTooHigh = !typedValue  //  priceImpactSeverity > 3 && !isExpertMode
  const showPriceImpactWarning = largerPriceImpact && priceImpactSeverity > 3

  // Handle time based logging events and event properties.
  useEffect(() => {
    const now = new Date()
    // If a trade exists, and we need to log the receipt of this new swap quote:
    if (newSwapQuoteNeedsLogging && !!trade) {
      // Set the current datetime as the time of receipt of latest swap quote.
      setSwapQuoteReceivedDate(now)
      // Log swap quote.
      sendAnalyticsEvent(
        EventName.SWAP_QUOTE_RECEIVED,
        formatSwapQuoteReceivedEventProperties(trade, fetchingSwapQuoteStartTime)
      )
      // Latest swap quote has just been logged, so we don't need to log the current trade anymore
      // unless user inputs change again and a new trade is in the process of being generated.
      setNewSwapQuoteNeedsLogging(false)
      // New quote is not being fetched, so set start time of quote fetch to undefined.
      setFetchingSwapQuoteStartTime(undefined)
    }
    // If another swap quote is being loaded based on changed user inputs:
    if (routeIsLoading) {
      setNewSwapQuoteNeedsLogging(true)
      if (!fetchingSwapQuoteStartTime) setFetchingSwapQuoteStartTime(now)
    }
  }, [
    newSwapQuoteNeedsLogging,
    routeIsSyncing,
    routeIsLoading,
    fetchingSwapQuoteStartTime,
    trade,
    setSwapQuoteReceivedDate,
  ])

  const approveTokenButtonDisabled =
    approvalStateOfConcern !== ApprovalState.NOT_APPROVED || approvalSubmitted || signatureState === UseERC20PermitState.SIGNED



  console.log("MODES swap args", borrowedDebtType, selectedBorrowInterestMode, marginTradeType, fieldLeft, fieldRight)
  console.log("MODES swap args FA", formattedAmounts[fieldLeft], formattedAmounts[fieldRight])

  const riskParameters = useGetAaveRiskParameters(account)

  const riskChange = useMemo(() => {
    const [deltaCollateral, deltaBorrow] = marginTradeType === MarginTradeType.Open ?
      [trade?.outputAmount.quotient.toString() ?? '0', trade?.inputAmount?.quotient.toString() ?? '0'] :
      [
        ethers.BigNumber.from(trade?.inputAmount.quotient.toString() ?? '0').mul(-1),
        ethers.BigNumber.from(trade?.outputAmount?.quotient.toString() ?? '0').mul(-1)
      ]

    return calculateRiskChangeMarginTrade(
      { asset: assetCollateralSide.id, deltaCollateral: ethers.BigNumber.from(deltaCollateral) },
      { asset: assetBorrowSide.id, deltaBorrow: ethers.BigNumber.from(deltaBorrow) },
      riskParameters)
  }, [riskParameters, marginTradeType, assetBorrowSide, assetCollateralSide, trade])

  const [liquidityRate, borrowRateStable, borrowRateVariable] = useMemo(() => {

    return [
      formatAaveYield(assetCollateralSide?.reserveData?.liquidityRate ?? '0'),
      formatAaveYield(assetBorrowSide?.reserveData?.stableBorrowRate ?? '0'),
      formatAaveYield(assetBorrowSide?.reserveData?.variableBorrowRate ?? '0')
    ]
  }, [assetCollateralSide, assetBorrowSide])

  return (
    <Trace page={PageName.SWAP_PAGE} shouldLogImpression>
      <>
        {/* <PageWrapper redesignFlag={redesignFlagEnabled} navBarFlag={navBarFlagEnabled}> */}
        <SwapWrapper id="swap-page" redesignFlag={redesignFlagEnabled}>

          <MarginTradeHeader allowedSlippage={allowedSlippage} sideIn={positionState.sideIn} />
          <ConfirmSwapModal
            isOpen={showConfirm}
            trade={trade}
            originalTrade={tradeToConfirm}
            onAcceptChanges={handleAcceptChanges}
            attemptingTxn={attemptingTxn}
            txHash={txHash}
            recipient={recipient}
            allowedSlippage={allowedSlippage}
            onConfirm={handleSwap}
            swapErrorMessage={swapErrorMessage}
            onDismiss={handleConfirmDismiss}
            swapQuoteReceivedDate={swapQuoteReceivedDate}
            fiatValueInput={fiatValueInput}
            fiatValueOutput={fiatValueOutput}
          />
          <div>
            <AutoColumn>
              <PanelContainer>
                <AdvancedRiskDetails tradeImpact={riskChange} allowedSlippage={allowedSlippage} marginTradeType={marginTradeType} noTrade={!typedValue} />
              </PanelContainer>
              <YieldContainer>
                <PanelBox>
                  <MouseoverTooltip
                    text={"The deposit rate is based on market conditions and cannot be modified."}
                  >
                    <ButtonLight height={'30px'}
                      onClick={() => null}
                      style={{
                        borderTopLeftRadius: '16px',
                        borderBottomLeftRadius: '16px',
                        marginLeft: '5px',
                        marginRight: '3px',
                        borderBottomRightRadius: '16px',
                        borderTopRightRadius: '16px',
                        width: '350px'
                      }}
                    >
                      <YieldRowLeft marked={false}>
                        <Box />
                        <SelfCenteredText>
                          Deposit rate:
                        </SelfCenteredText>
                        <SelfCenteredText>
                          {liquidityRate}%
                        </SelfCenteredText>
                      </YieldRowLeft>
                    </ButtonLight>
                  </MouseoverTooltip>
                </PanelBox>
                <ButtonBox>
                  {hasStableBorrow && <ButtonLight height={'30px'}
                    onClick={handleSelectInterestMode}
                    style={{
                      borderTopLeftRadius: '16px',
                      borderBottomLeftRadius: '0px',
                      marginLeft: '5px',
                      marginRight: '3px',
                      borderBottomRightRadius: '0px',
                      borderTopRightRadius: '16px',
                      width: '350px'
                    }}
                  >
                    <YieldRowRight marked={selectedBorrowInterestMode === AaveInterestMode.STABLE}>
                      {selectedBorrowInterestMode === AaveInterestMode.STABLE ? <CheckCircle /> : <Circle />}
                      <SelfCenteredText>
                        Borrow rate stable:
                      </SelfCenteredText>
                      <SelfCenteredText>
                        {borrowRateStable}%
                      </SelfCenteredText></YieldRowRight>
                  </ButtonLight>
                  }
                  <ButtonLight height={'30px'}
                    onClick={handleSelectInterestMode}
                    style={{
                      borderTopLeftRadius: hasStableBorrow ? '0px' : '16px',
                      borderBottomLeftRadius: '16px',
                      marginLeft: '5px',
                      marginRight: '3px',
                      borderBottomRightRadius: '16px',
                      borderTopRightRadius: hasStableBorrow ? '0px' : '16px',
                      width: '350px'
                    }}
                  >
                    <YieldRowRight marked={selectedBorrowInterestMode === AaveInterestMode.VARIABLE}>
                      {selectedBorrowInterestMode === AaveInterestMode.VARIABLE ? <CheckCircle /> : <Circle />}
                      <SelfCenteredText>
                        Borrow rate variable:
                      </SelfCenteredText>
                      <SelfCenteredText>
                        {borrowRateVariable}%
                      </SelfCenteredText>
                    </YieldRowRight>
                  </ButtonLight>
                </ButtonBox>
              </YieldContainer>
              <PanelContainer>

                <PanelBox>
                  <CurrencyInputCustomListLeft
                    providedTokenList={restrictedTokenList}
                    value={formattedAmounts[fieldLeft]}
                    onUserInput={handleTypeLeft}
                    label={
                      independentField === fieldRight && !showWrap ? (
                        <Trans>To (at least)</Trans>
                      ) : (
                        <Trans>To</Trans>
                      )
                    }
                    showMaxButton={marginTradeType === MarginTradeType.Trim} // {side !== PositionSides.Collateral}
                    onMax={handleMaxCollateral}
                    hideBalance={false}
                    fiatValue={fiatValueOutput ?? undefined}
                    priceImpact={stablecoinPriceImpact}
                    currency={currencyAmounts[fieldLeft].currency} //{currencies[fieldLeft] ?? null}
                    providedCurrencyBalance={currencyAmounts[fieldLeft]}
                    balanceText={'Your deposits'}
                    // onCurrencySelect={null}
                    otherCurrency={null}
                    showCommonBases={true}
                    id={SectionName.CURRENCY_OUTPUT_PANEL}
                    loading={independentField === fieldRight && routeIsSyncing}
                  />
                </PanelBox>
                <HorizontalArrowWrapper clickable={isSupportedChain(chainId)} redesignFlag={redesignFlagEnabled}>
                  <TraceEvent
                    events={[Event.onClick]}
                    name={EventName.SWAP_TOKENS_REVERSED}
                    element={ElementName.SWAP_TOKENS_REVERSE_ARROW_BUTTON}
                  >
                    {redesignFlagEnabled ? (
                      <ArrowContainer
                        onClick={() => {
                          setApprovalSubmitted(false) // reset 2 step UI for approvals
                          handleSwitchMarginTradeType()
                        }}
                        color={theme.textPrimary}
                      >
                        <ArrowUpWrapper>
                          <ArrowRight size="12" stroke-width="3" />
                        </ArrowUpWrapper>
                        <ArrowDownWrapper>
                          <ArrowLeft size="12" stroke-width="3" />
                        </ArrowDownWrapper>
                      </ArrowContainer>
                    ) : (
                      <ArrowWrapper clickable onClick={() => {
                        handleSwitchMarginTradeType()
                        setApprovalSubmitted(false)
                      }
                      } redesignFlag={redesignFlagEnabled}
                      >
                        {
                          positionState.sideIn === PositionSides.Collateral
                            ?
                            (<ArrowRight size="16" color={theme.deprecated_text2} />)
                            : (<ArrowLeft size="16" color={theme.deprecated_text2} />)
                        }
                      </ArrowWrapper>
                    )}
                  </TraceEvent>
                </HorizontalArrowWrapper>
                <PanelBox>
                  <CurrencyInputCustomListRight
                    providedTokenList={restrictedTokenList}
                    label={
                      independentField === fieldLeft && !showWrap ? (
                        <Trans>From (at most)</Trans>
                      ) : (
                        <Trans>From</Trans>
                      )
                    }
                    value={formattedAmounts[fieldRight]}
                    showMaxButton
                    currency={currencyAmounts[fieldRight].currency}
                    onUserInput={handleTypeRight}
                    onMax={handleMaxBorrow}
                    fiatValue={fiatValueInput ?? undefined}
                    otherCurrency={null}
                    showCommonBases={true}
                    id={SectionName.CURRENCY_INPUT_PANEL}
                    loading={independentField === fieldLeft && routeIsSyncing}
                    providedCurrencyBalance={currencyAmounts[fieldRight]}
                    balanceText={selectedBorrowInterestMode === AaveInterestMode.STABLE ?
                      'Your stable debt' : selectedBorrowInterestMode === AaveInterestMode.VARIABLE ? 'Your variable debt' : ' No debt selected'
                    }
                  />

                </PanelBox>
              </PanelContainer>
            </AutoColumn>
            {/* <AutoColumn gap={redesignFlagEnabled ? '12px' : '8px'}> */}
            <InputWrapper redesignFlag={redesignFlagEnabled}>

              {recipient !== null && !showWrap ? (
                <>
                  <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                    <ArrowWrapper clickable={false} redesignFlag={redesignFlagEnabled}>
                      <ArrowDown size="16" color={theme.deprecated_text2} />
                    </ArrowWrapper>
                    <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                      <Trans>- Remove recipient</Trans>
                    </LinkStyledButton>
                  </AutoRow>
                  <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
                </>
              ) : null}
              {!showWrap && userHasSpecifiedInputOutput && (trade || routeIsLoading || routeIsSyncing) && (
                <SwapDetailsDropdown
                  trade={trade}
                  syncing={routeIsSyncing}
                  loading={routeIsLoading}
                  showInverted={showInverted}
                  setShowInverted={setShowInverted}
                  allowedSlippage={allowedSlippage}
                />
              )}
              {showPriceImpactWarning && <PriceImpactWarning priceImpact={largerPriceImpact} />}
            </InputWrapper>
            <div>
              {swapIsUnsupported ? (
                <ButtonPrimary disabled={true}>
                  <ThemedText.DeprecatedMain mb="4px">
                    <Trans>Unsupported Asset</Trans>
                  </ThemedText.DeprecatedMain>
                </ButtonPrimary>
              ) : !account ? (
                <TraceEvent
                  events={[Event.onClick]}
                  name={EventName.CONNECT_WALLET_BUTTON_CLICKED}
                  properties={{ received_swap_quote: getIsValidSwapQuote(trade, tradeState, swapInputError) }}
                  element={ElementName.CONNECT_WALLET_BUTTON}
                >
                  <ButtonLight onClick={toggleWalletModal} redesignFlag={redesignFlagEnabled}>
                    <Trans>Connect Wallet</Trans>
                  </ButtonLight>
                </TraceEvent>
              ) : showWrap ? (
                <ButtonPrimary disabled={Boolean(wrapInputError)} onClick={onWrap}>
                  {wrapInputError ? (
                    <WrapErrorText wrapInputError={wrapInputError} />
                  ) : wrapType === WrapType.WRAP ? (
                    <Trans>Wrap</Trans>
                  ) : wrapType === WrapType.UNWRAP ? (
                    <Trans>Unwrap</Trans>
                  ) : null}
                </ButtonPrimary>
              ) : routeNotFound && userHasSpecifiedInputOutput && !routeIsLoading && !routeIsSyncing ? (
                <GreyCard style={{ textAlign: 'center' }}>
                  <ThemedText.DeprecatedMain mb="4px">
                    <Trans>Insufficient liquidity for this trade.</Trans>
                  </ThemedText.DeprecatedMain>
                </GreyCard>
              ) : showApproveFlow ? (
                <AutoRow style={{ flexWrap: 'nowrap', width: '100%' }}>
                  <ButtonConfirmed
                    onClick={handleApprove}
                    disabled={approveTokenButtonDisabled}
                    width="100%"
                    altDisabledStyle={approvalStateOfConcern === ApprovalState.PENDING} // show solid button while waiting
                    confirmed={
                      approvalStateOfConcern === ApprovalState.APPROVED || signatureState === UseERC20PermitState.SIGNED
                    }
                  >
                    <AutoRow justify="space-between" style={{ flexWrap: 'nowrap' }} height="20px">
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        {/* we need to shorten this string on mobile */}
                        {approvalStateOfConcern === ApprovalState.APPROVED ||
                          signatureState === UseERC20PermitState.SIGNED ? (
                          <Trans>
                            {
                              marginTradeType === MarginTradeType.Trim ?
                                `You can now withdraw ${currencyAmounts[fieldLeft]?.currency?.symbol}` :
                                `You can now borrow ${currencyAmounts[fieldRight]?.currency?.symbol}`
                            }
                          </Trans>
                        ) : (
                          <Trans>
                            {
                              marginTradeType === MarginTradeType.Trim ?
                                `Allow our protocol to withdraw your ${currencyAmounts[fieldLeft]?.currency?.symbol}` :
                                `Allow our protocol to borrow ${currencyAmounts[fieldRight]?.currency?.symbol} on your behalf`
                            }
                          </Trans>
                        )}
                      </span>
                      {approvalPending || approvalStateOfConcern === ApprovalState.PENDING ? (
                        <Loader stroke={theme.white} />
                      ) : (approvalSubmitted && approvalStateOfConcern === ApprovalState.APPROVED) ||
                        signatureState === UseERC20PermitState.SIGNED ? (
                        <CheckCircle size="20" color={theme.deprecated_green1} />
                      ) : (
                        <MouseoverTooltip
                          text={
                            <Trans>
                              You must give the 1Delta DAO smart contracts permission to use your{' '}
                              {currencies[marginTradeType === MarginTradeType.Trim ? fieldLeft : fieldRight]?.symbol}. You only have to do this once per token.
                            </Trans>
                          }
                        >
                          <HelpCircle size="20" color={theme.deprecated_white} style={{ marginLeft: '8px' }} />
                        </MouseoverTooltip>
                      )}
                    </AutoRow>
                  </ButtonConfirmed>
                  <ButtonPrimary
                    onClick={() => {
                      if (isExpertMode) {
                        handleSwap()
                      } else {
                        setSwapState({
                          tradeToConfirm: trade,
                          attemptingTxn: false,
                          swapErrorMessage: undefined,
                          showConfirm: true,
                          txHash: undefined,
                        })
                      }
                    }}
                    width="100%"
                    id="swap-button"
                    disabled={
                      // !isValid ||
                      routeIsSyncing ||
                      routeIsLoading ||
                      (approvalStateOfConcern !== ApprovalState.APPROVED &&
                        signatureState !== UseERC20PermitState.SIGNED) ||
                      priceImpactTooHigh
                    }
                  // error={isValid && priceImpactSeverity > 2}
                  >
                    <Text fontSize={16} fontWeight={500}>
                      {priceImpactTooHigh ? (
                        <Trans>Pick Your Trade</Trans>
                      ) : (
                        <Trans>Swap</Trans>
                      )}
                    </Text>
                  </ButtonPrimary>
                </AutoRow>
              ) : (
                <ButtonPrimary
                  onClick={() => {
                    if (isExpertMode) {
                      handleSwap()
                    } else {
                      setSwapState({
                        tradeToConfirm: trade,
                        attemptingTxn: false,
                        swapErrorMessage: undefined,
                        showConfirm: true,
                        txHash: undefined,
                      })
                    }
                  }}
                  id="swap-button"
                  disabled={
                    // !isValid || 
                    routeIsSyncing || routeIsLoading || priceImpactTooHigh || !!swapCallbackError
                  }
                // error={priceImpactSeverity > 2 && !swapCallbackError}
                >
                  <Text fontSize={20} fontWeight={500}>
                    {
                      // swapInputError ? (
                      //   swapInputError
                      // ) :
                      routeIsSyncing || routeIsLoading ? (
                        <Trans>Swap</Trans>
                      ) : priceImpactTooHigh ? (
                        <Trans>Pick Your Trade</Trans>
                      ) : (
                        <Trans>Swap</Trans>
                      )}
                  </Text>
                </ButtonPrimary>
              )}
              {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
            </div>
            {/* </AutoColumn> */}
          </div>
          {/* </AutoColumn> */}
        </SwapWrapper>
        <NetworkAlert />
        {/* </PageWrapper> */}
        <SwitchLocaleLink />
        {!swapIsUnsupported ? null : (
          <UnsupportedCurrencyFooter
            show={swapIsUnsupported}
            currencies={[currencies[Field.INPUT], currencies[Field.OUTPUT]]}
          />
        )}
      </>
    </Trace>
  )
}
