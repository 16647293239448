import { RouteV3 } from "@uniswap/router-sdk"
import { Currency, CurrencyAmount, MaxUint256, TradeType } from "@uniswap/sdk-core"
import { ADDRESS_ZERO } from "@uniswap/v3-sdk"
import { Contract } from "ethers"
import { Field } from "state/swap/actions"
import { AaveInterestMode, encodePath, LendingProtocolInteraction, PositionSides } from "types/1delta"

export interface SimpleTransactioResponse {
  hash: string
}

type ContractCall = () => Promise<SimpleTransactioResponse>;

export interface ContractCallData {
  args: any
  method: any
  estimate: any
  call?: ContractCall | undefined
}

export const createSingleSideCalldata = (
  parsedAmounts: {
    INPUT?: CurrencyAmount<Currency> | undefined;
    OUTPUT?: CurrencyAmount<Currency> | undefined;
  },
  marginTraderContract: Contract,
  side: PositionSides,
  sourceInterestMode: AaveInterestMode,
  targetInterestMode: AaveInterestMode,
  account?: string,
  v3Route?: RouteV3<Currency, Currency>,
  tradeType?: TradeType
): ContractCallData => {

  let args: any = {}
  let method: any
  let estimate: any
  let contractCall: ContractCall | undefined = undefined

  if (!v3Route || (tradeType == undefined) || !account) return {
    args: {}, method: undefined, estimate: undefined
  }

  const hasOnePool = v3Route.pools.length === 1

  if (side === PositionSides.Collateral) { // collateral swaps
    if (tradeType === TradeType.EXACT_INPUT) {
      if (hasOnePool) {
        args = v3Route && {
          tokenIn: v3Route.input.wrapped.address,
          tokenOut: v3Route.output.wrapped.address,
          fee: v3Route.pools[0].fee,
          amountIn: parsedAmounts?.[Field.INPUT] && parsedAmounts?.[Field.INPUT]?.quotient.toString(),
          amountOutMinimum: 0,
          sqrtPriceLimitX96: 0,
          interestRateMode: AaveInterestMode.NONE
        }
        method = marginTraderContract.swapCollateralExactIn
        estimate = marginTraderContract.estimateGas.swapCollateralExactIn
        contractCall = async () => await marginTraderContract.swapCollateralExactIn(args)
      } else {
        args = v3Route && {
          path: encodePath(v3Route.path.map(p => p.address), v3Route.pools.map(pool => pool.fee)),
          amountIn: parsedAmounts?.[Field.INPUT] && parsedAmounts?.[Field.INPUT]?.quotient.toString(),
          amountOutMinimum: 0,
          sqrtPriceLimitX96: 0,
          interestRateMode: AaveInterestMode.NONE
        }
        method = marginTraderContract.swapCollateralExactInMulti
        estimate = marginTraderContract.estimateGas.swapCollateralExactInMulti
        contractCall = async () => await marginTraderContract.swapCollateralExactInMulti(args)
      }
    } else {
      if (hasOnePool) {
        args = v3Route && {
          tokenIn: v3Route.input.wrapped.address,
          tokenOut: v3Route.output.wrapped.address,
          fee: v3Route.pools[0].fee,
          amountOut: parsedAmounts?.[Field.OUTPUT] && parsedAmounts?.[Field.OUTPUT]?.quotient.toString(),
          amountInMaximum: 0,
          sqrtPriceLimitX96: 0,
          interestRateMode: AaveInterestMode.NONE
        }
        method = marginTraderContract.swapCollateralExactOut
        estimate = marginTraderContract.estimateGas.swapCollateralExactOut
        contractCall = async () => await marginTraderContract.swapCollateralExactOut(args)
      } else {
        const structInp = v3Route && {
          path: encodePath(v3Route.path.map(p => p.address).reverse(), v3Route.pools.map(pool => pool.fee).reverse()),
          amountOut: parsedAmounts?.[Field.OUTPUT] && parsedAmounts?.[Field.OUTPUT]?.quotient.toString(),
          amountInMaximum: MaxUint256.toString(),
          sqrtPriceLimitX96: '0',
          interestRateMode: 0
        }
        args = v3Route && [
          structInp
        ]
        method = marginTraderContract.swapCollateralExactOutMulti
        estimate = marginTraderContract.estimateGas.swapCollateralExactOutMulti
        contractCall = async () => await marginTraderContract.swapCollateralExactOutMulti(structInp)
      }
    }
  }
  // borrow swaps
  else {
    if (tradeType === TradeType.EXACT_INPUT) {
      if (hasOnePool) {
        args = v3Route && {
          tokenIn: v3Route.input.wrapped.address,
          tokenOut: v3Route.output.wrapped.address,
          fee: v3Route.pools[0].fee,
          amountIn: parsedAmounts?.[Field.INPUT] && parsedAmounts?.[Field.INPUT]?.quotient.toString(),
          amountOutMinimum: 0,
          sqrtPriceLimitX96: 0,
          interestRateMode: targetInterestMode === AaveInterestMode.NONE ? sourceInterestMode : targetInterestMode
        }
        method = marginTraderContract.swapBorrowExactIn
        estimate = marginTraderContract.estimateGas.swapBorrowExactIn
        contractCall = async () => await marginTraderContract.swapBorrowExactIn(args)
      } else {
        args = v3Route && {
          path: encodePath(v3Route.path.map(p => p.address), v3Route.pools.map(pool => pool.fee)),
          amountIn: parsedAmounts?.[Field.INPUT] && parsedAmounts?.[Field.INPUT]?.quotient.toString(),
          amountOutMinimum: 0,
          sqrtPriceLimitX96: 0,
          interestRateMode: targetInterestMode === AaveInterestMode.NONE ? sourceInterestMode : targetInterestMode
        }
        method = marginTraderContract.swapBorrowExactInMulti
        estimate = marginTraderContract.estimateGas.swapBorrowExactInMulti
        contractCall = async () => await marginTraderContract.swapBorrowExactInMulti(args)
      }
    }
    else {
      if (hasOnePool) {
        args = v3Route && {
          tokenIn: v3Route.input.wrapped.address,
          tokenOut: v3Route.output.wrapped.address,
          fee: v3Route.pools[0].fee,
          amountOut: parsedAmounts?.[Field.OUTPUT] && parsedAmounts?.[Field.OUTPUT]?.quotient.toString(),
          amountInMaximum: 0,
          sqrtPriceLimitX96: 0,
          interestRateMode: targetInterestMode === AaveInterestMode.NONE ? sourceInterestMode : targetInterestMode
        }
        method = marginTraderContract.swapBorrowExactOut
        estimate = marginTraderContract.estimateGas.swapBorrowExactOut
        contractCall = async () => await marginTraderContract.swapBorrowExactOut(args)

      } else {
        const interestRateMode = targetInterestMode === AaveInterestMode.NONE ? sourceInterestMode : targetInterestMode
        const structInp = v3Route && {
          path: encodePath(v3Route.path.map(p => p.address).reverse(), v3Route.pools.map(pool => pool.fee).reverse()),
          amountOut: parsedAmounts?.[Field.OUTPUT] && parsedAmounts?.[Field.OUTPUT]?.quotient.toString(),
          interestRateMode,
          amountInMaximum: MaxUint256.toString(),
          userAmountProvided: 0,
          sqrtPriceLimitX96: '0'
        }
        args = [structInp]
        method = marginTraderContract.swapBorrowExactOutMulti
        estimate = marginTraderContract.estimateGas.swapBorrowExactOutMulti
        contractCall = async () => await marginTraderContract.swapBorrowExactOutMulti(structInp)
      }
    }
  }

  return { args, method, estimate, call: contractCall }
}