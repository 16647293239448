import invariant from "tiny-invariant"

export type SerializedBigNumber = string
export type SerializedNumber = string

export interface TokenMeta {
  symbol: string
  decimals: number
  name: string
}

export interface Asset extends TokenMeta {
  id: SupportedAssets
  tokenPrice?: SerializedBigNumber
  // symbol?: string
  borrowBalance?: SerializedBigNumber
  // decimals?: number
  walletBalance?: SerializedBigNumber
  vtokenAddress?: string
  borrowApy?: SerializedBigNumber
  xvsBorrowApy?: SerializedBigNumber
  xvsBorrowApr?: SerializedBigNumber
  img?: string
  borrowCaps?: SerializedBigNumber
  liquidity?: SerializedBigNumber
  xvsSupplyApy?: SerializedBigNumber
  xvsSupplyApr?: SerializedBigNumber
  supplyApy?: SerializedBigNumber
  collateralFactor?: SerializedBigNumber
  collateral?: boolean
  supplyBalance?: SerializedBigNumber
  key?: number
  percentOfLimit?: string
  tokenAddress?: string
  treasuryBalance?: SerializedBigNumber
  vimg?: string | undefined
  vsymbol?: string
  treasuryTotalBorrowsCents?: SerializedBigNumber
  treasuryTotalSupplyCents?: SerializedBigNumber
  treasuryTotalSupply?: SerializedBigNumber
  treasuryTotalBorrows?: SerializedBigNumber
  xvsPerDay?: SerializedBigNumber
  // AAVE params
  underlyingAddress?: string
  currentStableBorrowRate?: SerializedBigNumber
  currentLiquidityRate?: SerializedBigNumber
  currentVariableBorrowRate?: SerializedBigNumber
  aTokenAddress?: string
  // interest rates are numbers
  stableDebtTokenAddress?: SerializedNumber
  variableDebtTokenAddress?: SerializedNumber
  interestRateStrategyAddress?: SerializedNumber
  // custom aave fields
  aData?: { supply?: SerializedBigNumber }
  vData?: { scaledSupply?: SerializedBigNumber }
  sData?: {
    principal?: SerializedBigNumber
    supply?: SerializedBigNumber
    averageStableRate?: SerializedBigNumber
    lastUpdated?: number
  }
  price?: SerializedBigNumber
  userData?: {
    // deprecated
    supplyBalance?: SerializedBigNumber
    borrowBalanceStable?: SerializedBigNumber
    borrowBalanceVariable?: SerializedBigNumber
    // new from provider
    currentATokenBalance?: SerializedBigNumber
    currentStableDebt?: SerializedBigNumber
    currentVariableDebt?: SerializedBigNumber
    principalStableDebt?: SerializedBigNumber
    scaledVariableDebt?: SerializedBigNumber
    stableBorrowRate?: SerializedBigNumber
    liquidityRate?: SerializedBigNumber
    stableRateLastUpdated?: number
    usageAsCollateralEnabled?: boolean
  },
  reserveData?: {
    // reserve market data
    unbacked?: SerializedBigNumber,
    accruedToTreasuryScaled?: SerializedBigNumber,
    totalAToken?: SerializedBigNumber,
    totalStableDebt?: SerializedBigNumber,
    totalVariableDebt?: SerializedBigNumber,
    liquidityRate?: SerializedBigNumber,
    variableBorrowRate?: SerializedBigNumber,
    stableBorrowRate?: SerializedBigNumber,
    averageStableBorrowRate?: SerializedBigNumber,
    liquidityIndex?: SerializedBigNumber,
    variableBorrowIndex?: SerializedBigNumber,
    lastUpdateTimestamp?: number,
    // reserve config
    decimals?: number,
    ltv?: SerializedBigNumber,
    liquidationThreshold?: SerializedBigNumber,
    liquidationBonus?: SerializedBigNumber,
    reserveFactor?: SerializedBigNumber,
    usageAsCollateralEnabled?: boolean,
    borrowingEnabled?: boolean,
    stableBorrowRateEnabled?: boolean,
    isActive?: boolean,
    isFrozen?: boolean
  }
}

export enum SupportedAssets {
  WETH = 'WETH',
  DAI = 'DAI',
  LINK = 'LINK',
  USDC = 'USDC',
  WBTC = 'WBTC',
  USDT = 'USDT',
  AAVE = 'AAVE',
  EURS = 'EURS',
  WMATIC = 'WMATIC',
  AGEUR = 'AGEUR',
  BAL = 'BAL',
  CRV = 'CRV',
  DPI = 'DPI',
  GHST = 'GHST',
  JEUR = 'JEUR',
  SUSHI = 'SUSHI',
}

export enum PositionDirection {
  To = 'To',
  From = 'From',
}

export enum PositionSides {
  Collateral = 'Collateral',
  Borrow = 'Borrow',
}

export enum LendingProtocolInteraction {
  Supply = 'Supply',
  Withdraw = 'Withdraw',
  Borrow = 'Borrow',
  Repay = 'Repay',
}

export enum MarginTradeType {
  Open = 'Open',
  Trim = 'Trim'
}

export enum AaveInterestMode {
  NONE = 0,
  STABLE = 1,
  VARIABLE = 2
}

export interface PositionEntry {
  asset: SupportedAssets
  side: PositionSides
}
export interface MarginTradeState {
  [PositionDirection.To]?: PositionEntry
  [PositionDirection.From]?: PositionEntry
  isSingle?: boolean
}

export const handleSelectNewAsset = (state: MarginTradeState, newAsset: SupportedAssets, newSide: PositionSides): MarginTradeState => {
  const newState = { ...state }
  const newPositionEntry = { asset: newAsset, side: newSide }
  const { [PositionDirection.To]: toEntry, [PositionDirection.From]: fromEntry } = state

  // uset single flag
  if (newState.isSingle) {
    newState.isSingle = false
  }

  // the new selection will always be stored in the "To" entry
  newState[PositionDirection.To] = newPositionEntry

  // case for first selection
  if (!toEntry) {
    newState[PositionDirection.From] = undefined
    newState.isSingle = true
    return newState
  }

  // We have the case that the user selects an asset already selected, but on the other side
  // the 1st caseis that toEntry matches selection
  if (newPositionEntry.asset === toEntry?.asset && newPositionEntry.side != toEntry?.side) {
    // then the from entry remains as is and we return the state
    return newState
  } else {
    // all other cases will move the toEntry to the new from entry
    // that also covers the match of the new selection with the fromEntry
    newState[PositionDirection.From] = toEntry
    return newState
  }
}

export const handleUnSelectAsset = (state: MarginTradeState, asset: SupportedAssets, assetSide: PositionSides): MarginTradeState => {
  const newState = { ...state }
  const { [PositionDirection.To]: toEntry, [PositionDirection.From]: fromEntry } = state
  newState.isSingle = true
  if (toEntry?.asset == asset && toEntry?.side == assetSide) {
    newState[PositionDirection.To] = undefined

  }
  if (fromEntry?.asset == asset && fromEntry?.side == assetSide) {
    newState[PositionDirection.From] = undefined

  }

  return newState
}

export const getSingleAsset = (state: MarginTradeState): SupportedAssets => {
  invariant(state.isSingle, "NOT SINGLE")

  const { [PositionDirection.To]: toEntry, [PositionDirection.From]: fromEntry } = state

  if (toEntry?.asset) {
    return toEntry.asset
  }

  if (fromEntry?.asset) {
    return fromEntry.asset
  }

  invariant(false, "NO SELECTION")
}

export const getSinglePositionEntry = (state: MarginTradeState): PositionEntry | undefined => {

  const { [PositionDirection.To]: toEntry, [PositionDirection.From]: fromEntry } = state

  if (toEntry?.asset) {
    return toEntry
  }

  if (fromEntry?.asset) {
    return fromEntry
  }
  return undefined
}


export const getAssetsOnSide = (state: MarginTradeState, side: PositionSides): SupportedAssets[] => {
  const assetList: SupportedAssets[] = []
  const { [PositionDirection.To]: toEntry, [PositionDirection.From]: fromEntry } = state
  if (toEntry?.asset) {
    if (toEntry?.side === side)
      assetList.push(toEntry.asset)
  }
  if (fromEntry?.asset) {
    if (fromEntry?.side === side)
      assetList.push(fromEntry.asset)
  }

  return assetList
}

export const positionEntriesAreMatching = (entry0: PositionEntry, entry1?: PositionEntry): boolean => {
  return entry0?.asset === entry1?.asset && entry0.side === entry1.side
}


export enum FeeAmount {
  LOW = 500,
  MEDIUM = 3000,
  HIGH = 10000,
}


const FEE_SIZE = 3

export function encodePath(path: string[], fees: number[]): string {
  if (path.length != fees.length + 1) {
    throw new Error('path/fee lengths do not match')
  }

  let encoded = '0x'
  for (let i = 0; i < fees.length; i++) {
    // 20 byte encoding of the address
    encoded += path[i].slice(2)
    // 3 byte encoding of the fee
    encoded += fees[i].toString(16).padStart(2 * FEE_SIZE, '0')
  }
  // encode the final token
  encoded += path[path.length - 1].slice(2)

  return encoded.toLowerCase()
}


export const getTradeTypeDescription = (state: MarginTradeState): string => {
  const { [PositionDirection.To]: toEntry, [PositionDirection.From]: fromEntry } = state

  if (fromEntry?.asset && toEntry?.asset) {
    if (fromEntry.side === PositionSides.Borrow && toEntry.side === PositionSides.Borrow) {
      return 'Carry Over Your Debts'
    }
    if (fromEntry.side === PositionSides.Collateral && toEntry.side === PositionSides.Borrow) {
      return 'Trade On Margin'
    }
    if (fromEntry.side === PositionSides.Borrow && toEntry.side === PositionSides.Collateral) {
      return 'Trade On Margin'
    }
    if (fromEntry.side === PositionSides.Collateral && toEntry.side === PositionSides.Collateral) {
      return 'Trade Your Collaterals'
    }
  }

  if (fromEntry?.asset && !toEntry?.asset) {
    if (fromEntry.side === PositionSides.Collateral) {
      return ' Trade To Supply Or Withdraw Collateral'
    }
    if (fromEntry.side === PositionSides.Borrow) {
      return 'Trade From Borrow Or To Repay'
    }
  }

  if (toEntry?.asset && !fromEntry?.asset) {
    if (toEntry.side === PositionSides.Collateral) {
      return ' Trade To Supply Or Withdraw Collateral'
    }
    if (toEntry.side === PositionSides.Borrow) {
      return 'Trade From Borrow Or To Repay'
    }
  }

  return 'Pick A Position to Open A Trade'
}

export enum OneDeltaTradeType {
  None = 'None',
  Single = 'Single',
  SingleSide = 'SingleSide',
  MarginSwap = 'MarginSwap'
}

export const getMarginTradeType = (state: MarginTradeState): OneDeltaTradeType => {
  const { [PositionDirection.To]: toEntry, [PositionDirection.From]: fromEntry } = state

  if (!fromEntry?.asset && !toEntry?.asset) return OneDeltaTradeType.None

  if (!fromEntry?.asset || !toEntry?.asset) return OneDeltaTradeType.Single

  if (fromEntry?.asset && toEntry?.asset) {
    if ((fromEntry.side === PositionSides.Collateral && toEntry.side === PositionSides.Borrow)
      || (fromEntry.side === PositionSides.Borrow && toEntry.side === PositionSides.Collateral)) {
      return OneDeltaTradeType.MarginSwap
    }
    return OneDeltaTradeType.SingleSide
  }
  return OneDeltaTradeType.None
}