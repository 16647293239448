import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { addressesAaveAggregators, addressesAaveATokens, addressesAaveSTokens, addressesAaveTokens, addressesAaveVTokens, getAddressesForChainIdFromAssetDict } from 'hooks/1delta/addresses'
import { getAavePoolContract, getAavePoolDataProviderContract } from 'hooks/1delta/use1DeltaContract'
import { SerializedBigNumber, SerializedNumber, SupportedAssets } from 'types/1delta'
import multicall, { Call } from 'utils/multicall'
import POOL_ABI from '../../hooks/1delta/abis/AAVEPoolV3.json'
import AAVE_POOL_DATA_PROVIDER_ABI from '../../hooks/1delta/abis/AAVEProtocolDataProvider.json'
import AGGREGATOR_ABI from '../../hooks/1delta/abis/AggregatorInterface.json'
import A_SUPPLY_API from '../../hooks/1delta/abis/AAVESupply.json'
import { ethers } from 'ethers'

export interface AAVEPoolV3PublicResponse {
  data: {
    [tokenSymbol: string]: {
      currentStableBorrowRate: SerializedNumber
      currentLiquidityRate: SerializedNumber
      currentVariableBorrowRate: SerializedNumber
      aTokenAddress: string
      stableDebtTokenAddress: string
      variableDebtTokenAddress: string
      interestRateStrategyAddress: string
    }
  }
}

export interface AAVEPoolV3PublicQueryParams {
  chainId: number
}

export const fetchAAVEPublicDataAsync:
  AsyncThunk<AAVEPoolV3PublicResponse, AAVEPoolV3PublicQueryParams, any> =
  createAsyncThunk<AAVEPoolV3PublicResponse, AAVEPoolV3PublicQueryParams>(
    '1delta/fetchAAVEPublicDataAsync',

    async ({ chainId }) => {
      const rawAddressDict = getAddressesForChainIdFromAssetDict(addressesAaveTokens, chainId)
      const poolContract = getAavePoolContract(chainId)
      const tokens = Object.values(rawAddressDict)
      const names = Object.keys(rawAddressDict)
      const calls: Call[] = tokens.map(tk => {
        return {
          address: poolContract.address,
          name: 'getReserveData',
          params: [tk]
        }
      })

      const multicallResult = await multicall(chainId, POOL_ABI, calls)

      const result = Object.assign({}, ...multicallResult.map((entry, index) => {
        return {
          [names[index]]: {
            underlyingAddress: tokens[index],
            currentStableBorrowRate: ethers.utils.formatEther(entry[0].currentStableBorrowRate ?? '0'),
            currentLiquidityRate: ethers.utils.formatEther(entry[0].currentLiquidityRate ?? '0'),
            currentVariableBorrowRate: ethers.utils.formatEther(entry[0].currentVariableBorrowRate ?? '0'),
            stableDebtTokenAddress: entry[0]?.stableDebtTokenAddress,
            variableDebtTokenAddress: entry[0]?.variableDebtTokenAddress,
            interestRateStrategyAddress: entry[0]?.interestRateStrategyAddress,
            aTokenAddress: entry[0]?.aTokenAddress
          }
        }
      }))

      return { data: result }
    }
  )

export interface AAVEPoolV3LiquidityResponse {
  data: {
    [tokenSymbol: string]: {
      aData: { supply: SerializedBigNumber }
      vData: { scaledSupply: SerializedBigNumber }
      sData: {
        principal: SerializedBigNumber
        supply: SerializedBigNumber
        averageStableRate: SerializedBigNumber
        lastUpdated: number
      }

    }
  }
}

export interface AAVEPoolV3LiquidityQueryParams {
  chainId: number,
}

export const fetchAAVELiquidityDataAsync:
  AsyncThunk<AAVEPoolV3LiquidityResponse, AAVEPoolV3LiquidityQueryParams, any> =
  createAsyncThunk<AAVEPoolV3LiquidityResponse, AAVEPoolV3LiquidityQueryParams>(
    '1delta/fetchAAVELiquidityDataAsync',

    async ({ chainId }) => {
      const rawAddressATokensDict = getAddressesForChainIdFromAssetDict(addressesAaveATokens, chainId)
      const aTokens = Object.values(rawAddressATokensDict)
      const names = Object.keys(rawAddressATokensDict)

      const callsSupply: Call[] = aTokens.map(tk => {
        return {
          address: tk,
          name: 'totalSupply',
          params: []
        }
      })
      const rawAddressVTokensDict = getAddressesForChainIdFromAssetDict(addressesAaveVTokens, chainId)
      const vTokens = Object.values(rawAddressVTokensDict)
      const callsVariableDebt: Call[] = vTokens.map(tk => {
        return {
          address: tk,
          name: 'scaledTotalSupply',
          params: []
        }
      })
      const rawAddressSTokensDict = getAddressesForChainIdFromAssetDict(addressesAaveSTokens, chainId)
      const sTokens = Object.values(rawAddressSTokensDict)
      const callsStableDebt: Call[] = sTokens.map(tk => {
        return {
          address: tk,
          name: 'getSupplyData',
          params: []
        }
      })

      const calls = [
        ...callsSupply,
        ...callsVariableDebt,
        ...callsStableDebt
      ]

      const multicallResult = await multicall(
        chainId,
        A_SUPPLY_API, // cusom abi merged for these calls
        calls
      )

      const resA = multicallResult.slice(0, callsSupply.length)
      const resV = multicallResult.slice(callsSupply.length, 2 * callsSupply.length)
      const resS = multicallResult.slice(2 * callsSupply.length, multicallResult.length)

      // map arrays to objects
      const result = Object.assign({}, ...resA.map((entry, index) => {
        return {
          [names[index]]: {
            aData: { supply: entry?.[0]?.toString() },
            vData: { scaledSupply: resV?.[index]?.[0]?.toString() },
            sData: {
              principal: resS?.[index]?.[0]?.toString(),
              supply: resS?.[index]?.[1]?.toString(),
              averageStableRate: resS?.[index]?.[2]?.toString(),
              lastUpdated: resS?.[index]?.[3]
            }
          }
        }
      }))

      return { data: result }
    }
  )

export interface AAVEAggregatorResponse {
  data: {
    [tokenSymbol: string]: {
      price: SerializedBigNumber
    }
  }
}

export interface AAVEAggregatorQueryParams {
  chainId: number
}

export const fetchAAVEAggregatorDataAsync:
  AsyncThunk<AAVEAggregatorResponse, AAVEAggregatorQueryParams, any> =
  createAsyncThunk<AAVEAggregatorResponse, AAVEAggregatorQueryParams>(
    '1delta/fetchAAVEAggregatorDataAsync',

    async ({ chainId }) => {
      const rawAggregatorDict = getAddressesForChainIdFromAssetDict(addressesAaveAggregators, chainId)
      const aaveAggregators = Object.values(rawAggregatorDict)
      const names = Object.keys(rawAggregatorDict)

      const calls: Call[] = aaveAggregators.map(tk => {
        return {
          address: tk,
          name: 'latestAnswer',
          params: []
        }
      })

      const multicallResult = await multicall(chainId, AGGREGATOR_ABI, calls)

      const result = Object.assign({}, ...multicallResult.map((entry, index) => {
        return {
          [names[index]]: {
            price: entry[0].toString(), // && ethers.utils.formatEther(entry[0]?.mul(ethers.BigNumber.from(10).pow(10))),
          }
        }
      }))

      return { data: result }
    }
  )



export interface AAVEPoolV3ReserveResponse {
  data: {
    [tokenSymbol: string]: {
      // reserves
      unbacked: SerializedBigNumber,
      accruedToTreasuryScaled: SerializedBigNumber,
      totalAToken: SerializedBigNumber,
      totalStableDebt: SerializedBigNumber,
      totalVariableDebt: SerializedBigNumber,
      liquidityRate: SerializedBigNumber,
      variableBorrowRate: SerializedBigNumber,
      stableBorrowRate: SerializedBigNumber,
      averageStableBorrowRate: SerializedBigNumber,
      liquidityIndex: SerializedBigNumber,
      variableBorrowIndex: SerializedBigNumber,
      lastUpdateTimestamp: number,
    }
  }
}

export interface AAVEPoolV3ReservesQueryParams {
  chainId: number
  assetsToQuery: SupportedAssets[]
}

export const fetchAAVEReserveDataAsync:
  AsyncThunk<AAVEPoolV3ReserveResponse, AAVEPoolV3ReservesQueryParams, any> =
  createAsyncThunk<AAVEPoolV3ReserveResponse, AAVEPoolV3ReservesQueryParams>(
    '1delta/fetchAAVEReserveDataAsync',

    async ({ chainId, assetsToQuery }) => {
      const providerContract = getAavePoolDataProviderContract(chainId)
      const assets = assetsToQuery.map(a => getAddressesForChainIdFromAssetDict(addressesAaveTokens, chainId)[a])


      const names = Object.keys(getAddressesForChainIdFromAssetDict(addressesAaveATokens, chainId))

      const calls: Call[] = assets.map(tk => {
        return {
          address: providerContract.address,
          name: 'getReserveData',
          params: [tk]
        }
      })

      const multicallResult: any[] = await multicall(
        chainId,
        AAVE_POOL_DATA_PROVIDER_ABI,
        calls
      )

      const result: any = Object.assign({}, ...multicallResult.map((entry: any, index) => {
        return {
          [names[index]]: {
            unbacked: entry?.unbacked.toString(),
            accruedToTreasuryScaled: entry?.accruedToTreasuryScaled.toString(),
            totalAToken: entry?.totalAToken.toString(),
            totalStableDebt: entry?.totalStableDebt.toString(),
            totalVariableDebt: entry?.totalVariableDebt.toString(),
            liquidityRate: entry?.liquidityRate.toString(),
            variableBorrowRate: entry?.variableBorrowRate.toString(),
            stableBorrowRate: entry?.stableBorrowRate.toString(),
            averageStableBorrowRate: entry?.averageStableBorrowRate.toString(),
            liquidityIndex: entry?.liquidityIndex.toString(),
            variableBorrowIndex: entry?.variableBorrowIndex.toString(),
            lastUpdateTimestamp: entry?.lastUpdateTimestamp
          }
        }
      }
      ))
      return {
        data: result
      }
    }
  )



export interface AAVEPoolV3ReserveConfigResponse {
  data: {
    [tokenSymbol: string]: {
      // reserve config
      decimals?: number,
      ltv?: SerializedBigNumber,
      liquidationThreshold?: SerializedBigNumber,
      liquidationBonus?: SerializedBigNumber,
      reserveFactor?: SerializedBigNumber,
      usageAsCollateralEnabled?: boolean,
      borrowingEnabled?: boolean,
      stableBorrowRateEnabled?: boolean,
      isActive?: boolean,
      isFrozen?: boolean
    }
  }
}

export interface AAVEPoolV3ReserveConfigsQueryParams {
  chainId: number
  assetsToQuery: SupportedAssets[]
}

export const fetchAAVEReserveConfigDataAsync:
  AsyncThunk<AAVEPoolV3ReserveConfigResponse, AAVEPoolV3ReserveConfigsQueryParams, any> =
  createAsyncThunk<AAVEPoolV3ReserveConfigResponse, AAVEPoolV3ReserveConfigsQueryParams>(
    '1delta/fetchAAVEReserveConfigDataAsync',

    async ({ chainId, assetsToQuery }) => {
      const providerContract = getAavePoolDataProviderContract(chainId)
      const assets = assetsToQuery.map(a => getAddressesForChainIdFromAssetDict(addressesAaveTokens, chainId)[a])


      const names = Object.keys(getAddressesForChainIdFromAssetDict(addressesAaveATokens, chainId))

      const calls: Call[] = assets.map(tk => {
        return {
          address: providerContract.address,
          name: 'getReserveConfigurationData',
          params: [tk]
        }
      })

      const multicallResult: any[] = await multicall(
        chainId,
        AAVE_POOL_DATA_PROVIDER_ABI,
        calls
      )

      const result: any = Object.assign({}, ...multicallResult.map((entry: any, index) => {
        return {
          [names[index]]: {
            decimals: Number(entry?.decimals),
            ltv: entry?.ltv.toString(),
            liquidationThreshold: entry?.liquidationThreshold.toString(),
            liquidationBonus: entry?.liquidationBonus.toString(),
            reserveFactor: entry?.reserveFactor.toString(),
            usageAsCollateralEnabled: entry?.usageAsCollateralEnabled,
            borrowingEnabled: entry?.borrowingEnabled,
            stableBorrowRateEnabled: entry?.stableBorrowRateEnabled,
            isActive: entry?.isActive,
            isFrozen: entry?.isFrozen
          }
        }
      }
      ))
      return {
        data: result
      }
    }
  )