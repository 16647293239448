import Toggle from '1delta/components/Toggle'
import TokenDetail from '1delta/components/TokenDetail'
import { useWeb3React } from '@web3-react/core'
import { BigNumber, ethers } from 'ethers'
import { safeGetToken } from 'hooks/1delta/tokens'
import { getAavePoolContract } from 'hooks/1delta/use1DeltaContract'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Check } from 'react-feather'
import { fetchAAVEUserReserveDataAsync } from 'state/1delta/fetchAAVEUserData'
import { useAppDispatch } from 'state/hooks'
import styled, { useTheme } from 'styled-components/macro'
import { Asset } from 'types/1delta'
import { Cell, CheckboxWrapper, Circle, Row, ToggleContainer } from '../Styles'
import { formatAaveYield, formatAbbrivatedPrice } from '../utils/format'

const ResponsiveCheck = styled(Check)``

export function ButtonRadioChecked({ active, onClick }: { active: boolean; onClick: () => void }) {
  const theme = useTheme()

  return (
    <CheckboxWrapper onClick={onClick}>
      <Circle active={active}>
        <ResponsiveCheck size={13} stroke={theme.deprecated_white} />
      </Circle>
    </CheckboxWrapper>
  )
}

interface MarketTableRowProps {
  isEditing?: boolean
  asset: Asset
  isCheckEnabled: boolean
  onCheckMarkToggle: () => void
}

export default function CollateralTableRow(props: MarketTableRowProps) {
  const [isToggled, setToggled] = useState(props.asset?.userData?.usageAsCollateralEnabled ?? false)
  const supplyLoaded = Boolean(props.asset.aData?.supply)
  const { chainId, account, provider } = useWeb3React()
  const poolContract = getAavePoolContract(chainId ?? 5, account, provider)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!isToggled && props.asset?.userData?.usageAsCollateralEnabled) {
      setToggled(true)
    }
  }, [props.asset.userData])

  // this is the total supply, the coonversion to USD is at the bottom
  const formattedSupply = useMemo(() => {
    return supplyLoaded
      ? Number(
        ethers.utils.formatEther(
          ethers.BigNumber.from(props.asset.aData?.supply).mul(
            BigNumber.from(10).pow(18 - (props.asset.decimals ?? 0))
          ) ?? '0'
        )
      )
      : 0
  }, [props.asset.decimals, props.asset.aData, supplyLoaded])

  const [formattedBalance, formattedUsdBalance, price] = useMemo(() => {
    const balance = Number(
      ethers.utils.formatEther(
        ethers.BigNumber.from(props.asset.userData?.currentATokenBalance ?? '0').mul(
          BigNumber.from(10).pow(18 - (props.asset.decimals ?? 0))
        )
      )
    )
    const price = ethers.utils.formatEther(ethers.BigNumber.from(props.asset?.price ?? '0').mul(ethers.BigNumber.from(10).pow(10)))
    return [balance, Number(price) * balance, price]
  }, [props.asset.decimals, props.asset.userData])

  const depositedAmount = useMemo(() => {
    return props.asset?.userData?.currentATokenBalance ?? '0'
  }, [props.asset.userData])

  const handleCollateralConfig = useCallback(async () => {
    if (!account) return () => null
    if (ethers.BigNumber.from(depositedAmount).eq(0)) return () => null
    try {
      const tx = await poolContract.setUserUseReserveAsCollateral(safeGetToken(chainId ?? 5, props.asset.id).address, !isToggled)
      const receipt = await tx.wait()
      setToggled(!isToggled)
      dispatch(fetchAAVEUserReserveDataAsync({ chainId: 5, account, assetsToQuery: [props.asset.id] }))
      return receipt.status
    } catch (e) {
      return false
    }
  }, [poolContract, account])

  const reservesLoaded = Boolean(props.asset.reserveData)

  const aprSupply = useMemo(() => {
    if (!reservesLoaded) return 0
    return formatAaveYield(props.asset?.reserveData?.liquidityRate ?? '0')
  }, [props.asset.reserveData])


  return (
    <Row>
      {props.isEditing && (
        <Cell>
          <div>
            <ButtonRadioChecked active={props.isCheckEnabled} onClick={props.onCheckMarkToggle} />
          </div>
        </Cell>
      )}
      <Cell>
        <TokenDetail asset={props.asset.id} price={price} />
      </Cell>
      <Cell>
        <span>{`${aprSupply}%`}</span>
      </Cell>
      {account && <Cell>{formattedBalance.toLocaleString()}</Cell>}
      <Cell>{supplyLoaded ? formatAbbrivatedPrice(Number(price) * formattedSupply) : '-'}</Cell>
      {account && <Cell>
        <ToggleContainer>
          <Toggle isActive={isToggled} toggle={handleCollateralConfig} />
        </ToggleContainer>
      </Cell>}
    </Row>
  )
}
