import { formatPriceString } from '1delta/MarketTable/utils/format'
import { getTokenIcon } from 'constants/1delta'
import styled from 'styled-components'
import { SupportedAssets } from 'types/1delta'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const Image = styled.img`
  height: 32px;
  padding-right: 8px;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const AssetName = styled.span`
  font-weight: 500;
  font-size: 15px;
  padding-bottom: 2px;
`
const Price = styled.span`
  font-size: 12px;
`

export default function TokenIcon({ asset, price }: { asset: SupportedAssets; price?: string }) {
  return (
    <Wrapper>
      <Image src={getTokenIcon(asset)} />
      <TextContainer>
        <AssetName>{asset}</AssetName>
        <Price>{formatPriceString(price)}</Price>
      </TextContainer>
    </Wrapper>
  )
}
