

import { ButtonLight } from 'components/Button'
import { Box, CheckCircle, Circle } from 'react-feather'
import styled, { useTheme } from 'styled-components/macro'
import { colors, colorsLight } from 'theme/colors'
import { AaveInterestMode } from 'types/1delta'


const ButtonBox = styled.div`
margin-left: 20px;
display: flex;
flex-direction: column;
align-items: space-between;
justify-content: center;
align-self:center;
`


const PanelContainer = styled.div`
display: flex;
flex-direction: row;
align-items: space-between;
justify-content: center;
margin: 5px;
`

const YieldContainer = styled.div`
padding: 10px;
display: flex;
flex-direction: row;
align-items: space-between;
justify-content: space-between;
`

const YieldColumn = styled.div`
padding: 10px;
display: flex;
flex-direction: column;
align-items: space-between;
justify-content: center;
`

export const YieldRowLeft = styled.div<{ marked: boolean }>`
margin-right: 10px;
flex-direction: row;
display: flex;
width: 100%;
justify-content: space-between;
${({ marked }) => marked ? `

` : ''}
`

export const SelfCenteredText = styled.div`
align-self:center;
`

export const YieldRowRight = styled.div<{ marked: boolean }>`
margin-left: 10px;
flex-direction: row;
display: flex;
width: 100%;
justify-content: space-between;
${({ marked }) => marked ? `

` : ''}
`

interface DebtOptionButtonProps {
  handleSelectInterestMode: () => void
  hasStableBorrow: boolean
  selectedBorrowInterestMode: AaveInterestMode
  borrowRateStable: string
  borrowRateVariable: string
}

export const DebtOptionButton = (
  {
    handleSelectInterestMode,
    hasStableBorrow,
    selectedBorrowInterestMode,
    borrowRateStable,
    borrowRateVariable,
  }: DebtOptionButtonProps
) => {
  return <ButtonBox>
    {hasStableBorrow && <ButtonLight height={'30px'}
      onClick={handleSelectInterestMode}
      style={{
        borderTopLeftRadius: '16px',
        borderBottomLeftRadius: '0px',
        marginLeft: '5px',
        marginRight: '3px',
        borderBottomRightRadius: '0px',
        borderTopRightRadius: '16px',
        width: '350px',
      }}
    >
      <YieldRowRight marked={selectedBorrowInterestMode === AaveInterestMode.STABLE}>
        {selectedBorrowInterestMode === AaveInterestMode.STABLE ? <CheckCircle /> : <Circle />}
        <SelfCenteredText>
          Borrow rate stable:
        </SelfCenteredText>
        <SelfCenteredText>
          {borrowRateStable}%
        </SelfCenteredText></YieldRowRight>
    </ButtonLight>
    }
    <ButtonLight height={'30px'}
      onClick={handleSelectInterestMode}
      style={{
        borderTopLeftRadius: hasStableBorrow ? '0px' : '16px',
        borderBottomLeftRadius: '0px',
        marginLeft: '5px',
        marginRight: '3px',
        borderBottomRightRadius: '0px',
        borderTopRightRadius: hasStableBorrow ? '0px' : '16px',
        width: '350px'
      }}
    >
      <YieldRowRight marked={selectedBorrowInterestMode === AaveInterestMode.VARIABLE}>
        {selectedBorrowInterestMode === AaveInterestMode.VARIABLE ? <CheckCircle /> : <Circle />}
        <SelfCenteredText>
          Borrow rate variable:
        </SelfCenteredText>
        <SelfCenteredText>
          {borrowRateVariable}%
        </SelfCenteredText>
      </YieldRowRight>
    </ButtonLight>
  </ButtonBox>

}


interface CollateralYield {
  liquidityRate: string
}

export const DepositYield = ({ liquidityRate }: CollateralYield) => {

  return (<ButtonBox>
    <ButtonLight height={'30px'}
      onClick={() => null}
      style={{
        borderTopLeftRadius: '16px',
        borderBottomLeftRadius: '0px',
        marginLeft: '5px',
        marginRight: '3px',
        borderBottomRightRadius: '0px',
        borderTopRightRadius: '16px',
        width: '350px'
      }}
    >
      <YieldRowLeft marked={false}>
        <Box />
        <SelfCenteredText>
          Deposit rate:
        </SelfCenteredText>
        <SelfCenteredText>
          {liquidityRate}%
        </SelfCenteredText>
      </YieldRowLeft>
    </ButtonLight>
  </ButtonBox>)
}