import { formatAaveYield } from '1delta/MarketTable/utils/format'
import { Trans } from '@lingui/macro'
import { RouteV3, Trade } from '@uniswap/router-sdk'
import { Currency, CurrencyAmount, Token, TradeType } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { sendAnalyticsEvent } from 'analytics'
import { ElementName, Event, EventName, PageName, SectionName } from 'analytics/constants'
import { Trace } from 'analytics/Trace'
import { TraceEvent } from 'analytics/TraceEvent'
import { sendEvent } from 'components/analytics'
import CurrencyInputCustomList from 'components/CurrencyInputPanel/CustomListInputPanel/CurrencyInputCustomList'
import GeneralCurrencyInputPanel from 'components/CurrencyInputPanel/GeneralInputPanel/GeneralCurrencyInputPanel'
import { NetworkAlert } from 'components/NetworkAlert/NetworkAlert'
import PriceImpactWarning from 'components/swap/PriceImpactWarning'
import SwapDetailsDropdown from 'components/swap/SwapDetailsDropdown'
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
import TokenSafetyModal from 'components/TokenSafety/TokenSafetyModal'
import { MouseoverTooltip } from 'components/Tooltip'
import { isSupportedChain } from 'constants/chains'
import { ethers } from 'ethers'
import { NavBarVariant, useNavBarFlag } from 'featureFlags/flags/navBar'
import { RedesignVariant, useRedesignFlag } from 'featureFlags/flags/redesign'
import { filterSupportedAssets, getAaveTokens } from 'hooks/1delta/tokens'
import { getMarginTraderContract, getMoneyMarketOperatorContract } from 'hooks/1delta/use1DeltaContract'
import { useApproveCallbackFromGeneralTrade, useApproveCallbackFromMarginTrade, useDelegateBorrowCallbackFromGeneralTrade } from 'hooks/use1DeltaMarginSwapCallback'
import { useSwapCallback } from 'hooks/useSwapCallback'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import JSBI from 'jsbi'
import { useCurrencyBalances } from 'lib/hooks/useCurrencyBalance'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ReactNode } from 'react'
import { ArrowDown, ArrowUp, CheckCircle, HelpCircle } from 'react-feather'
import { Text } from 'rebass'
import { fetchAAVEUserReserveDataAsync } from 'state/1delta/fetchAAVEUserData'
import { useDeltaAssetState } from 'state/1delta/hooks'
import { useToggleWalletModal } from 'state/application/hooks'
import { useAppDispatch } from 'state/hooks'
import { TradeState } from 'state/routing/types'
import styled, { useTheme } from 'styled-components/macro'
import { AaveInterestMode, LendingProtocolInteraction, PositionSides, SupportedAssets } from 'types/1delta'

import AddressInputPanel from '../../../components/AddressInputPanel'
import { BaseButton, ButtonConfirmed, ButtonLight, ButtonPrimary } from '../../../components/Button'
import { GreyCard } from '../../../components/Card'
import { AutoColumn } from '../../../components/Column'
import Loader from '../../../components/Loader'
import { AutoRow } from '../../../components/Row'
import confirmPriceImpactWithoutFee from '../../../components/swap/confirmPriceImpactWithoutFee'
import ConfirmSwapModal from '../../../components/swap/ConfirmSwapModal'
import { ArrowWrapper, PageWrapper, SwapCallbackError, SwapWrapper } from '../../../components/swap/styleds'
import { SwitchLocaleLink } from '../../../components/SwitchLocaleLink'
import { ApprovalState } from '../../../hooks/useApproveCallback'
import useENSAddress from '../../../hooks/useENSAddress'
import { useERC20PermitFromTrade, UseERC20PermitState } from '../../../hooks/useERC20Permit'
import useIsArgentWallet from '../../../hooks/useIsArgentWallet'
import { useIsSwapUnsupported } from '../../../hooks/useIsSwapUnsupported'
import { useStablecoinValue } from '../../../hooks/useStablecoinPrice'
import useWrapCallback, { WrapErrorText, WrapType } from '../../../hooks/useWrapCallback'
import { Field } from '../../../state/swap/actions'
import {
  useDerivedSwapInfoClientSideV3ProvidedCcy,
  useSwapActionHandlers,
  useSwapState,
} from '../../../state/swap/hooks'
import { useExpertModeManager } from '../../../state/user/hooks'
import { LinkStyledButton, ThemedText } from '../../../theme'
import { computeFiatValuePriceImpact } from '../../../utils/computeFiatValuePriceImpact'
import { maxAmountSpend } from '../../../utils/maxAmountSpend'
import { computeRealizedPriceImpact, warningSeverity } from '../../../utils/prices'
import { ArrowContainer, ArrowDownWrapper, ArrowUpWrapper, InputWrapper } from '../components/wrappers'
import { formatSwapQuoteReceivedEventProperties, getIsValidSwapQuote, largerPercentValue } from '../utils/formatters'
import { createSingleSideCalldata } from '../utils/singleSideMethodCreator'
import SingleSideTradeHeader from './SingleSideTradeHeader'
import { DebtOptionButton, DepositYield } from './YieldOptionButtons'



const Container = styled.div<{ redesignFlag: boolean }>`
  min-height: ${({ redesignFlag }) => redesignFlag && '69px'};
  border-radius: 32px;
  border: 1px solid ${({ theme, redesignFlag }) => (redesignFlag ? 'transparent' : theme.deprecated_bg0)};
  background-color: ${({ theme, redesignFlag }) => (redesignFlag ? 'transparent' : theme.deprecated_bg2)};
  width: initial;
  ${({ theme, redesignFlag }) =>
    !redesignFlag &&
    `
    :focus,
    :hover {
      border: 1px solid  ${theme.deprecated_bg3};
    }
  `}
`

const TRADE_STRING = 'SwapRouter'

export interface SingleSideSwapInterface {
  selectedAsset0: Token
  selectedAsset1: Token
  side: PositionSides
}

export default function SingleSideSwap({ selectedAsset0, selectedAsset1, side }: SingleSideSwapInterface) {
  const navBarFlag = useNavBarFlag()
  const navBarFlagEnabled = navBarFlag === NavBarVariant.Enabled
  const redesignFlag = useRedesignFlag()
  const redesignFlagEnabled = redesignFlag === RedesignVariant.Enabled
  const { account, chainId, provider } = useWeb3React()
  const [newSwapQuoteNeedsLogging, setNewSwapQuoteNeedsLogging] = useState(true)
  const [fetchingSwapQuoteStartTime, setFetchingSwapQuoteStartTime] = useState<Date | undefined>()

  const restrictedTokenList = useMemo(() => {
    return getAaveTokens(chainId ?? 5)
  }, [chainId])


  const dispatch = useAppDispatch()

  const [sourceBorrowInterestMode, setSourceBorrowInterestMode] = useState(AaveInterestMode.STABLE)

  const [targetBorrowInterestMode, setTargetBorrowInterestMode] = useState(AaveInterestMode.STABLE)

  const theme = useTheme()

  // toggle wallet when disconnected
  const toggleWalletModal = useToggleWalletModal()

  // for expert mode
  const [isExpertMode] = useExpertModeManager()

  const [assetState, switchAssets] = useState<{ assetIn: SupportedAssets, assetOut: SupportedAssets }>(
    {
      assetIn: selectedAsset0.symbol as SupportedAssets,
      assetOut: selectedAsset1.symbol as SupportedAssets
    }
  )

  const handleSwitchAssets = useCallback(() => {
    return switchAssets({ assetIn: assetState.assetOut, assetOut: assetState.assetIn })
  },
    [assetState]
  )
  const deltaAssets = useDeltaAssetState()

  const { assetIn, assetOut } = useMemo(() => {
    return { assetIn: deltaAssets[assetState.assetIn], assetOut: deltaAssets[assetState.assetOut] }
  },
    [deltaAssets, assetState]
  )

  const [currencyAmounts, userDebtTypeIn, userDebtTypeOut] = useMemo(() => {
    // default debt types
    let _userDebtTypeIn = AaveInterestMode.NONE
    let _userDebtTypeOut = AaveInterestMode.NONE
    // get ccys
    const [currencyIn, currencyOut] = String(assetIn.id) === selectedAsset0.symbol ? [selectedAsset0, selectedAsset1] : [selectedAsset1, selectedAsset0]
    let _ccyAmounts = undefined;

    // for a collateral swap, debt types are irrelevant
    if (side === PositionSides.Collateral) {
      _ccyAmounts = {
        [Field.INPUT]: CurrencyAmount.fromRawAmount(currencyIn, assetIn?.userData?.currentATokenBalance ?? '0'),
        [Field.OUTPUT]: CurrencyAmount.fromRawAmount(currencyOut, assetOut?.userData?.currentATokenBalance ?? '0'),
      }
    } else {
      // we see which debt the user owns and assign it if there is any of a specific type
      // if the input type  has not type (NONE), it shall be editable by the user
      const stableDebtIn = ethers.BigNumber.from(assetIn?.userData?.currentStableDebt ?? '0')
      const variableDebtIn = ethers.BigNumber.from(assetIn?.userData?.currentVariableDebt ?? '0')

      const stableDebtOut = ethers.BigNumber.from(assetOut?.userData?.currentStableDebt ?? '0')
      const variableDebtOut = ethers.BigNumber.from(assetOut?.userData?.currentVariableDebt ?? '0')

      if (assetIn?.reserveData?.stableBorrowRateEnabled && stableDebtIn.gt(0)) _userDebtTypeIn = AaveInterestMode.STABLE
      if (assetOut?.reserveData?.stableBorrowRateEnabled && stableDebtOut.gt(0)) _userDebtTypeOut = AaveInterestMode.STABLE
      if (variableDebtIn.gt(0)) _userDebtTypeIn = AaveInterestMode.VARIABLE
      if (variableDebtOut.gt(0)) _userDebtTypeOut = AaveInterestMode.VARIABLE

      // assign debt balances
      _ccyAmounts = {
        [Field.INPUT]: CurrencyAmount.fromRawAmount(currencyIn, (stableDebtIn.gt(0) ? stableDebtIn.toString() : variableDebtIn.toString()) ?? '0'),
        [Field.OUTPUT]: CurrencyAmount.fromRawAmount(currencyOut, (stableDebtOut.gt(0) ? stableDebtOut.toString() : variableDebtOut.toString()) ?? '0'),
      }
    }

    return [_ccyAmounts, _userDebtTypeIn, _userDebtTypeOut]
  },
    [assetIn, assetOut, selectedAsset0, selectedAsset1, assetState]
  )

  const currencies: { [field in Field]?: Currency | null } = useMemo(
    () => ({
      [Field.INPUT]: currencyAmounts[Field.INPUT].currency,
      [Field.OUTPUT]: currencyAmounts[Field.OUTPUT].currency,
    }),
    [currencyAmounts]
  )

  const rawUserBalances = useCurrencyBalances(
    account ?? undefined,
    useMemo(() => [currencies[Field.INPUT] ?? undefined, currencies[Field.OUTPUT] ?? undefined], [currencies])
  )

  const currencyUserBalances = useMemo(
    () => ({
      [Field.INPUT]: rawUserBalances[0],
      [Field.OUTPUT]: rawUserBalances[1],
    }),
    [rawUserBalances]
  )

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()
  const {
    trade: { state: tradeState, trade },
    allowedSlippage,
    parsedAmount,
    inputError: swapInputError,
  } = useDerivedSwapInfoClientSideV3ProvidedCcy(currencyAmounts)
  console.log("TRADE", trade)
  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENSAddress(recipient)

  const parsedAmounts = useMemo(
    () =>
      showWrap
        ? {
          [Field.INPUT]: parsedAmount,
          [Field.OUTPUT]: parsedAmount,
        }
        : {
          [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
          [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
        },
    [independentField, parsedAmount, showWrap, trade]
  )

  const [routeNotFound, routeIsLoading, routeIsSyncing] = useMemo(
    () => [!trade?.swaps, TradeState.LOADING === tradeState, TradeState.SYNCING === tradeState],
    [trade, tradeState]
  )

  const fiatValueInput = useStablecoinValue(parsedAmounts[Field.INPUT])
  const fiatValueOutput = useStablecoinValue(parsedAmounts[Field.OUTPUT])
  const stablecoinPriceImpact = useMemo(
    () => (routeIsSyncing ? undefined : computeFiatValuePriceImpact(fiatValueInput, fiatValueOutput)),
    [fiatValueInput, fiatValueOutput, routeIsSyncing]
  )

  const { onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade<Currency, Currency, TradeType> | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  })

  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue,
      [dependentField]: showWrap
        ? parsedAmounts[independentField]?.toExact() ?? ''
        : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
    }),
    [dependentField, independentField, parsedAmounts, showWrap, typedValue]
  )

  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )

  const marginTraderContract = getMarginTraderContract(chainId ?? 5, provider, account)

  // check whether the user has approved the router on the input token
  const [approvalState, approveCallback] = useApproveCallbackFromGeneralTrade(
    trade,
    side === PositionSides.Collateral ? LendingProtocolInteraction.Withdraw :
      LendingProtocolInteraction.Borrow,
    Field.INPUT,
    marginTraderContract.address,
    allowedSlippage
  )

  // check whether the user has approved the router on the input token
  const [borrowApprovalState, approveBorrowCallback] = useDelegateBorrowCallbackFromGeneralTrade(
    trade,
    side === PositionSides.Collateral ? LendingProtocolInteraction.Withdraw :
      LendingProtocolInteraction.Borrow,
    Field.INPUT,
    marginTraderContract.address,
    allowedSlippage,
    AaveInterestMode.VARIABLE
  )


  const handleSelectInterestModeSource = useCallback(() => {
    if (!assetIn?.reserveData?.stableBorrowRateEnabled) {
      setSourceBorrowInterestMode(AaveInterestMode.VARIABLE)
      return null // no selections shall be possible
    } else {
      if (sourceBorrowInterestMode != AaveInterestMode.VARIABLE) return setSourceBorrowInterestMode(AaveInterestMode.VARIABLE)
      return setSourceBorrowInterestMode(AaveInterestMode.STABLE)
    }
  },
    [sourceBorrowInterestMode, assetIn]
  )

  const handleSelectInterestModeTarget = useCallback(() => {
    if (!assetOut?.reserveData?.stableBorrowRateEnabled) {
      setTargetBorrowInterestMode(AaveInterestMode.VARIABLE)
      return null // no selections shall be possible
    } else {
      if (targetBorrowInterestMode != AaveInterestMode.VARIABLE) return setTargetBorrowInterestMode(AaveInterestMode.VARIABLE)
      return setTargetBorrowInterestMode(AaveInterestMode.STABLE)
    }
  },
    [sourceBorrowInterestMode, assetOut]
  )

  const [approvalStateOfConcern, approveCallbackOfConcern] = useMemo(() => {
    if (side != PositionSides.Borrow)
      return [approvalState, approveCallback]
    else return [borrowApprovalState, approveBorrowCallback]
  },
    [side, approvalState, borrowApprovalState, assetIn.id, assetOut.id]
  )

  const transactionDeadline = useTransactionDeadline()
  const {
    state: signatureState,
    signatureData,
    gatherPermitSignature,
  } = useERC20PermitFromTrade(trade, allowedSlippage, transactionDeadline)

  const [approvalPending, setApprovalPending] = useState<boolean>(false)
  const handleApprove = useCallback(async () => {
    setApprovalPending(true)
    try {
      if (signatureState === UseERC20PermitState.NOT_SIGNED && gatherPermitSignature) {
        try {
          await gatherPermitSignature()
        } catch (error) {
          // try to approve if gatherPermitSignature failed for any reason other than the user rejecting it
          if (error?.code !== 4001) {
            await approveCallbackOfConcern()
          }
        }
      } else {
        await approveCallbackOfConcern()

        sendEvent({
          category: 'Swap',
          action: 'Approve',
          label: [TRADE_STRING, trade?.inputAmount?.currency.symbol].join('/'),
        })
      }
    } finally {
      setApprovalPending(false)
    }
  }, [signatureState, gatherPermitSignature, approveCallbackOfConcern, trade?.inputAmount?.currency.symbol])

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approvalStateOfConcern === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approvalStateOfConcern, approvalSubmitted])

  const maxInputAmount: CurrencyAmount<Currency> | undefined = useMemo(
    () => maxAmountSpend(currencyAmounts[Field.INPUT]),
    [currencyAmounts]
  )
  const showMaxButton = Boolean(maxInputAmount?.greaterThan(0) && !parsedAmounts[Field.INPUT]?.equalTo(maxInputAmount))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    trade,
    allowedSlippage,
    recipient,
    signatureData
  )
  const test = (trade?.routes[0] as RouteV3<Currency, Currency>)
  console.log("SWAP TRADE", test?.input?.symbol, test?.output?.symbol, test?.tokenPath,)

  const handleSwap = useCallback(async () => {
    if (!swapCallback) {
      return
    }
    if (stablecoinPriceImpact && !confirmPriceImpactWithoutFee(stablecoinPriceImpact)) {
      return
    }
    const v3Route = (trade?.routes[0] as RouteV3<Currency, Currency>)
    const { args, method, estimate, call } = createSingleSideCalldata(
      parsedAmounts,
      marginTraderContract,
      side,
      sourceBorrowInterestMode,
      userDebtTypeOut,
      account,
      v3Route,
      trade?.tradeType
    )
    console.log("SWAP ARGS", args, v3Route.path)
    setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
    if (call)
      await call().then((txResponse) => {
        setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: txResponse.hash })
        sendEvent({
          category: 'Swap',
          action: 'transaction hash',
          label: txResponse.hash,
        })
        sendEvent({
          category: 'Swap',
          action:
            recipient === null
              ? 'Swap w/o Send'
              : (recipientAddress ?? recipient) === account
                ? 'Swap w/o Send + recipient'
                : 'Swap w/ Send',
          label: [TRADE_STRING, trade?.inputAmount?.currency?.symbol, trade?.outputAmount?.currency?.symbol, 'MH'].join(
            '/'
          ),
        })
        dispatch(fetchAAVEUserReserveDataAsync({
          chainId: chainId ?? 5,
          account: account ?? '',
          assetsToQuery: filterSupportedAssets(trade?.inputAmount?.currency, trade?.outputAmount?.currency)
        })
        )
      })
        .catch((error) => {
          setSwapState({
            attemptingTxn: false,
            tradeToConfirm,
            showConfirm,
            swapErrorMessage: error.message,
            txHash: undefined,
          })
        })
  }, [
    swapCallback,
    stablecoinPriceImpact,
    tradeToConfirm,
    showConfirm,
    recipient,
    recipientAddress,
    account,
    trade?.inputAmount?.currency?.symbol,
    trade?.outputAmount?.currency?.symbol,
  ])


  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)
  const [swapQuoteReceivedDate, setSwapQuoteReceivedDate] = useState<Date | undefined>()

  // warnings on the greater of fiat value price impact and execution price impact
  const { priceImpactSeverity, largerPriceImpact } = useMemo(() => {
    const marketPriceImpact = trade?.priceImpact ? computeRealizedPriceImpact(trade) : undefined
    const largerPriceImpact = largerPercentValue(marketPriceImpact, stablecoinPriceImpact)
    return { priceImpactSeverity: warningSeverity(largerPriceImpact), largerPriceImpact }
  }, [stablecoinPriceImpact, trade])

  const isArgentWallet = useIsArgentWallet()

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow = // swap input error should be respected
    !isArgentWallet &&
    (approvalStateOfConcern === ApprovalState.NOT_APPROVED ||
      approvalStateOfConcern === ApprovalState.PENDING ||
      (approvalSubmitted && approvalStateOfConcern === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const handleMaxInput = useCallback(() => {
    maxInputAmount && onUserInput(Field.INPUT, maxInputAmount.toExact())
    sendEvent({
      category: 'Swap',
      action: 'Max',
    })
  }, [maxInputAmount, onUserInput])

  const maxOutputAmount: CurrencyAmount<Currency> | undefined = useMemo(
    () => maxAmountSpend(currencyAmounts[Field.OUTPUT]),
    [currencyAmounts]
  )

  const handleMaxOutput = useCallback(() => {
    maxOutputAmount && onUserInput(Field.OUTPUT, maxOutputAmount.toExact())
    sendEvent({
      category: 'Swap',
      action: 'Max',
    })
  }, [maxOutputAmount, onUserInput])

  const swapIsUnsupported = useIsSwapUnsupported(currencies[Field.INPUT], currencies[Field.OUTPUT])

  const priceImpactTooHigh = priceImpactSeverity > 3 && !isExpertMode
  const showPriceImpactWarning = largerPriceImpact && priceImpactSeverity > 3

  // Handle time based logging events and event properties.
  useEffect(() => {
    const now = new Date()
    // If a trade exists, and we need to log the receipt of this new swap quote:
    if (newSwapQuoteNeedsLogging && !!trade) {
      // Set the current datetime as the time of receipt of latest swap quote.
      setSwapQuoteReceivedDate(now)
      // Log swap quote.
      sendAnalyticsEvent(
        EventName.SWAP_QUOTE_RECEIVED,
        formatSwapQuoteReceivedEventProperties(trade, fetchingSwapQuoteStartTime)
      )
      // Latest swap quote has just been logged, so we don't need to log the current trade anymore
      // unless user inputs change again and a new trade is in the process of being generated.
      setNewSwapQuoteNeedsLogging(false)
      // New quote is not being fetched, so set start time of quote fetch to undefined.
      setFetchingSwapQuoteStartTime(undefined)
    }
    // If another swap quote is being loaded based on changed user inputs:
    if (routeIsLoading) {
      setNewSwapQuoteNeedsLogging(true)
      if (!fetchingSwapQuoteStartTime) setFetchingSwapQuoteStartTime(now)
    }
  }, [
    newSwapQuoteNeedsLogging,
    routeIsSyncing,
    routeIsLoading,
    fetchingSwapQuoteStartTime,
    trade,
    setSwapQuoteReceivedDate,
  ])

  const approveTokenButtonDisabled =
    approvalStateOfConcern !== ApprovalState.NOT_APPROVED || approvalSubmitted || signatureState === UseERC20PermitState.SIGNED

  const [borrowRateStableIn, borrowRateVariableIn, borrowRateStableOut, borrowRateVariableOut] = useMemo(() => {

    return [
      formatAaveYield(assetIn?.reserveData?.stableBorrowRate ?? '0'),
      formatAaveYield(assetIn?.reserveData?.variableBorrowRate ?? '0'),
      formatAaveYield(assetOut?.reserveData?.stableBorrowRate ?? '0'),
      formatAaveYield(assetOut?.reserveData?.variableBorrowRate ?? '0')
    ]
  }, [assetIn, assetOut])

  const [supplyRateIn, supplyRateOut] = useMemo(() => {

    return [
      formatAaveYield(assetIn?.reserveData?.liquidityRate ?? '0'),
      formatAaveYield(assetOut?.reserveData?.liquidityRate ?? '0'),
    ]
  }, [assetIn, assetOut])

  const [hasStableRateIn, hasStableRateOut] = useMemo(() => {
    return [
      assetIn?.reserveData?.stableBorrowRateEnabled ?? false,
      assetOut?.reserveData?.stableBorrowRateEnabled ?? false,
    ]
  }, [assetIn, assetOut])



  useEffect(() => {
    if (!hasStableRateIn) {
      setSourceBorrowInterestMode(AaveInterestMode.VARIABLE)
    }
    if (!hasStableRateOut) {
      setTargetBorrowInterestMode(AaveInterestMode.VARIABLE)
    }
  }, [hasStableRateIn, hasStableRateOut])

  return (
    <Trace page={PageName.SWAP_PAGE} shouldLogImpression>
      <>
        <PageWrapper redesignFlag={redesignFlagEnabled} navBarFlag={navBarFlagEnabled}>
          <SwapWrapper id="swap-page" redesignFlag={redesignFlagEnabled}>
            <SingleSideTradeHeader allowedSlippage={allowedSlippage} side={side} />
            <ConfirmSwapModal
              isOpen={showConfirm}
              trade={trade}
              originalTrade={tradeToConfirm}
              onAcceptChanges={handleAcceptChanges}
              attemptingTxn={attemptingTxn}
              txHash={txHash}
              recipient={recipient}
              allowedSlippage={allowedSlippage}
              onConfirm={handleSwap}
              swapErrorMessage={swapErrorMessage}
              onDismiss={handleConfirmDismiss}
              swapQuoteReceivedDate={swapQuoteReceivedDate}
              fiatValueInput={fiatValueInput}
              fiatValueOutput={fiatValueOutput}
            />

            <AutoColumn gap={'0px'}>

              <div style={{ display: 'relative' }}>
                <Container redesignFlag={redesignFlagEnabled}>
                  {side === PositionSides.Borrow ? <>
                    <MouseoverTooltip
                      text={"The asset configuration to borrow."}
                    >
                      <ThemedText.DeprecatedMediumHeader
                        color={theme.deprecated_text1}
                        textAlign='center'
                        marginLeft='50px'
                        height='30px'
                        marginTop='5px'
                      >
                        Origination
                      </ThemedText.DeprecatedMediumHeader>
                    </MouseoverTooltip>
                    <DebtOptionButton
                      handleSelectInterestMode={handleSelectInterestModeSource}
                      hasStableBorrow={hasStableRateIn}
                      borrowRateStable={borrowRateStableIn}
                      borrowRateVariable={borrowRateVariableIn}
                      selectedBorrowInterestMode={sourceBorrowInterestMode}
                    />
                  </> :
                    <>
                      <MouseoverTooltip
                        text={"The asset configuration to withdraw. Make sure that you aproved the respective interest bearing token."}
                      >
                        <ThemedText.DeprecatedMediumHeader
                          color={theme.deprecated_text1}
                          textAlign='center'
                          marginLeft='50px'
                          height='30px'
                          marginTop='5px'
                        >
                          Withdraw
                        </ThemedText.DeprecatedMediumHeader>
                      </MouseoverTooltip>
                      <DepositYield liquidityRate={supplyRateIn} />
                    </>
                  }
                  <InputWrapper redesignFlag={redesignFlagEnabled}>
                    <Trace section={SectionName.CURRENCY_INPUT_PANEL}>
                      <CurrencyInputCustomList
                        providedTokenList={restrictedTokenList}
                        label={
                          independentField === Field.OUTPUT && !showWrap ? (
                            <Trans>From (at most)</Trans>
                          ) : (
                            <Trans>From</Trans>
                          )
                        }
                        value={formattedAmounts[Field.INPUT]}
                        showMaxButton={showMaxButton && side === PositionSides.Collateral}
                        currency={currencyAmounts[Field.INPUT].currency}
                        onUserInput={handleTypeInput}
                        onMax={handleMaxInput}
                        fiatValue={fiatValueInput ?? undefined}
                        otherCurrency={null}
                        showCommonBases={true}
                        id={SectionName.CURRENCY_INPUT_PANEL}
                        loading={independentField === Field.OUTPUT && routeIsSyncing}
                        providedCurrencyBalance={currencyAmounts[Field.INPUT]}
                        balanceText={side === PositionSides.Borrow ? (
                          userDebtTypeIn === AaveInterestMode.STABLE ? 'Your stable debt' : userDebtTypeIn === AaveInterestMode.VARIABLE ? 'Your variable debt' : ' No debt of any kind'
                        ) : 'Your deposits'}
                      />
                    </Trace>
                  </InputWrapper>
                </Container>
                <ArrowWrapper clickable={isSupportedChain(chainId)} redesignFlag={redesignFlagEnabled}>
                  <TraceEvent
                    events={[Event.onClick]}
                    name={EventName.SWAP_TOKENS_REVERSED}
                    element={ElementName.SWAP_TOKENS_REVERSE_ARROW_BUTTON}
                  >
                    {redesignFlagEnabled ? (
                      <ArrowContainer
                        onClick={() => {
                          setApprovalSubmitted(false) // reset 2 step UI for approvals
                          handleSwitchAssets()
                        }}
                        color={theme.textPrimary}
                      >
                        <ArrowUpWrapper>
                          <ArrowUp size="12" stroke-width="3" />
                        </ArrowUpWrapper>
                        <ArrowDownWrapper>
                          <ArrowDown size="12" stroke-width="3" />
                        </ArrowDownWrapper>
                      </ArrowContainer>
                    ) : (
                      <ArrowWrapper clickable onClick={() => {
                        handleSwitchAssets()
                        setApprovalSubmitted(false)
                      }
                      } redesignFlag={redesignFlagEnabled}
                      >
                        <ArrowDown size="16" color={theme.deprecated_text2} />
                      </ArrowWrapper>
                    )}
                  </TraceEvent>
                </ArrowWrapper>

              </div>
              <div>

                <AutoColumn gap={redesignFlagEnabled ? '12px' : '8px'}>
                  <Container redesignFlag={redesignFlagEnabled}>
                    {side === PositionSides.Borrow ? <>
                      <MouseoverTooltip
                        text={"The asset configuration to supply."}
                      >
                        <ThemedText.DeprecatedMediumHeader
                          color={theme.deprecated_text1}
                          textAlign='center'
                          marginLeft='50px'
                          height='30px'
                          marginTop='5px'
                        >
                          Supply
                        </ThemedText.DeprecatedMediumHeader>
                      </MouseoverTooltip>
                      <DebtOptionButton
                        handleSelectInterestMode={handleSelectInterestModeTarget}
                        hasStableBorrow={hasStableRateOut}
                        borrowRateStable={borrowRateStableOut}
                        borrowRateVariable={borrowRateVariableOut}
                        selectedBorrowInterestMode={targetBorrowInterestMode}

                      />
                    </> :
                      <>
                        <MouseoverTooltip
                          text={"The asset configuration to repay."}
                        >
                          <ThemedText.DeprecatedMediumHeader
                            color={theme.deprecated_text1}
                            textAlign='center'
                            marginLeft='50px'
                            height='30px'
                            marginTop='5px'
                          >
                            Supply
                          </ThemedText.DeprecatedMediumHeader>
                        </MouseoverTooltip>
                        <DepositYield liquidityRate={supplyRateOut} />
                      </>
                    }
                    <InputWrapper redesignFlag={redesignFlagEnabled}>
                      <Trace section={SectionName.CURRENCY_OUTPUT_PANEL}>
                        <CurrencyInputCustomList
                          providedTokenList={restrictedTokenList}
                          value={formattedAmounts[Field.OUTPUT]}
                          onUserInput={handleTypeOutput}
                          label={
                            independentField === Field.INPUT && !showWrap ? (
                              <Trans>To (at least)</Trans>
                            ) : (
                              <Trans>To</Trans>
                            )
                          }
                          showMaxButton={side !== PositionSides.Collateral}
                          onMax={handleMaxOutput}
                          hideBalance={false}
                          fiatValue={fiatValueOutput ?? undefined}
                          priceImpact={stablecoinPriceImpact}
                          currency={currencyAmounts[Field.OUTPUT].currency} //{currencies[Field.OUTPUT] ?? null}
                          providedCurrencyBalance={currencyAmounts[Field.OUTPUT]}
                          balanceText={side === PositionSides.Borrow ? (
                            userDebtTypeOut === AaveInterestMode.STABLE ? 'Your stable debt' : userDebtTypeOut === AaveInterestMode.VARIABLE ? 'Your variable debt' : ' No debt of any kind'
                          ) : 'Your deposits'}
                          // onCurrencySelect={null}
                          otherCurrency={null}
                          showCommonBases={true}
                          id={SectionName.CURRENCY_OUTPUT_PANEL}
                          loading={independentField === Field.INPUT && routeIsSyncing}
                        />
                      </Trace>

                    </InputWrapper>
                  </Container>

                  {recipient !== null && !showWrap ? (
                    <>
                      <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                        <ArrowWrapper clickable={false} redesignFlag={redesignFlagEnabled}>
                          <ArrowDown size="16" color={theme.deprecated_text2} />
                        </ArrowWrapper>
                        <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                          <Trans>- Remove recipient</Trans>
                        </LinkStyledButton>
                      </AutoRow>
                      <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
                    </>
                  ) : null}
                  {!showWrap && userHasSpecifiedInputOutput && (trade || routeIsLoading || routeIsSyncing) && (
                    <SwapDetailsDropdown
                      trade={trade}
                      syncing={routeIsSyncing}
                      loading={routeIsLoading}
                      showInverted={showInverted}
                      setShowInverted={setShowInverted}
                      allowedSlippage={allowedSlippage}
                    />
                  )}
                  {showPriceImpactWarning && <PriceImpactWarning priceImpact={largerPriceImpact} />}
                  <div>
                    {swapIsUnsupported ? (
                      <ButtonPrimary disabled={true}>
                        <ThemedText.DeprecatedMain mb="4px">
                          <Trans>Unsupported Asset</Trans>
                        </ThemedText.DeprecatedMain>
                      </ButtonPrimary>
                    ) : !account ? (
                      <TraceEvent
                        events={[Event.onClick]}
                        name={EventName.CONNECT_WALLET_BUTTON_CLICKED}
                        properties={{ received_swap_quote: getIsValidSwapQuote(trade, tradeState, swapInputError) }}
                        element={ElementName.CONNECT_WALLET_BUTTON}
                      >
                        <ButtonLight onClick={toggleWalletModal} redesignFlag={redesignFlagEnabled}>
                          <Trans>Connect Wallet</Trans>
                        </ButtonLight>
                      </TraceEvent>
                    ) : showWrap ? (
                      <ButtonPrimary disabled={Boolean(wrapInputError)} onClick={onWrap}>
                        {wrapInputError ? (
                          <WrapErrorText wrapInputError={wrapInputError} />
                        ) : wrapType === WrapType.WRAP ? (
                          <Trans>Wrap</Trans>
                        ) : wrapType === WrapType.UNWRAP ? (
                          <Trans>Unwrap</Trans>
                        ) : null}
                      </ButtonPrimary>
                    ) : routeNotFound && userHasSpecifiedInputOutput && !routeIsLoading && !routeIsSyncing ? (
                      <GreyCard style={{ textAlign: 'center' }}>
                        <ThemedText.DeprecatedMain mb="4px">
                          <Trans>Insufficient liquidity for this trade.</Trans>
                        </ThemedText.DeprecatedMain>
                      </GreyCard>
                    ) : showApproveFlow ? (
                      <AutoRow style={{ flexWrap: 'nowrap', width: '100%' }}>
                        <AutoColumn style={{ width: '100%' }} gap="12px">
                          <ButtonConfirmed
                            onClick={handleApprove}
                            disabled={approveTokenButtonDisabled}
                            width="100%"
                            altDisabledStyle={approvalStateOfConcern === ApprovalState.PENDING} // show solid button while waiting
                            confirmed={
                              approvalStateOfConcern === ApprovalState.APPROVED || signatureState === UseERC20PermitState.SIGNED
                            }
                          >
                            <AutoRow justify="space-between" style={{ flexWrap: 'nowrap' }} height="20px">
                              <span style={{ display: 'flex', alignItems: 'center' }}>
                                {/* we need to shorten this string on mobile */}
                                {approvalStateOfConcern === ApprovalState.APPROVED ||
                                  signatureState === UseERC20PermitState.SIGNED ? (
                                  <Trans>
                                    {
                                      side === PositionSides.Collateral ?
                                        `You can now withdraw ${currencies[Field.INPUT]?.symbol}` :
                                        `You can now borrow ${currencies[Field.INPUT]?.symbol}`
                                    }
                                  </Trans>
                                ) : (
                                  <Trans>
                                    {
                                      side === PositionSides.Collateral ?
                                        `Allow our protocol to withdraw your ${currencies[Field.INPUT]?.symbol}` :
                                        `Allow our protocol to borrow ${currencies[Field.INPUT]?.symbol} on your behalf`
                                    }
                                  </Trans>
                                )}
                              </span>
                              {approvalPending || approvalStateOfConcern === ApprovalState.PENDING ? (
                                <Loader stroke={theme.white} />
                              ) : (approvalSubmitted && approvalStateOfConcern === ApprovalState.APPROVED) ||
                                signatureState === UseERC20PermitState.SIGNED ? (
                                <CheckCircle size="20" color={theme.deprecated_green1} />
                              ) : (
                                <MouseoverTooltip
                                  text={
                                    <Trans>
                                      You must give the 1Delta DAO smart contracts permission to use your{' '}
                                      {currencies[Field.INPUT]?.symbol}. You only have to do this once per token.
                                    </Trans>
                                  }
                                >
                                  <HelpCircle size="20" color={theme.deprecated_white} style={{ marginLeft: '8px' }} />
                                </MouseoverTooltip>
                              )}
                            </AutoRow>
                          </ButtonConfirmed>
                          <ButtonPrimary
                            onClick={() => {
                              if (isExpertMode) {
                                handleSwap()
                              } else {
                                setSwapState({
                                  tradeToConfirm: trade,
                                  attemptingTxn: false,
                                  swapErrorMessage: undefined,
                                  showConfirm: true,
                                  txHash: undefined,
                                })
                              }
                            }}
                            width="100%"
                            id="swap-button"
                            disabled={
                              // !isValid ||
                              routeIsSyncing ||
                              routeIsLoading ||
                              (approvalStateOfConcern !== ApprovalState.APPROVED &&
                                signatureState !== UseERC20PermitState.SIGNED) ||
                              priceImpactTooHigh
                            }
                          // error={isValid && priceImpactSeverity > 2}
                          >
                            <Text fontSize={16} fontWeight={500}>
                              {priceImpactTooHigh ? (
                                <Trans>High Price Impact</Trans>
                              ) : trade && priceImpactSeverity > 2 ? (
                                <Trans>Swap Anyway</Trans>
                              ) : (
                                <Trans>Swap</Trans>
                              )}
                            </Text>
                          </ButtonPrimary>
                        </AutoColumn>
                      </AutoRow>
                    ) : (
                      <ButtonPrimary
                        onClick={() => {
                          if (isExpertMode) {
                            handleSwap()
                          } else {
                            setSwapState({
                              tradeToConfirm: trade,
                              attemptingTxn: false,
                              swapErrorMessage: undefined,
                              showConfirm: true,
                              txHash: undefined,
                            })
                          }
                        }}
                        id="swap-button"
                        disabled={
                          // !isValid || 
                          routeIsSyncing || routeIsLoading || priceImpactTooHigh || !!swapCallbackError
                        }
                      // error={priceImpactSeverity > 2 && !swapCallbackError}
                      >
                        <Text fontSize={20} fontWeight={500}>
                          {
                            // swapInputError ? (
                            //   swapInputError
                            // ) :
                            routeIsSyncing || routeIsLoading ? (
                              <Trans>Swap</Trans>
                            ) : priceImpactSeverity > 2 ? (
                              <Trans>Swap Anyway</Trans>
                            ) : priceImpactTooHigh ? (
                              <Trans>Price Impact Too High</Trans>
                            ) : (
                              <Trans>Swap</Trans>
                            )}
                        </Text>
                      </ButtonPrimary>
                    )}
                    {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
                  </div>
                </AutoColumn>
              </div>
            </AutoColumn>
          </SwapWrapper>
          <NetworkAlert />
        </PageWrapper>
        <SwitchLocaleLink />
        {
          !swapIsUnsupported ? null : (
            <UnsupportedCurrencyFooter
              show={swapIsUnsupported}
              currencies={[currencies[Field.INPUT], currencies[Field.OUTPUT]]}
            />
          )
        }
      </>
    </Trace >
  )
}
