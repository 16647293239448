import { createReducer } from '@reduxjs/toolkit'
import { getAssets, TOKEN_META } from 'constants/1delta'
import { Asset, SerializedBigNumber, SupportedAssets } from 'types/1delta'

import { resetState } from './actions'
import { fetchAAVEAggregatorDataAsync, fetchAAVELiquidityDataAsync, fetchAAVEPublicDataAsync, fetchAAVEReserveConfigDataAsync, fetchAAVEReserveDataAsync } from './fetchAAVEPublicData'
import { fetchAAVEUserDataAsync, fetchAAVEUserReserveDataAsync } from './fetchAAVEUserData'

export interface DeltaState {
  loadingState: {
    userDataLoaded: boolean
    publicDataLoaded: boolean
    liquidityLoaded: boolean
    pricesLoaded: boolean
    configLoaded: boolean
    reservesLoaded: boolean
  }
  readonly userState: {
    totals: {
      totalCollateralBase: SerializedBigNumber
      totalDebtBase: SerializedBigNumber
      availableBorrowsBase: SerializedBigNumber
      currentLiquidationThreshold: SerializedBigNumber
      ltv: SerializedBigNumber
      healthFactor: SerializedBigNumber
      data: SerializedBigNumber
    }
    suppliedBalance: SerializedBigNumber
    borrowBalance: SerializedBigNumber
    borrowLimit: number
    healthFactor: number
  }
  readonly assets: { [key: string]: Asset }
}

const dummyAAVEAsset = {
  currentStableBorrowRate: '0',
  currentLiquidityRate: '0',
  currentVariableBorrowRate: '0',
  aTokenAddress: '',
  stableDebtTokenAddress: '',
  variableDebtTokenAddress: '',
  interestRateStrategyAddress: '',
  aData: { supply: '0' },
  sData: { scaledSupply: '0' },
  vData: {
    principal: '0',
    supply: '0',
    averageStableRate: '0',
    lastUpdated: 0,
  },
  userData: {
    // deprecated
    supplyBalance: '0',
    borrowBalanceStable: '0',
    borrowBalanceVariable: '0',
    // fetched from provider
    currentATokenBalance: '0',
    currentStableDebt: '0',
    currentVariableDebt: '0',
    principalStableDebt: '0',
    scaledVariableDebt: '0',
    stableBorrowRate: '0',
    liquidityRate: '0',
    stableRateLastUpdated: 0,
    usageAsCollateralEnabled: false
  },
  reserveData: {
    unbacked: '0',
    accruedToTreasuryScaled: '0',
    totalAToken: '0',
    totalStableDebt: '0',
    totalVariableDebt: '0',
    liquidityRate: '0',
    variableBorrowRate: '0',
    stableBorrowRate: '0',
    averageStableBorrowRate: '0',
    liquidityIndex: '0',
    variableBorrowIndex: '0',
    lastUpdateTimestamp: 0,
    // config
    decimals: '0',
    ltv: '0',
    liquidationThreshold: '0',
    liquidationBonus: '0',
    reserveFactor: '0',
    usageAsCollateralEnabled: false,
    borrowingEnabled: false,
    stableBorrowRateEnabled: false,
    isActive: false,
    isFrozen: false
  }
}

export const initialState: DeltaState = {
  loadingState: {
    reservesLoaded: false,
    userDataLoaded: false,
    publicDataLoaded: false,
    liquidityLoaded: false,
    pricesLoaded: false,
    configLoaded: false
  },
  userState: {
    totals: {
      totalCollateralBase: '0',
      totalDebtBase: '0',
      availableBorrowsBase: '0',
      currentLiquidationThreshold: '0',
      ltv: '0',
      healthFactor: '0',
      data: '0',
    },
    suppliedBalance: '0',
    borrowBalance: '0',
    borrowLimit: 0.0,
    healthFactor: 2.0,
  },
  assets: Object.assign({}, ...getAssets(5).map(x => {
    return {
      [x]: { id: x, ...dummyAAVEAsset, ...TOKEN_META[x] }
    }
  }))
}

export default createReducer<DeltaState>(initialState, (builder) =>
  builder
    .addCase(resetState, () => initialState)
    // public data fetch
    .addCase(fetchAAVEPublicDataAsync.pending, (state) => {
      // state.userDataLoading = true
    })
    .addCase(fetchAAVEPublicDataAsync.fulfilled, (state, action) => {
      const assetKeys = Object.keys(action.payload.data)
      for (let i = 0; i < assetKeys.length; i++) {
        state.assets[assetKeys[i]] = {
          ...state.assets[assetKeys[i]],
          ...action.payload.data[assetKeys[i]]
        }
      }
      state.loadingState.publicDataLoaded = true
    })
    // liquidity data fetch
    .addCase(fetchAAVELiquidityDataAsync.fulfilled, (state, action) => {
      const assetKeys = Object.keys(action.payload.data)
      for (let i = 0; i < assetKeys.length; i++) {
        state.assets[assetKeys[i]] = {
          ...state.assets[assetKeys[i]],
          ...action.payload.data[assetKeys[i]]
        }
      }
      state.loadingState.liquidityLoaded = true
    })
    .addCase(fetchAAVELiquidityDataAsync.pending, (state) => {
      //
    })
    // new reserve datafetch using aave data provider
    .addCase(fetchAAVEReserveDataAsync.fulfilled, (state, action) => {
      const assetKeys = Object.keys(action.payload.data)
      for (let i = 0; i < assetKeys.length; i++) {
        state.assets[assetKeys[i]].reserveData = {
          ...state.assets[assetKeys[i]].reserveData,
          ...action.payload.data[assetKeys[i]]
        }
      }
      state.loadingState.reservesLoaded = true
    })
    .addCase(fetchAAVEReserveDataAsync.pending, (state) => {
      //
    })
    // reserve config data
    .addCase(fetchAAVEReserveConfigDataAsync.fulfilled, (state, action) => {
      const assetKeys = Object.keys(action.payload.data)
      for (let i = 0; i < assetKeys.length; i++) {
        state.assets[assetKeys[i]].reserveData = {
          ...state.assets[assetKeys[i]].reserveData,
          ...action.payload.data[assetKeys[i]]
        }
      }
      state.loadingState.configLoaded = true
    })
    .addCase(fetchAAVEReserveConfigDataAsync.pending, (state) => {
      //
    })
    // prices from oracle
    .addCase(fetchAAVEAggregatorDataAsync.fulfilled, (state, action) => {
      const assetKeys = Object.keys(action.payload.data)
      for (let i = 0; i < assetKeys.length; i++) {
        state.assets[assetKeys[i]] = {
          ...state.assets[assetKeys[i]],
          ...action.payload.data[assetKeys[i]]
        }
      }
      state.loadingState.pricesLoaded = true
    })
    .addCase(fetchAAVEAggregatorDataAsync.pending, (state) => {
      //
    })
    // user data
    .addCase(fetchAAVEUserDataAsync.fulfilled, (state, action) => {
      const assetKeys = Object.keys(action.payload.data)
      for (let i = 0; i < assetKeys.length; i++) {
        state.assets[assetKeys[i]].userData = {
          ...state.assets[assetKeys[i]].userData,
          ...action.payload.data[assetKeys[i]]
        }
      }
      state.userState.totals = action.payload.totals

      state.loadingState.userDataLoaded = true
    })
    .addCase(fetchAAVEUserDataAsync.pending, (state) => {
      //
    })
    // user data from provider
    .addCase(fetchAAVEUserReserveDataAsync.fulfilled, (state, action) => {
      const assetKeys = Object.keys(action.payload.data)
      for (let i = 0; i < assetKeys.length; i++) {
        state.assets[assetKeys[i]].userData = {
          ...state.assets[assetKeys[i]].userData,
          ...action.payload.data[assetKeys[i]]
        }
      }

      state.loadingState.userDataLoaded = true
    })
    .addCase(fetchAAVEUserReserveDataAsync.pending, (state) => {
      //
    })
)
