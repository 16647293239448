import { Trans } from '@lingui/macro'
import { encodeMixedRouteToPath, RouteV3, Trade } from '@uniswap/router-sdk'
import { Currency, CurrencyAmount, Token, TradeType } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { sendAnalyticsEvent } from 'analytics'
import { ElementName, Event, EventName, PageName, SectionName } from 'analytics/constants'
import { Trace } from 'analytics/Trace'
import { TraceEvent } from 'analytics/TraceEvent'
import { sendEvent } from 'components/analytics'
import CurrencyInputCustomList from 'components/CurrencyInputPanel/CustomListInputPanel/CurrencyInputCustomList'
import GeneralCurrencyInputPanel from 'components/CurrencyInputPanel/GeneralInputPanel/GeneralCurrencyInputPanel'
import { NetworkAlert } from 'components/NetworkAlert/NetworkAlert'
import PriceImpactWarning from 'components/swap/PriceImpactWarning'
import SwapDetailsDropdown from 'components/swap/SwapDetailsDropdown'
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
import { MouseoverTooltip } from 'components/Tooltip'
import { isSupportedChain } from 'constants/chains'
import { ethers } from 'ethers'
import { NavBarVariant, useNavBarFlag } from 'featureFlags/flags/navBar'
import { RedesignVariant, useRedesignFlag } from 'featureFlags/flags/redesign'
import { filterSupportedAssets, getAaveTokens } from 'hooks/1delta/tokens'
import { getMoneyMarketOperatorContract } from 'hooks/1delta/use1DeltaContract'
import { useApproveCallbackFromGeneralTrade, useDelegateBorrowCallbackFromGeneralTrade } from 'hooks/use1DeltaMarginSwapCallback'
import { useSwapCallback } from 'hooks/useSwapCallback'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import JSBI from 'jsbi'
import { useCurrencyBalances } from 'lib/hooks/useCurrencyBalance'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ReactNode } from 'react'
import { ArrowDown, ArrowUp, CheckCircle, HelpCircle } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { Text } from 'rebass'
import { fetchAAVEUserReserveDataAsync } from 'state/1delta/fetchAAVEUserData'
import { useGetSingleAsset } from 'state/1delta/hooks'
import { useToggleWalletModal } from 'state/application/hooks'
import { useAppDispatch } from 'state/hooks'
import { TradeState } from 'state/routing/types'
import { useTheme } from 'styled-components/macro'
import { AaveInterestMode, LendingProtocolInteraction, PositionSides, SupportedAssets } from 'types/1delta'

import AddressInputPanel from '../../../components/AddressInputPanel'
import { BaseButton, ButtonConfirmed, ButtonLight, ButtonPrimary } from '../../../components/Button'
import { GreyCard } from '../../../components/Card'
import { AutoColumn } from '../../../components/Column'
import Loader from '../../../components/Loader'
import { AutoRow } from '../../../components/Row'
import confirmPriceImpactWithoutFee from '../../../components/swap/confirmPriceImpactWithoutFee'
import ConfirmSwapModal from '../../../components/swap/ConfirmSwapModal'
import { ArrowWrapper, PageWrapper, SwapCallbackError, SwapWrapper } from '../../../components/swap/styleds'
import { SwitchLocaleLink } from '../../../components/SwitchLocaleLink'
import { useCurrency } from '../../../hooks/Tokens'
import { ApprovalState } from '../../../hooks/useApproveCallback'
import useENSAddress from '../../../hooks/useENSAddress'
import { useERC20PermitFromTrade, UseERC20PermitState } from '../../../hooks/useERC20Permit'
import useIsArgentWallet from '../../../hooks/useIsArgentWallet'
import { useIsSwapUnsupported } from '../../../hooks/useIsSwapUnsupported'
import { useStablecoinValue } from '../../../hooks/useStablecoinPrice'
import useWrapCallback, { WrapErrorText, WrapType } from '../../../hooks/useWrapCallback'
import { Field } from '../../../state/swap/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfoClientSideV3ProvidedCcy,
  useSwapActionHandlers,
  useSwapState,
} from '../../../state/swap/hooks'
import { useExpertModeManager } from '../../../state/user/hooks'
import { LinkStyledButton, ThemedText } from '../../../theme'
import { computeFiatValuePriceImpact } from '../../../utils/computeFiatValuePriceImpact'
import { maxAmountSpend } from '../../../utils/maxAmountSpend'
import { computeRealizedPriceImpact, warningSeverity } from '../../../utils/prices'
import { ArrowContainer, ArrowDownWrapper, ArrowUpWrapper, InputWrapper } from '../components/wrappers'
import { formatSwapQuoteReceivedEventProperties, getIsValidSwapQuote, largerPercentValue } from '../utils/formatters'
import { createMoneyMarketCalldata } from '../utils/methodCreator'
import MoneyMarketTradeHeader from './MoneyMarketHeader'


const TRADE_STRING = 'SwapRouter'

export interface MoneyMarketSwapInterface {
  selectedAsset: Token
  side: PositionSides
}

export default function MoneyMarketSwap({ selectedAsset, side }: MoneyMarketSwapInterface) {
  const navigate = useNavigate()
  const navBarFlag = useNavBarFlag()
  const navBarFlagEnabled = navBarFlag === NavBarVariant.Enabled
  const redesignFlag = useRedesignFlag()
  const redesignFlagEnabled = redesignFlag === RedesignVariant.Enabled
  const { account, chainId, provider } = useWeb3React()
  const loadedUrlParams = useDefaultsFromURLSearch()
  const [newSwapQuoteNeedsLogging, setNewSwapQuoteNeedsLogging] = useState(true)
  const [fetchingSwapQuoteStartTime, setFetchingSwapQuoteStartTime] = useState<Date | undefined>()
  const [poolInteraction, setInteraction] = useState(
    side === PositionSides.Collateral ? LendingProtocolInteraction.Supply :
      LendingProtocolInteraction.Borrow
  )

  const handleSetInteraction = useCallback(() => {
    if (side === PositionSides.Collateral) {
      if (poolInteraction === LendingProtocolInteraction.Withdraw)
        return setInteraction(LendingProtocolInteraction.Supply)
      else
        return setInteraction(LendingProtocolInteraction.Withdraw)
    } else {
      if (poolInteraction === LendingProtocolInteraction.Borrow)
        return setInteraction(LendingProtocolInteraction.Repay)
      else
        return setInteraction(LendingProtocolInteraction.Borrow)
    }
  }, [poolInteraction])

  const restrictedTokenList = useMemo(() => {
    return getAaveTokens(chainId ?? 5)
  }, [chainId])

  const dispatch = useAppDispatch()

  const [borrowInterestMode, setBorrowInterestMode] = useState(AaveInterestMode.STABLE)

  const theme = useTheme()

  // toggle wallet when disconnected
  const toggleWalletModal = useToggleWalletModal()

  // for expert mode
  const [isExpertMode] = useExpertModeManager()

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()


  const { asset: assetToInteractWith } = useGetSingleAsset(chainId, selectedAsset)
  const [selectedCurrencyOutside, setCurrencyOutside] = useState("WETH")
  const selectedCurrency = useCurrency(selectedCurrencyOutside)


  const handleCcyInputSelect = useCallback(
    (inputCurrency: Currency) => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      setCurrencyOutside(((inputCurrency.isToken ? inputCurrency.address : inputCurrency.symbol) ?? assetToInteractWith?.tokenAddress) ?? 'ETH')
    },
    [setCurrencyOutside, selectedCurrencyOutside]
  )

  // user balacnes
  const currencyUserBalances = useCurrencyBalances(
    account ?? undefined,
    useMemo(() => [selectedCurrency ?? undefined, selectedAsset ?? undefined], [selectedAsset, selectedCurrency])
  )

  const [currencyAmounts, debtInfo]: [{ [field in Field]: CurrencyAmount<Currency> | undefined }, AaveInterestMode] = useMemo(
    () => {
      const stable0 = ethers.BigNumber.from(assetToInteractWith?.userData?.currentStableDebt ?? '0').gt(0)
      const variable0 = ethers.BigNumber.from(assetToInteractWith?.userData?.currentVariableDebt ?? '0')

      if (side === PositionSides.Collateral) {
        return [{
          [Field.INPUT]: currencyUserBalances?.[0],
          [Field.OUTPUT]: CurrencyAmount.fromRawAmount(selectedAsset, assetToInteractWith?.userData?.currentATokenBalance ?? '0'),
        }, AaveInterestMode.NONE]
      } else {
        return [{
          [Field.INPUT]: currencyUserBalances?.[0],
          [Field.OUTPUT]: CurrencyAmount.fromRawAmount(selectedAsset, (stable0 ? assetToInteractWith?.userData?.currentStableDebt : variable0.toString()) ?? '0'),
        }, stable0 ? AaveInterestMode.STABLE : variable0.gt(0) ? AaveInterestMode.VARIABLE : AaveInterestMode.NONE]
      }

    },
    [assetToInteractWith, selectedCurrency]
  )


  console.log("CCY SELECTED", selectedCurrency, selectedCurrencyOutside, currencyAmounts)

  const currencies: { [field in Field]?: Currency | null } = useMemo(
    () => ({
      [Field.INPUT]: currencyAmounts[Field.INPUT]?.currency,
      [Field.OUTPUT]: currencyAmounts[Field.OUTPUT]?.currency,
    }),
    [currencyAmounts]
  )

  const {
    trade: { state: tradeState, trade },
    allowedSlippage,
    parsedAmount,
    inputError: swapInputError,
  } = useDerivedSwapInfoClientSideV3ProvidedCcy(currencyAmounts)

  console.log("TRADE", trade)

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENSAddress(recipient)

  const parsedAmounts = useMemo(
    () =>
      showWrap
        ? {
          [Field.INPUT]: parsedAmount,
          [Field.OUTPUT]: parsedAmount,
        }
        : {
          [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
          [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
        },
    [independentField, parsedAmount, showWrap, trade]
  )

  const [routeNotFound, routeIsLoading, routeIsSyncing] = useMemo(
    () => [!trade?.swaps, TradeState.LOADING === tradeState, TradeState.SYNCING === tradeState],
    [trade, tradeState]
  )

  const fiatValueInput = useStablecoinValue(parsedAmounts[Field.INPUT])
  const fiatValueOutput = useStablecoinValue(parsedAmounts[Field.OUTPUT])
  const stablecoinPriceImpact = useMemo(
    () => (routeIsSyncing ? undefined : computeFiatValuePriceImpact(fiatValueInput, fiatValueOutput)),
    [fiatValueInput, fiatValueOutput, routeIsSyncing]
  )

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )


  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade<Currency, Currency, TradeType> | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  })

  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue,
      [dependentField]: showWrap
        ? parsedAmounts[independentField]?.toExact() ?? ''
        : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
    }),
    [dependentField, independentField, parsedAmounts, showWrap, typedValue]
  )

  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )

  const marginTraderContract = getMoneyMarketOperatorContract(chainId ?? 5, provider, account)

  // check whether the user has approved the router on the input token
  const [approvalState, approveCallback] = useApproveCallbackFromGeneralTrade(
    trade,
    poolInteraction,
    Field.OUTPUT,
    marginTraderContract.address,
    allowedSlippage
  )

  // check whether the user has approved the router on the input token
  const [borrowApprovalState, approveBorrowCallback] = useDelegateBorrowCallbackFromGeneralTrade(
    trade,
    poolInteraction,
    Field.OUTPUT,
    marginTraderContract.address,
    allowedSlippage,
    AaveInterestMode.VARIABLE
  )

  const [userDebt, userDebtType] = useMemo(() => {
    const stableDebt = ethers.BigNumber.from(assetToInteractWith?.userData?.currentStableDebt ?? '0')
    const variableDebt = ethers.BigNumber.from(assetToInteractWith?.userData?.currentVariableDebt ?? '0')
    if (stableDebt.gt(0)) return [stableDebt, AaveInterestMode.STABLE]
    if (variableDebt.gt(0)) return [variableDebt, AaveInterestMode.VARIABLE]

    return [undefined, AaveInterestMode.NONE]
  },
    [assetToInteractWith]
  )

  const handleSelectInterestMode = useCallback(() => {
    if (userDebtType !== AaveInterestMode.NONE) {
      setBorrowInterestMode(userDebtType)
      return null // no selections shall be possible
    }
    if (!assetToInteractWith?.reserveData?.stableBorrowRateEnabled) {
      setBorrowInterestMode(AaveInterestMode.VARIABLE)
      return null // no selections shall be possible
    } else {
      if (borrowInterestMode != AaveInterestMode.VARIABLE) return setBorrowInterestMode(AaveInterestMode.VARIABLE)
      return setBorrowInterestMode(AaveInterestMode.STABLE)
    }
  },
    [borrowInterestMode, poolInteraction, assetToInteractWith]
  )
  console.log("INTEREST", userDebt, userDebtType)
  const [approvalStateOfConcern, approveCallbackOfConcern] = useMemo(() => {
    if (poolInteraction != LendingProtocolInteraction.Borrow)
      return [approvalState, approveCallback]
    else return [borrowApprovalState, approveBorrowCallback]
  },
    [poolInteraction, approvalState, borrowApprovalState]
  )

  const transactionDeadline = useTransactionDeadline()
  const {
    state: signatureState,
    signatureData,
    gatherPermitSignature,
  } = useERC20PermitFromTrade(trade, allowedSlippage, transactionDeadline)

  const [approvalPending, setApprovalPending] = useState<boolean>(false)
  const handleApprove = useCallback(async () => {
    setApprovalPending(true)
    try {
      if (signatureState === UseERC20PermitState.NOT_SIGNED && gatherPermitSignature) {
        try {
          await gatherPermitSignature()
        } catch (error) {
          // try to approve if gatherPermitSignature failed for any reason other than the user rejecting it
          if (error?.code !== 4001) {
            await approveCallbackOfConcern()
          }
        }
      } else {
        await approveCallbackOfConcern()

        sendEvent({
          category: 'Swap',
          action: 'Approve',
          label: [TRADE_STRING, trade?.inputAmount?.currency.symbol].join('/'),
        })
      }
    } finally {
      setApprovalPending(false)
    }
  }, [signatureState, gatherPermitSignature, approveCallbackOfConcern, trade?.inputAmount?.currency.symbol])

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approvalStateOfConcern === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approvalStateOfConcern, approvalSubmitted])

  const maxInputAmount: CurrencyAmount<Currency> | undefined = useMemo(
    () => maxAmountSpend(currencyUserBalances[0]),
    [currencyUserBalances]
  )


  const maxOutputAmount: CurrencyAmount<Currency> | undefined = useMemo(
    () => maxAmountSpend(currencyAmounts[Field.OUTPUT]),
    [currencyAmounts]
  )

  const handleMaxOutput = useCallback(() => {
    maxOutputAmount && onUserInput(Field.OUTPUT, maxOutputAmount.toExact())
    sendEvent({
      category: 'Swap',
      action: 'Max',
    })
  }, [maxOutputAmount, onUserInput])


  const showMaxButton = Boolean(maxInputAmount?.greaterThan(0) && !parsedAmounts[Field.INPUT]?.equalTo(maxInputAmount))
  const showMaxButtonOut = Boolean(maxOutputAmount?.greaterThan(0) && !parsedAmounts[Field.OUTPUT]?.equalTo(maxOutputAmount))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    trade,
    allowedSlippage,
    recipient,
    signatureData
  )

  const handleSwap = useCallback(async () => {
    if (!swapCallback) {
      return
    }
    if (stablecoinPriceImpact && !confirmPriceImpactWithoutFee(stablecoinPriceImpact)) {
      return
    }
    const v3Route = (trade?.routes[0] as RouteV3<Currency, Currency>)
    const { args, method, estimate, call } = createMoneyMarketCalldata(
      parsedAmounts,
      marginTraderContract,
      side,
      poolInteraction,
      borrowInterestMode,
      userDebtType,
      account,
      v3Route,
      trade?.tradeType
    )
    // console.log("CALL ARGS", args, method, poolInteraction, call)
    setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
    if (call)
      await call().then((txResponse) => {
        setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: txResponse.hash })
        sendEvent({
          category: 'Swap',
          action: 'transaction hash',
          label: txResponse.hash,
        })
        sendEvent({
          category: 'Swap',
          action:
            recipient === null
              ? 'Swap w/o Send'
              : (recipientAddress ?? recipient) === account
                ? 'Swap w/o Send + recipient'
                : 'Swap w/ Send',
          label: [TRADE_STRING, trade?.inputAmount?.currency?.symbol, trade?.outputAmount?.currency?.symbol, 'MH'].join(
            '/'
          ),
        })
        dispatch(fetchAAVEUserReserveDataAsync({
          chainId: chainId ?? 5,
          account: account ?? '',
          assetsToQuery: filterSupportedAssets(trade?.inputAmount?.currency, trade?.outputAmount?.currency)
        })
        )
      })
        .catch((error) => {
          setSwapState({
            attemptingTxn: false,
            tradeToConfirm,
            showConfirm,
            swapErrorMessage: error.message,
            txHash: undefined,
          })
        })
  }, [
    swapCallback,
    stablecoinPriceImpact,
    tradeToConfirm,
    showConfirm,
    recipient,
    recipientAddress,
    account,
    trade?.inputAmount?.currency?.symbol,
    trade?.outputAmount?.currency?.symbol,
  ])


  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)
  const [swapQuoteReceivedDate, setSwapQuoteReceivedDate] = useState<Date | undefined>()

  // warnings on the greater of fiat value price impact and execution price impact
  const { priceImpactSeverity, largerPriceImpact } = useMemo(() => {
    const marketPriceImpact = trade?.priceImpact ? computeRealizedPriceImpact(trade) : undefined
    const largerPriceImpact = largerPercentValue(marketPriceImpact, stablecoinPriceImpact)
    return { priceImpactSeverity: warningSeverity(largerPriceImpact), largerPriceImpact }
  }, [stablecoinPriceImpact, trade])

  const isArgentWallet = useIsArgentWallet()

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !isArgentWallet &&
    !swapInputError &&
    (approvalStateOfConcern === ApprovalState.NOT_APPROVED ||
      approvalStateOfConcern === ApprovalState.PENDING ||
      (approvalSubmitted && approvalStateOfConcern === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    (inputCurrency: Currency) => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
    },
    [onCurrencySelection]
  )

  const handleMaxInput = useCallback(() => {
    maxInputAmount && onUserInput(Field.INPUT, maxInputAmount.toExact())
    sendEvent({
      category: 'Swap',
      action: 'Max',
    })
  }, [maxInputAmount, onUserInput])

  const handleOutputSelect = useCallback(
    (outputCurrency: Currency) => onCurrencySelection(Field.OUTPUT, outputCurrency),
    [onCurrencySelection]
  )


  useEffect(() => {
    if (selectedAsset)
      handleOutputSelect(selectedAsset)
  }, [])

  const swapIsUnsupported = useIsSwapUnsupported(currencies[Field.INPUT], currencies[Field.OUTPUT])

  const priceImpactTooHigh = priceImpactSeverity > 3 && !isExpertMode
  const showPriceImpactWarning = largerPriceImpact && priceImpactSeverity > 3
  console.log("SWAP ERROR", swapErrorMessage)
  // Handle time based logging events and event properties.
  useEffect(() => {
    const now = new Date()
    // If a trade exists, and we need to log the receipt of this new swap quote:
    if (newSwapQuoteNeedsLogging && !!trade) {
      // Set the current datetime as the time of receipt of latest swap quote.
      setSwapQuoteReceivedDate(now)
      // Log swap quote.
      sendAnalyticsEvent(
        EventName.SWAP_QUOTE_RECEIVED,
        formatSwapQuoteReceivedEventProperties(trade, fetchingSwapQuoteStartTime)
      )
      // Latest swap quote has just been logged, so we don't need to log the current trade anymore
      // unless user inputs change again and a new trade is in the process of being generated.
      setNewSwapQuoteNeedsLogging(false)
      // New quote is not being fetched, so set start time of quote fetch to undefined.
      setFetchingSwapQuoteStartTime(undefined)
    }
    // If another swap quote is being loaded based on changed user inputs:
    if (routeIsLoading) {
      setNewSwapQuoteNeedsLogging(true)
      if (!fetchingSwapQuoteStartTime) setFetchingSwapQuoteStartTime(now)
    }
  }, [
    newSwapQuoteNeedsLogging,
    routeIsSyncing,
    routeIsLoading,
    fetchingSwapQuoteStartTime,
    trade,
    setSwapQuoteReceivedDate,
  ])

  const approveTokenButtonDisabled =
    approvalStateOfConcern !== ApprovalState.NOT_APPROVED || approvalSubmitted || signatureState === UseERC20PermitState.SIGNED

  return (
    <Trace page={PageName.SWAP_PAGE} shouldLogImpression>
      <>
        <PageWrapper redesignFlag={redesignFlagEnabled} navBarFlag={navBarFlagEnabled}>
          <SwapWrapper id="swap-page" redesignFlag={redesignFlagEnabled}>
            {(poolInteraction === LendingProtocolInteraction.Borrow || poolInteraction === LendingProtocolInteraction.Repay) && (
              <BaseButton onClick={handleSelectInterestMode} >
                {userDebtType === AaveInterestMode.NONE ? (
                  borrowInterestMode === AaveInterestMode.STABLE ?
                    'Current: Stable Mode' : 'Current: Variable Mode') : (
                  userDebtType === AaveInterestMode.STABLE ? 'You only have Stable Debt' : 'You only have Variable Debt')
                }
              </BaseButton>
            )}
            <BaseButton onClick={handleSetInteraction} >
              {side === PositionSides.Collateral ?
                (poolInteraction === LendingProtocolInteraction.Supply ? 'Withdraw instead' : 'Supply instead')
                : (poolInteraction === LendingProtocolInteraction.Borrow ? 'Repay instead' : 'Borrow instead')
              }
            </BaseButton>
            <MoneyMarketTradeHeader allowedSlippage={allowedSlippage} side={side} interaction={poolInteraction} />
            <ConfirmSwapModal
              isOpen={showConfirm}
              trade={trade}
              originalTrade={tradeToConfirm}
              onAcceptChanges={handleAcceptChanges}
              attemptingTxn={attemptingTxn}
              txHash={txHash}
              recipient={recipient}
              allowedSlippage={allowedSlippage}
              onConfirm={handleSwap}
              swapErrorMessage={swapErrorMessage}
              onDismiss={handleConfirmDismiss}
              swapQuoteReceivedDate={swapQuoteReceivedDate}
              fiatValueInput={fiatValueInput}
              fiatValueOutput={fiatValueOutput}
            />

            <AutoColumn gap={'0px'}>
              <div style={{ display: 'relative' }}>
                <InputWrapper redesignFlag={redesignFlagEnabled}>
                  <Trace section={SectionName.CURRENCY_INPUT_PANEL}>
                    <GeneralCurrencyInputPanel
                      label={
                        independentField === Field.OUTPUT && !showWrap ? (
                          <Trans>From (at most)</Trans>
                        ) : (
                          <Trans>From</Trans>
                        )
                      }
                      value={formattedAmounts[Field.INPUT]}
                      showMaxButton={showMaxButton}
                      currency={(selectedCurrency ?? currencies[Field.INPUT]) ?? null}
                      onUserInput={handleTypeInput}
                      onMax={handleMaxInput}
                      fiatValue={fiatValueInput ?? undefined}
                      onCurrencySelect={handleCcyInputSelect}
                      otherCurrency={null}
                      showCommonBases={true}
                      id={SectionName.CURRENCY_INPUT_PANEL}
                      loading={independentField === Field.OUTPUT && routeIsSyncing}
                    />
                  </Trace>
                </InputWrapper>
                <ArrowWrapper clickable={isSupportedChain(chainId)} redesignFlag={redesignFlagEnabled}>
                  <TraceEvent
                    events={[Event.onClick]}
                    name={EventName.SWAP_TOKENS_REVERSED}
                    element={ElementName.SWAP_TOKENS_REVERSE_ARROW_BUTTON}
                  >
                    {redesignFlagEnabled ? (
                      <ArrowContainer
                        onClick={() => {
                          setApprovalSubmitted(false) // reset 2 step UI for approvals
                          onSwitchTokens()
                        }}
                        color={theme.textPrimary}
                      >
                        <ArrowUpWrapper>
                          <ArrowUp size="12" stroke-width="3" />
                        </ArrowUpWrapper>
                        <ArrowDownWrapper>
                          <ArrowDown size="12" stroke-width="3" />
                        </ArrowDownWrapper>
                      </ArrowContainer>
                    ) : (
                      <ArrowWrapper clickable onClick={() => {
                        handleSetInteraction()
                        setApprovalSubmitted(false)
                      }
                      } redesignFlag={redesignFlagEnabled}
                      >
                        {
                          (
                            poolInteraction === LendingProtocolInteraction.Withdraw
                            || poolInteraction === LendingProtocolInteraction.Borrow
                          ) ?
                            (<ArrowUp size="16" color={theme.deprecated_text2} />)
                            : (<ArrowDown size="16" color={theme.deprecated_text2} />)
                        }
                      </ArrowWrapper>
                    )}
                  </TraceEvent>
                </ArrowWrapper>
              </div>
              <div>
                <AutoColumn gap={redesignFlagEnabled ? '12px' : '8px'}>
                  <InputWrapper redesignFlag={redesignFlagEnabled}>
                    <Trace section={SectionName.CURRENCY_OUTPUT_PANEL}>
                      <CurrencyInputCustomList
                        providedTokenList={restrictedTokenList}
                        value={formattedAmounts[Field.OUTPUT]}
                        onUserInput={handleTypeOutput}
                        label={
                          independentField === Field.INPUT && !showWrap ? (
                            <Trans>To (at least)</Trans>
                          ) : (
                            <Trans>To</Trans>
                          )
                        }
                        showMaxButton={showMaxButtonOut}
                        hideBalance={false}
                        onMax={handleMaxOutput}
                        fiatValue={fiatValueOutput ?? undefined}
                        priceImpact={stablecoinPriceImpact}
                        currency={selectedAsset}
                        providedCurrencyBalance={
                          selectedAsset && ((poolInteraction === LendingProtocolInteraction.Borrow || poolInteraction === LendingProtocolInteraction.Repay) ? (
                            CurrencyAmount.fromRawAmount(
                              selectedAsset,
                              userDebt?.toString() ?? '0'
                            )
                          ) : CurrencyAmount.fromRawAmount(
                            selectedAsset,
                            assetToInteractWith?.userData?.currentATokenBalance ?? '0')
                          )
                        }
                        balanceText={side === PositionSides.Borrow ? (
                          userDebtType === AaveInterestMode.STABLE ? 'Your stable debt' : userDebtType === AaveInterestMode.VARIABLE ? 'Your variable debt' : ' No debt of any kind'
                        ) : 'Your deposits'}
                        // onCurrencySelect={null}
                        otherCurrency={null}
                        showCommonBases={true}
                        id={SectionName.CURRENCY_OUTPUT_PANEL}
                        loading={independentField === Field.INPUT && routeIsSyncing}
                      />
                    </Trace>

                    {recipient !== null && !showWrap ? (
                      <>
                        <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                          <ArrowWrapper clickable={false} redesignFlag={redesignFlagEnabled}>
                            <ArrowDown size="16" color={theme.deprecated_text2} />
                          </ArrowWrapper>
                          <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                            <Trans>- Remove recipient</Trans>
                          </LinkStyledButton>
                        </AutoRow>
                        <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
                      </>
                    ) : null}
                    {!showWrap && userHasSpecifiedInputOutput && (trade || routeIsLoading || routeIsSyncing) && (
                      <SwapDetailsDropdown
                        trade={trade}
                        syncing={routeIsSyncing}
                        loading={routeIsLoading}
                        showInverted={showInverted}
                        setShowInverted={setShowInverted}
                        allowedSlippage={allowedSlippage}
                      />
                    )}
                    {showPriceImpactWarning && <PriceImpactWarning priceImpact={largerPriceImpact} />}
                  </InputWrapper>
                  <div>
                    {swapIsUnsupported ? (
                      <ButtonPrimary disabled={true}>
                        <ThemedText.DeprecatedMain mb="4px">
                          <Trans>Unsupported Asset</Trans>
                        </ThemedText.DeprecatedMain>
                      </ButtonPrimary>
                    ) : !account ? (
                      <TraceEvent
                        events={[Event.onClick]}
                        name={EventName.CONNECT_WALLET_BUTTON_CLICKED}
                        properties={{ received_swap_quote: getIsValidSwapQuote(trade, tradeState, swapInputError) }}
                        element={ElementName.CONNECT_WALLET_BUTTON}
                      >
                        <ButtonLight onClick={toggleWalletModal} redesignFlag={redesignFlagEnabled}>
                          <Trans>Connect Wallet</Trans>
                        </ButtonLight>
                      </TraceEvent>
                    ) : showWrap ? (
                      <ButtonPrimary disabled={Boolean(wrapInputError)} onClick={onWrap}>
                        {wrapInputError ? (
                          <WrapErrorText wrapInputError={wrapInputError} />
                        ) : wrapType === WrapType.WRAP ? (
                          <Trans>Wrap</Trans>
                        ) : wrapType === WrapType.UNWRAP ? (
                          <Trans>Unwrap</Trans>
                        ) : null}
                      </ButtonPrimary>
                    ) : routeNotFound && userHasSpecifiedInputOutput && !routeIsLoading && !routeIsSyncing ? (
                      <GreyCard style={{ textAlign: 'center' }}>
                        <ThemedText.DeprecatedMain mb="4px">
                          <Trans>Insufficient liquidity for this trade.</Trans>
                        </ThemedText.DeprecatedMain>
                      </GreyCard>
                    ) : showApproveFlow ? (
                      <AutoRow style={{ flexWrap: 'nowrap', width: '100%' }}>
                        <AutoColumn style={{ width: '100%' }} gap="12px">
                          <ButtonConfirmed
                            onClick={handleApprove}
                            disabled={approveTokenButtonDisabled}
                            width="100%"
                            altDisabledStyle={approvalStateOfConcern === ApprovalState.PENDING} // show solid button while waiting
                            confirmed={
                              approvalStateOfConcern === ApprovalState.APPROVED || signatureState === UseERC20PermitState.SIGNED
                            }
                          >
                            <AutoRow justify="space-between" style={{ flexWrap: 'nowrap' }} height="20px">
                              <span style={{ display: 'flex', alignItems: 'center' }}>
                                {/* we need to shorten this string on mobile */}
                                {approvalStateOfConcern === ApprovalState.APPROVED ||
                                  signatureState === UseERC20PermitState.SIGNED ? (
                                  <Trans>
                                    {(poolInteraction === LendingProtocolInteraction.Supply || poolInteraction === LendingProtocolInteraction.Repay) ?
                                      `You can now trade ${currencies[Field.INPUT]?.symbol}` :
                                      poolInteraction === LendingProtocolInteraction.Withdraw ?
                                        `You can now withdraw ${currencies[Field.OUTPUT]?.symbol}` :
                                        poolInteraction === LendingProtocolInteraction.Borrow ?
                                          `You can now borrow ${currencies[Field.OUTPUT]?.symbol}` :
                                          'unsupported'
                                    }
                                  </Trans>
                                ) : (
                                  <Trans>
                                    {(poolInteraction === LendingProtocolInteraction.Supply || poolInteraction === LendingProtocolInteraction.Repay) ?
                                      `Allow our protocol to use your ${currencies[Field.INPUT]?.symbol}` :
                                      poolInteraction === LendingProtocolInteraction.Withdraw ?
                                        `Allow our protocol to withdraw your ${currencies[Field.OUTPUT]?.symbol}` :
                                        poolInteraction === LendingProtocolInteraction.Borrow ?
                                          `Allow our protocol to borrow ${currencies[Field.OUTPUT]?.symbol} on your behalf` :
                                          'unsupported'
                                    }
                                  </Trans>
                                )}
                              </span>
                              {approvalPending || approvalStateOfConcern === ApprovalState.PENDING ? (
                                <Loader stroke={theme.white} />
                              ) : (approvalSubmitted && approvalStateOfConcern === ApprovalState.APPROVED) ||
                                signatureState === UseERC20PermitState.SIGNED ? (
                                <CheckCircle size="20" color={theme.deprecated_green1} />
                              ) : (
                                <MouseoverTooltip
                                  text={
                                    <Trans>
                                      You must give the 1Delta DAO smart contracts permission to use your{' '}
                                      {currencies[Field.INPUT]?.symbol}. You only have to do this once per token.
                                    </Trans>
                                  }
                                >
                                  <HelpCircle size="20" color={theme.deprecated_white} style={{ marginLeft: '8px' }} />
                                </MouseoverTooltip>
                              )}
                            </AutoRow>
                          </ButtonConfirmed>
                          <ButtonPrimary
                            onClick={() => {
                              if (isExpertMode) {
                                handleSwap()
                              } else {
                                setSwapState({
                                  tradeToConfirm: trade,
                                  attemptingTxn: false,
                                  swapErrorMessage: undefined,
                                  showConfirm: true,
                                  txHash: undefined,
                                })
                              }
                            }}
                            width="100%"
                            id="swap-button"
                            disabled={
                              // !isValid ||
                              routeIsSyncing ||
                              routeIsLoading ||
                              (approvalStateOfConcern !== ApprovalState.APPROVED &&
                                signatureState !== UseERC20PermitState.SIGNED) ||
                              priceImpactTooHigh
                            }
                          // error={isValid && priceImpactSeverity > 2}
                          >
                            <Text fontSize={16} fontWeight={500}>
                              {priceImpactTooHigh ? (
                                <Trans>High Price Impact</Trans>
                              ) : trade && priceImpactSeverity > 2 ? (
                                <Trans>Swap Anyway</Trans>
                              ) : (
                                <Trans>Swap</Trans>
                              )}
                            </Text>
                          </ButtonPrimary>
                        </AutoColumn>
                      </AutoRow>
                    ) : (
                      <ButtonPrimary
                        onClick={() => {
                          if (isExpertMode) {
                            handleSwap()
                          } else {
                            setSwapState({
                              tradeToConfirm: trade,
                              attemptingTxn: false,
                              swapErrorMessage: undefined,
                              showConfirm: true,
                              txHash: undefined,
                            })
                          }
                        }}
                        id="swap-button"
                        disabled={
                          // !isValid || 
                          routeIsSyncing || routeIsLoading || priceImpactTooHigh || !!swapCallbackError
                        }
                      // error={priceImpactSeverity > 2 && !swapCallbackError}
                      >
                        <Text fontSize={20} fontWeight={500}>
                          {
                            // swapInputError ? (
                            //   swapInputError
                            // ) :
                            routeIsSyncing || routeIsLoading ? (
                              <Trans>Swap</Trans>
                            ) : priceImpactSeverity > 2 ? (
                              <Trans>Swap Anyway</Trans>
                            ) : priceImpactTooHigh ? (
                              <Trans>Price Impact Too High</Trans>
                            ) : (
                              <Trans>Swap</Trans>
                            )}
                        </Text>
                      </ButtonPrimary>
                    )}
                    {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
                  </div>
                </AutoColumn>
              </div>
            </AutoColumn>
          </SwapWrapper>
          <NetworkAlert />
        </PageWrapper>
        <SwitchLocaleLink />
        {!swapIsUnsupported ? null : (
          <UnsupportedCurrencyFooter
            show={swapIsUnsupported}
            currencies={[currencies[Field.INPUT], currencies[Field.OUTPUT]]}
          />
        )}
      </>
    </Trace>
  )
}
