import { BigNumber, ethers } from 'ethers'
import { useMemo, useState } from 'react'
import { Check } from 'react-feather'
import styled, { useTheme } from 'styled-components/macro'
import { Asset } from 'types/1delta'
import { BorrowBox, BorrowField, Cell, CheckboxWrapper, Circle, Row, ToggleContainer, YieldBox, YieldRow } from '../Styles'
import TokenDetail from '1delta/components/TokenDetail'
import { formatAaveYield, formatAbbrivatedPrice } from '../utils/format'
import { useWeb3React } from '@web3-react/core'

const ResponsiveCheck = styled(Check)``

export function ButtonRadioChecked({ active, onClick }: { active: boolean; onClick: () => void }) {
  const theme = useTheme()

  return (
    <CheckboxWrapper onClick={onClick}>
      <Circle active={active}>
        <ResponsiveCheck size={13} stroke={theme.deprecated_white} />
      </Circle>
    </CheckboxWrapper>
  )
}

interface MarketTableRowProps {
  isEditing?: boolean
  asset: Asset
  isCheckEnabled: boolean
  onCheckMarkToggle: () => void
}

export default function BorrowTableRow(props: MarketTableRowProps) {
  const dataLoaded = Boolean(props.asset.sData?.principal) && Boolean(props.asset.vData?.scaledSupply) && Boolean(props.asset.price)
  const { account } = useWeb3React()
  // this is the total supply, the coonversion to USD is at the bottom
  const [formattedTotalBorrow, formattedTotalBorrowUsd, price] = useMemo(() => {
    if (!dataLoaded) return [0, 0]
    const borrow = Number(
      ethers.utils.formatEther(
        ethers.BigNumber.from(props.asset.reserveData?.totalAToken ?? '0').sub(
          ethers.BigNumber.from(props.asset.reserveData?.totalStableDebt ?? '0')
            .add(props.asset.reserveData?.totalVariableDebt ?? '0'))
          .mul(BigNumber.from(10).pow(18 - (props.asset?.decimals ?? 0))) ?? '0'
      )
    )
    const price = ethers.utils.formatEther(ethers.BigNumber.from(props.asset?.price ?? '0').mul(ethers.BigNumber.from(10).pow(10)))
    return [borrow, borrow * Number(price), price]
  }, [props.asset.reserveData, dataLoaded])

  // this is the total supply, the coonversion to USD is at the bottom
  const [userBorrowVariable, userBorrowStable] = useMemo(() => {
    return account
      ? [Number(
        ethers.utils.formatEther(
          ethers.BigNumber.from(props.asset?.userData?.currentStableDebt)
            .mul(BigNumber.from(10).pow(18 - (props.asset.decimals ?? 0))) ?? '0'
        )
      ),
      Number(
        ethers.utils.formatEther(
          ethers.BigNumber.from(props.asset?.userData?.currentVariableDebt ?? '0')
            .mul(BigNumber.from(10).pow(18 - (props.asset.decimals ?? 0))) ?? '0'
        )
      )
      ]
      : [0, 0]
  }, [props.asset.userData, account])

  const reservesLoaded = Boolean(props.asset.reserveData)

  const [aprStable, aprVariable, hasStable] = useMemo(() => {
    if (!reservesLoaded) return [0, 0, false, false]
    let _aprStable = '0'
    const _hasStable = props.asset?.reserveData?.stableBorrowRateEnabled
    const _aprVariable = formatAaveYield(props.asset?.reserveData?.variableBorrowRate ?? '0')
    if (_hasStable)
      _aprStable = formatAaveYield(props.asset?.reserveData?.stableBorrowRate ?? '0')
    return [_aprStable, _aprVariable, _hasStable]
  }, [props.asset.reserveData])



  return (
    <Row>
      {props.isEditing && (
        <Cell>
          <div>
            <ButtonRadioChecked active={props.isCheckEnabled} onClick={props.onCheckMarkToggle} />
          </div>
        </Cell>
      )}
      <Cell>
        <TokenDetail asset={props.asset.id} price={price} />
      </Cell>
      <Cell>
        <YieldBox>
          <YieldRow>
            <span>Variable:</span>
            <span>{`${aprVariable}%`}</span>
          </YieldRow>
          {hasStable ? (<YieldRow>
            <span>Stable:</span>
            <span>{`${aprStable}%`}</span></YieldRow>) : (<YieldRow>-</YieldRow>)}
        </YieldBox>
      </Cell>
      {account &&
        <Cell>
          <BorrowBox>
            <BorrowField>
              {userBorrowVariable.toLocaleString()}
            </BorrowField>
            <BorrowField>
              {hasStable ? userBorrowStable.toLocaleString() : '-'}
            </BorrowField>
          </BorrowBox>
        </Cell>}
      <Cell>{dataLoaded ? formatAbbrivatedPrice(formattedTotalBorrowUsd) : '-'}</Cell>
    </Row>
  )
}
