import { getAssets } from "constants/1delta"
import { SupportedAssets } from "types/1delta"

export type AddressDictionary = { [prop: string]: { [chainId: number]: string } }
export type FlatAddressDictionary = { [prop: string]: string }

const mergeAddressDicts = (dict1: AddressDictionary, dict2: AddressDictionary): AddressDictionary => {
  const keys = [... new Set([...Object.keys(dict1), ...Object.keys(dict1)])]
  return Object.assign({}, ...keys.map(k => {
    return {
      [k]: { ...dict1?.[k], ...dict2?.[k] }
    }
  }))
}

export function getAddressesForChainIdFromAssetDict(dict: AddressDictionary, chainId: number): FlatAddressDictionary {
  const ob = Object.assign({}, ...getAssets(chainId).map(a => {
    return {
      [a]: dict?.[a]?.[chainId]
    }
  }))
  return ob
}

const aaveBase: AddressDictionary = {
  PoolAddressesProviderRegistry: { 5: '0xC87385b5E62099f92d490750Fcd6C901a524BBcA' },
  SupplyLogic: { 5: '0xF61Cffd6071a8DB7cD5E8DF1D3A5450D9903cF1c' },
  BorrowLogic: { 5: '0xde9Fa4A2d8435d45b767506D4A34791fa0371f79' },
  LiquidationLogic: { 5: '0x63E537A69b3f5B03F4f46c5765c82861BD874b6e' },
  EModeLogic: { 5: '0x0082ef98229887020962624Cbc66092Da5D82AaC' },
  BridgeLogic: { 5: '0x02444D214962eC73ab733bB00Ca98879efAAa73d' },
  ConfiguratorLogic: { 5: '0xE341D799E61d9caDBB6b05539f1d10aAdfA24d70' },
  FlashLoanLogic: { 5: '0xB7348Df015BB2e67449406FD1283DbAc99Ab716B' },
  PoolLogic: { 5: '0x18eE6714Bb1796b8172951D892Fb9f42a961C812' },
}

export const addressesAaveGeneralGoerli: AddressDictionary = {
  TreasuryController: { 5: '0x5665007321915c8f0E72d041315bA1AD15065337' },
  TreasuryImplementation: { 5: '0xA5375B08232a0f5e911c8a92B390662e098a579A' },
  WETHGateway: { 5: '0xd5B55D3Ed89FDa19124ceB5baB620328287b915d' },
  WalletBalanceProvider: { 5: '0x75CC0f0E3764be7594772D08EEBc322970CbB3a9' },
  ERC20FaucetAave: { 5: '0x1ca525Cd5Cb77DB5Fa9cBbA02A0824e283469DBe' },
  PoolAddressesProviderAave: { 5: '0xc4dCB5126a3AfEd129BC3668Ea19285A9f56D15D' },
  PoolDataProviderAave: { 5: '0x9BE876c6DC42215B00d7efe892E2691C3bc35d10' },
  TreasuryProxy: { 5: '0xFbAF383eB6c757faCb8cb19B68d5131aEbc5c11e' },
}

const addressesAaveAggregatorsGoerli: AddressDictionary = {
  [SupportedAssets.WETH]: { 5: '0x60E4B131f0F219c72b0346675283E73888e4AB24' },
  [SupportedAssets.DAI]: { 5: '0x2A5Acddb524B9454204Ed54EAB51Faf24250a397' },
  [SupportedAssets.LINK]: { 5: '0x5b48AE7B44e1b6000d5E9227Af362223AfA87b1A' },
  [SupportedAssets.USDC]: { 5: '0x30Ce0bA21A92E14b889F4f31748650EFA8D4C860' },
  [SupportedAssets.WBTC]: { 5: '0x2Cb17b22e3Aff6e291D3448C11f39779A576ae17' },
  [SupportedAssets.USDT]: { 5: '0x5838fD84a94B3Bc30EE4BDF10AD981Da3310a6a9' },
  [SupportedAssets.AAVE]: { 5: '0x87a3F24060BbbAD5dfCE055f24d253f84B11326d' },
  [SupportedAssets.EURS]: { 5: '0xf6dc74ec7851695AD549BbF88d371C0A62E9Be23' },
}

export const addressesAaveCoreGoerli: AddressDictionary = {
  PoolImplementation: { 5: '0x88c806c9d3aF1b055e65e68Dc336c0065B6dC807' },
  PoolConfiguratorImplementation: { 5: '0xC096019F8d41e474BB0d04D4194479B8c67d943a' },
  ReservesSetupHelper: { 5: '0x87A5b1cD19fC93dfeb177CCEc3686a48c53D65Ec' },
  ACLManagerAave: { 5: '0x4c952A81A72A6BA2919a658feff1e7F023e4aadc' },
  AaveOracleAave: { 5: '0x5bed0810073cc9f0DacF73C648202249E87eF6cB' },
  FallbackOracleAave: { 5: '0x0000000000000000000000000000000000000000' },
  PoolProxyAave: { 5: '0x368EedF3f56ad10b9bC57eed4Dac65B26Bb667f6', 80001: '0x6C9fB0D5bD9429eb9Cd96B85B81d872281771E6B' },
  PoolConfiguratorProxyAave: { 5: '0x723d17Ee6a668C011F01553D19B850E425075665' },
  IncentivesProxy: { 5: '0x0C501fB73808e1BD73cBDdd0c99237bbc481Bb58' },
  EmissionManager: { 5: '0xefF40A6d9De203A8806F4F62D86d5C4c5856965E' },
  IncentivesV2Implementation: { 5: '0x6904a3C18A37B219A59C9c8C6ABB7B1C35fdd7c4' },
  PullRewardsTransferStrategy: { 5: '0xe14fe1e916817111Db9c51ff505189Ffe7bc7Dd2' },
  ATokenAave: { 5: '0xF2EBFA003f04f38Fc606a37ab8D1c015c015725c' },
  DelegationAwareATokenAave: { 5: '0x6EF3DFaC236763AA74509B04C0feF0B2f3F5aD3A' },
  StableDebtTokenAave: { 5: '0x7D17eCD9fc4F64F180227216befb9d8E2c723135' },
  VariableDebtTokenAave: { 5: '0x1342dd8Ff58aee340e3C25268A4d08168cC5d990' },
  ReserveStrategyrateStrategyStableTwo: { 5: '0x8557b3992278299E620ebc111A61C1c542d45261' },
  ReserveStrategyrateStrategyVolatileOne: { 5: '0xFd2D746cB7d2194DaB321133E28A7072B0945386' },
  ReserveStrategyrateStrategyStableOne: { 5: '0x6120276aA5B7cF545Ed3Fb82FfE30c9fbB4D8267' },
  MockFlashLoanReceiver: { 5: '0x1931722c81F8A6b27d21a8Abfc167134D2F1a790' },
  UiIncentiveDataProvider: { 5: '0xACFd610B51ac6B70F030B277EA8A2A8D2143dC7A' },
  UiPoolDataProviderV3: { 5: '0xC576539371a2f425545B7BF4eb2a14Eee1944a1C' },
}

const addressesAaveTokensGoerli: AddressDictionary = {
  [SupportedAssets.WETH]: { 5: '0x2e3A2fb8473316A02b8A297B982498E661E1f6f5' },
  [SupportedAssets.DAI]: { 5: '0xDF1742fE5b0bFc12331D8EAec6b478DfDbD31464' },
  [SupportedAssets.LINK]: { 5: '0x07C725d58437504CA5f814AE406e70E21C5e8e9e' },
  [SupportedAssets.USDC]: { 5: '0xA2025B15a1757311bfD68cb14eaeFCc237AF5b43' },
  [SupportedAssets.WBTC]: { 5: '0x8869DFd060c682675c2A8aE5B21F2cF738A0E3CE' },
  [SupportedAssets.USDT]: { 5: '0xC2C527C0CACF457746Bd31B2a698Fe89de2b6d49' },
  [SupportedAssets.AAVE]: { 5: '0x63242B9Bd3C22f18706d5c4E627B4735973f1f07' },
  [SupportedAssets.EURS]: { 5: '0xaA63E0C86b531E2eDFE9F91F6436dF20C301963D' },
}

const addressesAaveStableDebtTokensGoerli: AddressDictionary = {
  [SupportedAssets.WETH]: { 5: '0xCAF956bD3B3113Db89C0584Ef3B562153faB87D5' },
  [SupportedAssets.DAI]: { 5: '0xbaBd1C3912713d598CA2E6DE3303fC59b19d0B0F' },
  [SupportedAssets.LINK]: { 5: '0x4f094AB301C8787F0d06753CA3238bfA9CFB9c91' },
  [SupportedAssets.USDC]: { 5: '0xF04958AeA8b7F24Db19772f84d7c2aC801D9Cf8b' },
  [SupportedAssets.WBTC]: { 5: '0x15FF4188463c69FD18Ea39F68A0C9B730E23dE81' },
  [SupportedAssets.USDT]: { 5: '0x7720C270Fa5d8234f0DFfd2523C64FdeB333Fa50' },
  [SupportedAssets.AAVE]: { 5: '0x4a8aF512B73Fd896C8877cE0Ebed19b0a11B593C' },
  [SupportedAssets.EURS]: { 5: '0x512ad2D2fb3Bef82ca0A15d4dE6544246e2D32c7' },
}

const addressesAaveATokensGoerli: AddressDictionary = {
  [SupportedAssets.WETH]: { 5: '0x27B4692C93959048833f40702b22FE3578E77759' },
  [SupportedAssets.DAI]: { 5: '0x310839bE20Fc6a8A89f33A59C7D5fC651365068f' },
  [SupportedAssets.LINK]: { 5: '0x6A639d29454287B3cBB632Aa9f93bfB89E3fd18f' },
  [SupportedAssets.USDC]: { 5: '0x1Ee669290939f8a8864497Af3BC83728715265FF' },
  [SupportedAssets.WBTC]: { 5: '0xc0ac343EA11A8D05AAC3c5186850A659dD40B81B' },
  [SupportedAssets.USDT]: { 5: '0x73258E6fb96ecAc8a979826d503B45803a382d68' },
  [SupportedAssets.AAVE]: { 5: '0xC4bf7684e627ee069e9873B70dD0a8a1241bf72c' },
  [SupportedAssets.EURS]: { 5: '0xc31E63CB07209DFD2c7Edb3FB385331be2a17209' },
}

const addressesAaveVariableDebtTokens: AddressDictionary = {
  [SupportedAssets.WETH]: { 5: '0x2b848bA14583fA79519Ee71E7038D0d1061cd0F1' },
  [SupportedAssets.DAI]: { 5: '0xEa5A7CB3BDF6b2A8541bd50aFF270453F1505A72' },
  [SupportedAssets.LINK]: { 5: '0x593D1bB0b6052FB6c3423C42FA62275b3D95a943' },
  [SupportedAssets.USDC]: { 5: '0x3e491EB1A98cD42F9BBa388076Fd7a74B3470CA0' },
  [SupportedAssets.WBTC]: { 5: '0x480B8b39d1465b8049fbf03b8E0a072Ab7C9A422' },
  [SupportedAssets.USDT]: { 5: '0x45c3965f6FAbf2fB04e3FE019853813B2B7cC3A3' },
  [SupportedAssets.AAVE]: { 5: '0xad958444c255a71C659f7c30e18AFafdE910EB5a' },
  [SupportedAssets.EURS]: { 5: '0x257b4a23b3026E04790c39fD3Edd7101E5F31192' },
}

const addressesAaveCorePolygon: AddressDictionary = {
  BorrowLogic: { 80001: '0x03c9CfA0Fb97500af154EBE01a81D04986795993' },
  BridgeLogic: { 80001: '0x49461dF60a4b4b275f00fcbb006c176Ae824F655' },
  ConfiguratorLogic: { 80001: '0x9F853170Bfc6383Cf7c03d06b02529850b869Baf' },
  EModeLogic: { 80001: '0x82D5a84F4c9c691345C81e7e5556CA6adcA63f0f' },
  ERC20Faucet: { 80001: '0xc1eB89DA925cc2Ae8B36818d26E12DDF8F8601b0' },
  FlashLoanLogic: { 80001: '0x00198f66D12256751Da68B492A9e2b460bF7173A' },
  LiquidationLogic: { 80001: '0x353850c99405CcC0747739FbfE624E2fAAb54595' },
  PoolImplementation: { 80001: '0x1758d4e6f68166C4B2d9d0F049F33dEB399Daa1F' },
  PoolAddressesProvider: { 80001: '0x5343b5bA672Ae99d627A1C87866b8E53F47Db2E6' },
  PoolAddressesProviderRegistry: { 80001: '0xE0987FC9EDfcdcA3CB9618510AaF1D565f4960A6' },
  PoolDataProvider: { 80001: '0x8f57153F18b7273f9A814b93b31Cb3f9b035e7C2' },
  PoolLogic: { 80001: '0x0940ceaacBF4860d2F7BFA657121B2F26a1676B0' },
  SupplyLogic: { 80001: '0xbd1ee6F441E6702E9Ea23438360FA13995DBA071' },
  TreasuryController: { 80001: '0x810d913542D399F3680F0E806DEDf6EACf0e3383' },
  TreasuryImplementation: { 80001: '0x3111Aa37Dd484154A6BA4091Dfd282d9AeAfc64C' },
  TreasuryProxy: { 80001: '0x3B6E7a4750e478D7f7d6A5d464099A02ef164bCC' },
  UiIncentiveDataProvider: { 80001: '0x26C3249723F2b98be57F49a1a31A9243a4B2cd88' },
  UiPoolDataProviderV3: { 80001: '0x74E3445f239f9915D57715Efb810f67b2a7E5758' },
  WETHGateway: { 80001: '0x2a58E9bbb5434FdA7FF78051a4B82cb0EF669C17' },
  WalletBalanceProvider: { 80001: '0x78baC31Ed73c115EB7067d1AfE75eC7B4e16Df9e' },
  PoolConfiguratorImplementation: { 80001: '0x823800Cc75b802457e9590Ec9AF183EA76e31778' },
  ReservesSetupHelper: { 80001: '0xa645C7F315B03BFc2E6000A4885B4EF20b045c3b' },
  ACLManager: { 80001: '0x6437b6E14D7ECa1Fa9854df92eB067253D5f683A' },
  AaveOracle: { 80001: '0x520D14AE678b41067f029Ad770E2870F85E76588' },
  FallbackOracle: { 80001: '0x0000000000000000000000000000000000000000' },
  PoolConfiguratorProxy: { 80001: '0x7b47e727eC539CB74A744ae5259ef26743294fca' },
  IncentivesProxy: { 80001: '0xFfaDa869df79320120dfFd6eeE8cF664Dba43146' },
  IncentivesV2Implementation: { 80001: '0xa982Aef90A37675C0E321e3e2f3aDC959fB89351' },
  PullRewardsTransferStrategy: { 80001: '0xA3a8697C4C6A7D9ccF9238cb567b122d53012ac9' },
  AToken: { 80001: '0x8017B7FC5473d05e67E617072fB237D24Add550b' },
  DelegationAwareAToken: { 80001: '0x06Db7973d6D724Eb15404a0d178624f6e07834F9' },
  StableDebtToken: { 80001: '0x651b8A8cA545b251a8f49B57D5838Da0a8DFbEF9' },
  VariableDebtToken: { 80001: '0x08a917bbd0E22D496Ca9364B5D21311fe1D31637' },
  ReserveStrategyrateStrategyStableTwo: { 80001: '0xF2038a65f68a94d1CFD0166f087A795341e2eac8' },
  ReserveStrategyrateStrategyVolatileOne: { 80001: '0x7d23F30DE42c67cc8016e8da8c713448364E02cF' },
  ReserveStrategyrateStrategyStableOne: { 80001: '0xca2413028D0c91f5F88821A13d4A82690945F678' },
}

const polygonAddressesUnderlyingTokens: AddressDictionary = {
  [SupportedAssets.AAVE]: { 80001: '0x0AB1917A0cf92cdcf7F7b637EaC3A46BBBE41409' },
  [SupportedAssets.AGEUR]: { 80001: '0xFCadBDefd30E11258559Ba239C8a5A8A8D28CB00' },
  [SupportedAssets.BAL]: { 80001: '0xE3981f4840843D67aF50026d34DA0f7e56A02D69' },
  [SupportedAssets.CRV]: { 80001: '0x3e4b51076d7e9B844B92F8c6377087f9cf8C8696' },
  [SupportedAssets.DAI]: { 80001: '0x9A753f0F7886C9fbF63cF59D0D4423C5eFaCE95B' },
  [SupportedAssets.DPI]: { 80001: '0x56e0507A53Ee252947a1E55D84Dc4032F914DD98' },
  [SupportedAssets.EURS]: { 80001: '0x302567472401C7c7B50ee7eb3418c375D8E3F728' },
  [SupportedAssets.GHST]: { 80001: '0x8AaF462990dD5CC574c94C8266208996426A47e7' },
  [SupportedAssets.JEUR]: { 80001: '0xBaaCc99123133851Ba2D6d34952aa08CBDf5A4E4' },
  [SupportedAssets.LINK]: { 80001: '0xD9E7e5dd6e122dDE11244e14A60f38AbA93097f2' },
  [SupportedAssets.SUSHI]: { 80001: '0xdDc3C9B8614092e6188A86450c8D597509893E20' },
  [SupportedAssets.USDC]: { 80001: '0x9aa7fEc87CA69695Dd1f879567CcF49F3ba417E2' },
  [SupportedAssets.USDT]: { 80001: '0x21C561e551638401b937b03fE5a0a0652B99B7DD' },
  [SupportedAssets.WBTC]: { 80001: '0x85E44420b6137bbc75a85CAB5c9A3371af976FdE' },
  [SupportedAssets.WETH]: { 80001: '0xd575d4047f8c667E064a4ad433D04E25187F40BB' },
  [SupportedAssets.WMATIC]: { 80001: '0xb685400156cF3CBE8725958DeAA61436727A30c3' },
}

const polygonAddressesVTokens: AddressDictionary = {
  [SupportedAssets.AAVE]: { 80001: '0xb571dcf478E2cC6c0871402fa3Dd4a3C8f6BE66E' },
  [SupportedAssets.AGEUR]: { 80001: '0x290F8118AAf61e129646F03791227434DFe39669' },
  [SupportedAssets.BAL]: { 80001: '0xB70013Bde95589330F87cE9a5bD06a89Bc26e38d' },
  [SupportedAssets.CRV]: { 80001: '0xB6704e124997030cE773BB35C1Cc154CF5cE06fB' },
  [SupportedAssets.DAI]: { 80001: '0xB18041Ce2439774c4c7BF611a2a635824cE99032' },
  [SupportedAssets.DPI]: { 80001: '0x6bB285977693F47AC6799F0B3B159130018f4c9c' },
  [SupportedAssets.EURS]: { 80001: '0x6Fb76894E171eEDF94BB33E650Af90DfdA2c37FC' },
  [SupportedAssets.GHST]: { 80001: '0x1170823EA41B03e2258f228f617cB549C1faDf28' },
  [SupportedAssets.JEUR]: { 80001: '0x97CD2BA205ff6FF09332892AB216B665793fc39E' },
  [SupportedAssets.LINK]: { 80001: '0x292f1Cc1BcedCd22E860c7C92D21877774B44C16' },
  [SupportedAssets.SUSHI]: { 80001: '0x95230060256d957F852db649B381045ace7983Cc' },
  [SupportedAssets.USDC]: { 80001: '0xA24A380813FB7E283Acb8221F5E1e3C01052Bc93' },
  [SupportedAssets.USDT]: { 80001: '0x444672831D8E4A2350667C14E007F56BEfFcB79f' },
  [SupportedAssets.WBTC]: { 80001: '0xFDf3B7af2Cb32E5ADca11cf54d53D02162e8d622' },
  [SupportedAssets.WETH]: { 80001: '0xb0c924f61B27cf3C114CBD70def08c62843ebb3F' },
  [SupportedAssets.WMATIC]: { 80001: '0x02a5680AE3b7383854Bf446b1B3Be170E67E689C' },
}

const polygonAddressesSTokens: AddressDictionary = {
  [SupportedAssets.AAVE]: { 80001: '0x26Df87542C50326A5085764b1F650EF2514776B6' },
  [SupportedAssets.AGEUR]: { 80001: '0x706E3AD3F2745722152acc71Da3C76330c2aa258' },
  [SupportedAssets.BAL]: { 80001: '0xf28E16644C6389b1B6cF03b3120726b1FfAeDC6E' },
  [SupportedAssets.CRV]: { 80001: '0x4a6F74A19f05529aF7E7e9f00923FFB990aeBE7B' },
  [SupportedAssets.DAI]: { 80001: '0x333C04243D048836d53b4ACB3c9aE64875699375' },
  [SupportedAssets.DPI]: { 80001: '0x2C64B0ef18bC0616291Dc636b1738DbC675C3f0d' },
  [SupportedAssets.EURS]: { 80001: '0xaB7cDf4C6053873650695352634987BbEe472c05' },
  [SupportedAssets.GHST]: { 80001: '0x03d6be9Bc91956A0bc39f515CaA77C8C0f81c3fC' },
  [SupportedAssets.JEUR]: { 80001: '0xdAc793dc4A6850765F0f55224CC77425e67C2b6e' },
  [SupportedAssets.LINK]: { 80001: '0x27908f7216Efe649706B68b6a443623D9aaF16D0' },
  [SupportedAssets.SUSHI]: { 80001: '0x169E542d769137E82E704477aDdfFe89e7FB9b90' },
  [SupportedAssets.USDC]: { 80001: '0x01dBEdcb2437c79341cfeC4Cae765C53BE0E6EF7' },
  [SupportedAssets.USDT]: { 80001: '0xc601b4d43aF91fE4EAe327a2d2B12f37a568E05B' },
  [SupportedAssets.WBTC]: { 80001: '0x5BcBF666e14eCFe6e21686601c5cA7c7fbe674Cf' },
  [SupportedAssets.WETH]: { 80001: '0xC9Ac53b6ae1C653A54ab0E9D44693E807429aF1F' },
  [SupportedAssets.WMATIC]: { 80001: '0xEC59F2FB4EF0C46278857Bf2eC5764485974D17B' },

}

const polygonAddressesATokens: AddressDictionary = {
  [SupportedAssets.AAVE]: { 80001: '0x50434C5Da807189622Db5fff66379808c58574aD' },
  [SupportedAssets.AGEUR]: { 80001: '0xbC456dc7E6F882DBc7b11da1048eD253F5DB021D' },
  [SupportedAssets.BAL]: { 80001: '0x6236bfBfB3b6CDBFC311399BE346d61Ab8ab1094' },
  [SupportedAssets.CRV]: { 80001: '0x4e752fB98b0dCC90b6772f23C52aD33b795dc758' },
  [SupportedAssets.DAI]: { 80001: '0xDD4f3Ee61466C4158D394d57f3D4C397E91fBc51' },
  [SupportedAssets.DPI]: { 80001: '0xf815E724973ff3f5Eedc243eAE1a34D1f2a45e0C' },
  [SupportedAssets.EURS]: { 80001: '0xf6AeDD279Aae7361e70030515f56c22A16d81433' },
  [SupportedAssets.GHST]: { 80001: '0x128cB3720f5d220e1E35512917c3c7fFf064A858' },
  [SupportedAssets.JEUR]: { 80001: '0x04cdAA74B111b49EF4044455324C0dDb1C2aa783' },
  [SupportedAssets.LINK]: { 80001: '0x3e1608F4Db4b37DDf86536ef441890fE3AA9F2Ea' },
  [SupportedAssets.SUSHI]: { 80001: '0xb7EA2d40B845A1B49E59c9a5f8B6F67b3c48fA04' },
  [SupportedAssets.USDC]: { 80001: '0xCdc2854e97798AfDC74BC420BD5060e022D14607' },
  [SupportedAssets.USDT]: { 80001: '0x6Ca4abE253bd510fCA862b5aBc51211C1E1E8925' },
  [SupportedAssets.WBTC]: { 80001: '0xde230bC95a03b695be69C44b9AA6C0e9dAc1B143' },
  [SupportedAssets.WETH]: { 80001: '0x685bF4eab23993E94b4CFb9383599c926B66cF57' },
  [SupportedAssets.WMATIC]: { 80001: '0x89a6AE840b3F8f489418933A220315eeA36d11fF' },
}


const polygonAggregators: AddressDictionary = {
  [SupportedAssets.AAVE]: { 80001: '0xD90db1ca5A6e9873BCD9B0279AE038272b656728' },
  [SupportedAssets.AGEUR]: { 80001: '0x028806D92D6fC46f301b38EF1cA6d3ceFE7f3E4B' },
  [SupportedAssets.BAL]: { 80001: '0x99B70f90b76716D9f909AD91de7e7F44d3445da4' },
  [SupportedAssets.CRV]: { 80001: '0xCcbBaf8D40a5C34bf1c836e8dD33c7B7646706C5' },
  [SupportedAssets.DAI]: { 80001: '0x1D01f7d8B42Ec47837966732f831E1D6321df499' },
  [SupportedAssets.DPI]: { 80001: '0x36556E9b01BCcCF0017C4998D972614f751Adf14' },
  [SupportedAssets.EURS]: { 80001: '0x8e0988b28f9CdDe0134A206dfF94111578498C63' },
  [SupportedAssets.GHST]: { 80001: '0x1775ECC8362dB6CaB0c7A9C0957cF656A5276c29' },
  [SupportedAssets.JEUR]: { 80001: '0x55E1267C2e587b6b5E94aD4f72E3eDA725D58b8D' },
  [SupportedAssets.LINK]: { 80001: '0xFc7215C9498Fc12b22Bc0ed335871Db4315f03d3' },
  [SupportedAssets.SUSHI]: { 80001: '0x127277bF2F5fA186bfC6b3a0ca00baefB5472d3a' },
  [SupportedAssets.USDC]: { 80001: '0x73b4C0C45bfB90FC44D9013FA213eF2C2d908D0A' },
  [SupportedAssets.USDT]: { 80001: '0x28A8E6e41F84e62284970E4bc0867cEe2AAd0DA4' },
  [SupportedAssets.WBTC]: { 80001: '0x3E937B4881CBd500d05EeDAB7BA203f2b7B3f74f' },
  [SupportedAssets.WETH]: { 80001: '0x09C85Ef96e93f0ae892561052B48AE9DB29F2458' },
  [SupportedAssets.WMATIC]: { 80001: '0x036dDd300B57F6a8A6A55e2ede8b50b517A5094f' },
}


export const addressesAaveTokens: AddressDictionary = mergeAddressDicts(addressesAaveTokensGoerli, polygonAddressesUnderlyingTokens)

export const addressesAaveATokens: AddressDictionary = mergeAddressDicts(polygonAddressesATokens, addressesAaveATokensGoerli)


export const addressesAaveSTokens: AddressDictionary = mergeAddressDicts(addressesAaveStableDebtTokensGoerli, polygonAddressesSTokens)


export const addressesAaveVTokens: AddressDictionary = mergeAddressDicts(polygonAddressesVTokens, addressesAaveVariableDebtTokens)


export const addressesAaveAggregators: AddressDictionary = mergeAddressDicts(addressesAaveAggregatorsGoerli, polygonAggregators)

export const addressesAaveCore: AddressDictionary = mergeAddressDicts(mergeAddressDicts(addressesAaveGeneralGoerli, aaveBase), mergeAddressDicts(addressesAaveCorePolygon, addressesAaveCoreGoerli))

export const multicallAddressGeneral: { [chainId: number]: string } = {
  5: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e'
}

export const multicallAddress: { [chainId: number]: string } = {
  5: '0xf10Bd0dA1f0e69c3334D7F8116C9082746EBC1B4'
}

export const uniswapMulticallAddress: { [chainId: number]: string } = {
  5: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696'
}