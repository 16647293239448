import { Currency } from '@uniswap/sdk-core'
import { AAVE_PRICE_PRECISION, BPS_BN, LTV_PRECISION, TEN, TOKEN_META, ZERO_BN } from 'constants/1delta'
import { ethers } from 'ethers'
import { formatEther } from 'ethers/lib/utils'
import { isSupportedAsset, safeGetToken } from 'hooks/1delta/tokens'
import { AppState } from 'state'
import { useAppSelector } from 'state/hooks'
import { Asset, SupportedAssets } from 'types/1delta'

export function useDeltaState(): AppState['delta'] {
  return useAppSelector((state) => state.delta)
}

export function useGetMoneyMarketAssetData(
  chainId?: number,
  currencyOutside?: Currency | null | undefined,
  currencyInside?: Currency | null | undefined
): { assetInside?: Asset, assetOutside?: Asset } {
  const assetData = useAppSelector((state) => state.delta.assets)
  let assetInside = undefined
  let assetOutside = undefined
  if (isSupportedAsset(chainId, currencyInside))
    assetInside = assetData[currencyInside?.symbol as SupportedAssets]

  if (isSupportedAsset(chainId, currencyOutside))
    assetOutside = assetData[currencyOutside?.symbol as SupportedAssets]
  return { assetInside, assetOutside }
}

export function useGetAssetData(
  chainId?: number,
  currency0?: Currency | null | undefined,
  currency1?: Currency | null | undefined
): { asset0?: Asset, asset1?: Asset } {
  const assetData = useAppSelector((state) => state.delta.assets)
  let asset0 = undefined
  let asset1 = undefined
  if (isSupportedAsset(chainId, currency0))
    asset0 = assetData[currency0?.symbol as SupportedAssets]

  if (isSupportedAsset(chainId, currency1))
    asset1 = assetData[currency1?.symbol as SupportedAssets]
  return { asset0, asset1 }
}


export function useGetSingleAsset(
  chainId?: number,
  currency?: Currency | null | undefined,
): { asset?: Asset } {
  const assetData = useAppSelector((state) => state.delta.assets)
  let asset = undefined
  if (isSupportedAsset(chainId, currency))
    asset = assetData[currency?.symbol as SupportedAssets]
  return { asset }
}

export function useSortAssets(
  assetInKey: SupportedAssets,
  assetOutKey: SupportedAssets
): { assetIn?: Asset, assetOut?: Asset } {
  const assetData = useAppSelector((state) => state.delta.assets)
  return { assetIn: assetData[assetInKey], assetOut: assetData[assetOutKey] }
}

export function useDeltaAssetState(): {
  [key: string]: Asset;
} {
  return useAppSelector((state) => state.delta.assets)
}

interface AaveLtvAssetParams {
  price: ethers.BigNumber
  collateral: ethers.BigNumber
  debt: ethers.BigNumber
  liquidiationThreshold: ethers.BigNumber
}

export interface AaveLtvParams {
  assetData: { [key: string]: AaveLtvAssetParams }
  currentLtv: ethers.BigNumber
  collateral: ethers.BigNumber
  debt: ethers.BigNumber
  healthFactor: ethers.BigNumber
}


// see https://docs.aave.com/risk/asset-risk/risk-parameters
export function useGetAaveRiskParameters(account?: string): AaveLtvParams | undefined {
  const assetData = useAppSelector((state) => state.delta.assets)

  // return nothing if not connected
  if (!account) return undefined

  let totalCollateral = ZERO_BN
  let totalDebt = ZERO_BN

  const aaveData: { [key: string]: AaveLtvAssetParams } = {}
  const keys = Object.keys(assetData).map(a => a as SupportedAssets)

  // we iterate through all assets and calculate collateral, debt and record proces and liquidation thresholds
  for (let i = 0; i < keys.length; i++) {
    const name = keys[i] as SupportedAssets
    const price = ethers.BigNumber.from(assetData[name]?.price ?? '0')

    // we we normalize everything to 18 decimals
    const multiplier = TEN.pow(18 - TOKEN_META[name].decimals)

    // the price has a provided precision
    const amountCollateral = price.mul(
      ethers.BigNumber.from(assetData[name]?.userData?.currentATokenBalance ?? '0')
    ).mul(multiplier).div(AAVE_PRICE_PRECISION)

    // threshold is a basis point value 100% = 10k
    const liquidiationThreshold = assetData[name]?.reserveData?.liquidationThreshold ?? '10000'

    // add adjusted collateral
    totalCollateral = totalCollateral.add(amountCollateral.mul(liquidiationThreshold).div(BPS_BN))

    // records debt for this specific asset
    let amountDebt = ZERO_BN
    const amountDebtStable = ethers.BigNumber.from(assetData[name]?.userData?.currentStableDebt ?? '0')
    const amountDebtVariable = ethers.BigNumber.from(assetData[name]?.userData?.currentVariableDebt ?? '0')

    // add debts if existing (for aave, at max one can be nonzero)
    if (amountDebtStable.gt(0)) amountDebt = amountDebt.add(amountDebtStable.mul(price).mul(multiplier).div(AAVE_PRICE_PRECISION))
    if (amountDebtVariable.gt(0)) amountDebt = amountDebt.add(amountDebtVariable.mul(price).mul(multiplier).div(AAVE_PRICE_PRECISION))

    // add the asset-specific debt in usd to the total debt 
    totalDebt = totalDebt.add(amountDebt)

    aaveData[name] = {
      price,
      liquidiationThreshold: ethers.BigNumber.from(liquidiationThreshold),
      collateral: amountCollateral,
      debt: amountDebt
    }
  }

  return {
    assetData: aaveData,
    collateral: totalCollateral,
    debt: totalDebt,
    currentLtv: totalCollateral.gt(0) ? totalDebt.mul(LTV_PRECISION).div(totalCollateral) : ZERO_BN,
    healthFactor: totalDebt.gt(0) ? totalCollateral.mul(LTV_PRECISION).div(totalDebt) : ZERO_BN
  }
}

export interface TradeImpact {
  ltv: ethers.BigNumber
  ltvNew: ethers.BigNumber
  ltvDelta: ethers.BigNumber
  healthFactor: ethers.BigNumber
  healthFactorNew: ethers.BigNumber
  healthFactorDelta: ethers.BigNumber
}
interface AssetCollateralChange {
  asset?: SupportedAssets
  deltaCollateral: ethers.BigNumber
}

interface AssetBorrowChange {
  asset?: SupportedAssets
  deltaBorrow: ethers.BigNumber
}


export function calculateRiskChangeMarginTrade(assetCollateral: AssetCollateralChange, assetBorrow: AssetBorrowChange, aaveParams?: AaveLtvParams): TradeImpact {

  if (!assetCollateral?.asset || !assetBorrow?.asset || !aaveParams?.assetData[assetCollateral.asset] || !aaveParams?.assetData[assetBorrow.asset]) return {
    ltv: ZERO_BN,
    ltvNew: ZERO_BN,
    ltvDelta: ZERO_BN,
    healthFactor: ZERO_BN,
    healthFactorNew: ZERO_BN,
    healthFactorDelta: ZERO_BN,
  }

  // we we normalize everything to 18 decimals
  const multiplierCollateral = TEN.pow(18 - TOKEN_META[assetCollateral.asset].decimals)
  const multiplierBorrow = TEN.pow(18 - TOKEN_META[assetBorrow.asset].decimals)

  // fetch prices - we measure everything on state price levels
  const priceCollateral = aaveParams.assetData[assetCollateral.asset].price
  const priceBorrow = aaveParams.assetData[assetBorrow.asset].price

  // borrow is the usd value
  const effectiveDeltaBorrow = assetBorrow.deltaBorrow.mul(multiplierBorrow).mul(priceBorrow).div(AAVE_PRICE_PRECISION)

  // the collateral will be downscaled
  const collateralFactor = aaveParams.assetData[assetCollateral.asset].liquidiationThreshold
  const effectiveDeltaCollateral = assetCollateral.deltaCollateral.mul(multiplierCollateral).mul(priceCollateral).mul(collateralFactor).div(BPS_BN).div(AAVE_PRICE_PRECISION)

  // new values are created by adding the deltas
  const newCollateral = aaveParams.collateral.add(effectiveDeltaCollateral)
  const newBorrow = aaveParams.debt.add(effectiveDeltaBorrow)

  // the new ltv is the quotient
  const ltvNew = newCollateral.gt(0) ? newBorrow.mul(LTV_PRECISION).div(newCollateral) : ZERO_BN
  const healthFactorNew = newBorrow.gt(0) ? newCollateral.mul(LTV_PRECISION).div(newBorrow) : ZERO_BN
  return {
    ltv: aaveParams.currentLtv,
    ltvNew,
    ltvDelta: ltvNew.sub(aaveParams.currentLtv),
    healthFactor: aaveParams.healthFactor,
    healthFactorNew,
    healthFactorDelta: healthFactorNew.sub(aaveParams.healthFactor)
  }
}