import { RouteV3 } from "@uniswap/router-sdk"
import { Currency, CurrencyAmount, MaxUint256, TradeType } from "@uniswap/sdk-core"
import { ADDRESS_ZERO } from "@uniswap/v3-sdk"
import { reverse } from "dns"
import { Contract } from "ethers"
import { Field } from "state/swap/actions"
import { AaveInterestMode, encodePath, LendingProtocolInteraction, PositionSides } from "types/1delta"

export interface SimpleTransactioResponse {
  hash: string
}

type ContractCall = () => Promise<SimpleTransactioResponse>;

export interface ContractCallData {
  args: any
  method: any
  estimate: any
  call?: ContractCall | undefined
}

/*
 * @notice returns money market method and argument
 * @param parsedAmounts 
 * @param v3Route 
 * @param marginTraderContract 
 * @param tradeType 
 * @param side 
 * @param interaction 
 * @returns 
 */
export const createMoneyMarketCalldata = (
  parsedAmounts: {
    INPUT?: CurrencyAmount<Currency> | undefined;
    OUTPUT?: CurrencyAmount<Currency> | undefined;
  },
  marginTraderContract: Contract,
  side: PositionSides,
  interaction: LendingProtocolInteraction,
  interestMode: AaveInterestMode,
  userInterestMode: AaveInterestMode,
  account?: string,
  v3Route?: RouteV3<Currency, Currency>,
  tradeType?: TradeType
): ContractCallData => {

  let args: any = {}
  let method: any
  let estimate: any
  let contractCall: ContractCall | undefined = undefined
  console.log("ATOKEN THIS METHOD 1", side, interaction, tradeType)
  if (!v3Route || (tradeType == undefined) || !account) return {
    args: {}, method: undefined, estimate: undefined
  }

  if (side === PositionSides.Collateral) {
    if (interaction == LendingProtocolInteraction.Supply) {
      if (tradeType === TradeType.EXACT_INPUT) {
        args = v3Route && {
          path: encodePath(v3Route.path.map(p => p.address), v3Route.pools.map(pool => pool.fee)),
          amountIn: parsedAmounts?.[Field.INPUT] && parsedAmounts?.[Field.INPUT]?.numerator.toString()
        }
        method = marginTraderContract.swapAndSupplyExactIn
        estimate = marginTraderContract.estimateGas.swapAndSupplyExactIn
        contractCall = async () => await marginTraderContract.swapAndSupplyExactIn(args)
      } else {
        const amountInMaximum = MaxUint256.toString()
        const structInp = v3Route && {
          path: encodePath(v3Route.path.map(p => p.address).reverse(), v3Route.pools.map(pool => pool.fee).reverse()),
          amountOut: parsedAmounts?.[Field.INPUT] && parsedAmounts?.[Field.INPUT]?.numerator.toString(),
          amountInMaximum,
          userAmountProvided: '0',
          sqrtPriceLimitX96: '0',
          interestRateMode: 0
        }
        args = v3Route && [
          amountInMaximum, //  amountInMaximum, redundant
          structInp
        ]
        method = marginTraderContract.swapAndSupplyExactOut
        estimate = marginTraderContract.estimateGas.swapAndSupplyExactOut
        contractCall = async () => await marginTraderContract.swapAndSupplyExactOut(amountInMaximum, structInp)
      }
    } else { // withdraw and then swap
      if (tradeType === TradeType.EXACT_OUTPUT) {
        const structInp = v3Route && {
          path: encodePath(v3Route.path.map(p => p.address).reverse(), v3Route.pools.map(pool => pool.fee).reverse()),
          amountIn: parsedAmounts?.[Field.OUTPUT] && parsedAmounts?.[Field.OUTPUT]?.numerator.toString(),
          recipient: account
        }
        args = [structInp]
        method = marginTraderContract.withdrawAndSwapExactIn
        estimate = marginTraderContract.estimateGas.withdrawAndSwapExactIn
        contractCall = async () => await marginTraderContract.withdrawAndSwapExactIn(structInp)
      } else {
        const structInp = v3Route && {
          path: encodePath(v3Route.path.map(p => p.address), v3Route.pools.map(pool => pool.fee)),
          amountOut: parsedAmounts?.[Field.INPUT] && parsedAmounts?.[Field.INPUT]?.numerator.toString(),
          sqrtPriceLimitX96: '0',
          interestRateMode: 0,
          userAmountProvided: '0'
        }
        args = [
          structInp
        ]
        method = marginTraderContract.withdrawAndSwapExactOut
        estimate = marginTraderContract.estimateGas.withdrawAndSwapExactOut
        contractCall = async () => await marginTraderContract.withdrawAndSwapExactOut(structInp)
      }
    }
  }
  // borrow related methods
  else {
    if (interaction == LendingProtocolInteraction.Borrow) {
      // this means that the lower value is provided, hence exact out
      if (tradeType === TradeType.EXACT_OUTPUT) {
        // note that the interest mode is already pre-defined if the user already has debt
        const interestRateMode = userInterestMode === AaveInterestMode.NONE ? interestMode : userInterestMode
        const structInp = v3Route && {
          path: encodePath(v3Route.path.map(p => p.address).reverse(), v3Route.pools.map(pool => pool.fee).reverse()),
          amountIn: parsedAmounts?.[Field.OUTPUT] && parsedAmounts?.[Field.OUTPUT]?.numerator.toString(),
          recipient: account
        }
        args = [structInp]
        method = marginTraderContract.borrowAndSwapExactIn
        estimate = marginTraderContract.estimateGas.borrowAndSwapExactIn
        contractCall = async () => await marginTraderContract.borrowAndSwapExactIn(interestRateMode, structInp)
      }
      else {
        // users only have one type, the interest mode should not matter here
        const interestRateMode = userInterestMode === AaveInterestMode.NONE ? interestMode : userInterestMode
        const structInp = v3Route && {
          path: encodePath(v3Route.path.map(p => p.address), v3Route.pools.map(pool => pool.fee)),
          amountOut: parsedAmounts?.[Field.INPUT] && parsedAmounts?.[Field.INPUT]?.numerator.toString(),
          interestRateMode,
          userAmountProvided: 0,
          sqrtPriceLimitX96: '0'
        }
        args = [structInp]
        method = marginTraderContract.borrowAndSwapExactOut
        estimate = marginTraderContract.estimateGas.borrowAndSwapExactOut
        contractCall = async () => await marginTraderContract.borrowAndSwapExactOut(structInp)
      }
    } else { // repay
      if (tradeType === TradeType.EXACT_INPUT) {
        const interestRateMode = userInterestMode
        const structInp = v3Route && {
          path: encodePath(v3Route.path.map(p => p.address), v3Route.pools.map(pool => pool.fee)),
          amountIn: parsedAmounts?.[Field.INPUT] && parsedAmounts?.[Field.INPUT]?.numerator.toString()
        }
        args = [interestRateMode, structInp]
        method = marginTraderContract.swapAndRepayExactIn
        estimate = marginTraderContract.estimateGas.swapAndRepayExactIn
        contractCall = async () => await marginTraderContract.swapAndRepayExactIn(interestRateMode, structInp)
      }
      else {
        const interestRateMode = userInterestMode
        const structInp = v3Route && {
          path: encodePath(v3Route.path.map(p => p.address).reverse(), v3Route.pools.map(pool => pool.fee).reverse()),
          amountOut: parsedAmounts?.[Field.OUTPUT] && parsedAmounts?.[Field.OUTPUT]?.numerator.toString(),
          interestRateMode,
          userAmountProvided: 0,
          sqrtPriceLimitX96: '0',
          recipient: account
        }
        args = [structInp]
        method = marginTraderContract.swapAndRepayExactOut
        estimate = marginTraderContract.estimateGas.swapAndRepayExactOut
        contractCall = async () => await marginTraderContract.swapAndRepayExactOut(structInp)
      }
    }

  }

  return { args, method, estimate, call: contractCall }
}