import { Trans } from '@lingui/macro'
import { Percent } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import Card from 'components/Card'
import { AutoColumn } from 'components/Column'
import { LoadingRows } from 'components/Loader/styled'
import { RowBetween, RowFixed } from 'components/Row'
import { Separator } from 'components/SearchModal/styleds'
import { MouseoverTooltip } from 'components/Tooltip'
import { RedesignVariant, useRedesignFlag } from 'featureFlags/flags/redesign'
import useNativeCurrency from 'lib/hooks/useNativeCurrency'
import { useMemo } from 'react'
import { Activity, ChevronsRight, CreditCard } from 'react-feather'
import { TradeImpact } from 'state/1delta/hooks'
import styled, { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { MarginTradeType } from 'types/1delta'
import { formatNumber, formatPercent, formatPercentagePoints, ltvDataToNumber } from '../utils/formatters'

const StyledCard = styled(Card)`
  padding: 0;
  width: 50%;
`

const IconContainer = styled.div`
  width: 30px;
  margin-right: 10px;
`

const ArrowContainer = styled.div`
  width: 30px;
`


export const RiskRow = styled.div`
  flex-direction: row;
  display: flex;
  width: 300px;
  justify-content: space-between;
`


interface AdvancedRiskDetailsProps {
  tradeImpact?: TradeImpact
  marginTradeType: MarginTradeType
  allowedSlippage: Percent
  noTrade?: boolean
  hideInfoTooltips?: boolean
}

function TextWithLoadingPlaceholder({
  syncing,
  width,
  children,
}: {
  syncing: boolean
  width: number
  children: JSX.Element
}) {
  return syncing ? (
    <LoadingRows>
      <div style={{ height: '15px', width: `${width}px` }} />
    </LoadingRows>
  ) : (
    <div style={{ alignSelf: 'center' }} >
      {children}
    </div>
  )
}

export function AdvancedRiskDetails({
  tradeImpact,
  marginTradeType,
  allowedSlippage,
  noTrade = false,
  hideInfoTooltips = false,
}: AdvancedRiskDetailsProps) {
  const theme = useTheme()
  const { chainId } = useWeb3React()
  const nativeCurrency = useNativeCurrency()
  const redesignFlag = useRedesignFlag()
  const redesignFlagEnabled = redesignFlag === RedesignVariant.Enabled

  const [hfDelta, hfNew, ltvDelta, ltvNew, hf, ltv] = useMemo(() => {
    return [
      ltvDataToNumber(tradeImpact?.healthFactorDelta), ltvDataToNumber(tradeImpact?.healthFactorNew),
      ltvDataToNumber(tradeImpact?.ltvDelta), ltvDataToNumber(tradeImpact?.ltvNew),
      ltvDataToNumber(tradeImpact?.healthFactor), ltvDataToNumber(tradeImpact?.ltv)]
  }, [tradeImpact])

  return !tradeImpact ? null : (
    <StyledCard>
      <AutoColumn gap="8px">
        <RowBetween>
          <RowFixed>
            <IconContainer>
              <Activity />
            </IconContainer>
            <MouseoverTooltip
              text={
                "If your health factor drips below 1, it will be flagged for liquidation and your position might be closed at unfavorable terms. Always make sure to keep a margin of safety."
              }
              disableHover={hideInfoTooltips}
            >
              <ThemedText.DeprecatedSubHeader color={theme.deprecated_text1}>
                {noTrade ? 'Current HealthFactor' : 'Health Factor Impact'}
              </ThemedText.DeprecatedSubHeader>
            </MouseoverTooltip>
          </RowFixed>
          <RowFixed>
            <RiskRow>
              <TextWithLoadingPlaceholder syncing={hf === 0} width={65}>
                <ThemedText.DeprecatedBlack textAlign="right" fontSize={14}>
                  {hf != 0
                    ? `${formatNumber(hf, 2)}`
                    : '-'}
                </ThemedText.DeprecatedBlack>
              </TextWithLoadingPlaceholder>
              {!noTrade && <>
                <ArrowContainer>
                  <ChevronsRight />
                </ArrowContainer>
                <TextWithLoadingPlaceholder syncing={hfDelta === 0} width={65}>
                  <ThemedText.DeprecatedBlack textAlign="right" fontSize={14} color={hfDelta < 0 ? 'red' : 'green'}>
                    {hfDelta != 0
                      ? `${formatPercent(hfDelta / hf, 2)}`
                      : '-'}
                  </ThemedText.DeprecatedBlack>
                </TextWithLoadingPlaceholder>
                <ArrowContainer>
                  <ChevronsRight />
                </ArrowContainer>
                <TextWithLoadingPlaceholder syncing={hfDelta === 0} width={65}>
                  <ThemedText.DeprecatedBlack textAlign="right" fontSize={14}>
                    {hfNew != 0
                      ? `${formatNumber(hfNew, 2)}`
                      : '-'}
                  </ThemedText.DeprecatedBlack>
                </TextWithLoadingPlaceholder>
              </>}
            </RiskRow>

          </RowFixed>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <IconContainer>
              <CreditCard />
            </IconContainer>
            <MouseoverTooltip
              text={"The impact your trade has on your Loan-To-Value ratio."}
              disableHover={hideInfoTooltips}
            >
              <ThemedText.DeprecatedSubHeader color={theme.deprecated_text1}>
                {noTrade ? 'Current Adjusted LTV' : 'Adjusted LTV Impact'}
              </ThemedText.DeprecatedSubHeader>
            </MouseoverTooltip>
          </RowFixed>
          <RowFixed>
            <RiskRow>
              <TextWithLoadingPlaceholder syncing={ltv === 0} width={50}>
                <ThemedText.DeprecatedBlack textAlign="right" fontSize={14}>
                  {ltv != 0
                    ? `${formatPercent(ltv, 2)}`
                    : '-'}
                </ThemedText.DeprecatedBlack>
              </TextWithLoadingPlaceholder>
              {!noTrade && <>
                <ArrowContainer>
                  <ChevronsRight />
                </ArrowContainer>
                <TextWithLoadingPlaceholder syncing={ltvDelta === 0} width={50}>
                  <ThemedText.DeprecatedBlack textAlign="right" fontSize={14} color={ltvDelta > 0 ? 'red' : 'green'}>
                    {ltvDelta != 0
                      ? `${formatPercentagePoints(ltvDelta, 2)}`
                      : '-'}
                  </ThemedText.DeprecatedBlack>
                </TextWithLoadingPlaceholder>
                <ArrowContainer>
                  <ChevronsRight />
                </ArrowContainer>
                <TextWithLoadingPlaceholder syncing={ltvDelta === 0} width={50}>
                  <ThemedText.DeprecatedBlack textAlign="right" fontSize={14}>
                    {ltvDelta != 0
                      ? `${formatPercent(ltvNew, 2)}`
                      : '-'}
                  </ThemedText.DeprecatedBlack>
                </TextWithLoadingPlaceholder>
              </>}
            </RiskRow>
          </RowFixed>
        </RowBetween>
        <Separator redesignFlag={redesignFlagEnabled} />
      </AutoColumn>
    </StyledCard>
  )
}
