// eslint-disable-next-line no-restricted-imports
import { Trade } from '@uniswap/router-sdk'
import { Currency, CurrencyAmount, Percent, Token, TradeType } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { SwapCallbackState, useSwapCallback as useLibSwapCallBack } from 'lib/hooks/swap/useSwapCallback'
import { useApproval } from 'lib/hooks/useApproval'
import { useBorrowDelegation } from 'lib/hooks/useBorrowDelegation'
import { ReactNode, useMemo } from 'react'
import { Field } from 'state/swap/actions'
import { AaveInterestMode, LendingProtocolInteraction, SupportedAssets } from 'types/1delta'

import { useHasPendingApproval, useTransactionAdder } from '../state/transactions/hooks'
import { TransactionType } from '../state/transactions/types'
import { currencyId } from '../utils/currencyId'
import { safeGetAToken } from './1delta/tokens'
import { ApprovalState, useGetAndTrackApproval } from './useApproveCallback'
import useENS from './useENS'
import { SignatureData } from './useERC20Permit'
import useTransactionDeadline from './useTransactionDeadline'

// returns a function that will execute a swap, if the parameters are all valid
// and the user has approved the slippage adjusted input amount for the trade
export function useSwapCallback(
  trade: Trade<Currency, Currency, TradeType> | undefined, // trade to execute, required
  allowedSlippage: Percent, // in bips
  recipientAddressOrName: string | null, // the ENS name or address of the recipient of the trade, or null if swap should be returned to sender
  signatureData: SignatureData | undefined | null
): { state: SwapCallbackState; callback: null | (() => Promise<string>); error: ReactNode | null } {
  const { account } = useWeb3React()

  const deadline = useTransactionDeadline()

  const addTransaction = useTransactionAdder()

  const { address: recipientAddress } = useENS(recipientAddressOrName)
  const recipient = recipientAddressOrName === null ? account : recipientAddress

  const {
    state,
    callback: libCallback,
    error,
  } = useLibSwapCallBack({
    trade,
    allowedSlippage,
    recipientAddressOrName: recipient,
    signatureData,
    deadline,
  })

  const callback = useMemo(() => {
    if (!libCallback || !trade) {
      return null
    }
    return () =>
      libCallback().then((response) => {
        addTransaction(
          response,
          trade.tradeType === TradeType.EXACT_INPUT
            ? {
              type: TransactionType.SWAP,
              tradeType: TradeType.EXACT_INPUT,
              inputCurrencyId: currencyId(trade.inputAmount.currency),
              inputCurrencyAmountRaw: trade.inputAmount.quotient.toString(),
              expectedOutputCurrencyAmountRaw: trade.outputAmount.quotient.toString(),
              outputCurrencyId: currencyId(trade.outputAmount.currency),
              minimumOutputCurrencyAmountRaw: trade.minimumAmountOut(allowedSlippage).quotient.toString(),
            }
            : {
              type: TransactionType.SWAP,
              tradeType: TradeType.EXACT_OUTPUT,
              inputCurrencyId: currencyId(trade.inputAmount.currency),
              maximumInputCurrencyAmountRaw: trade.maximumAmountIn(allowedSlippage).quotient.toString(),
              outputCurrencyId: currencyId(trade.outputAmount.currency),
              outputCurrencyAmountRaw: trade.outputAmount.quotient.toString(),
              expectedInputCurrencyAmountRaw: trade.inputAmount.quotient.toString(),
            }
        )
        return response.hash
      })
  }, [addTransaction, allowedSlippage, libCallback, trade])

  return {
    state,
    callback,
    error,
  }
}


// wraps useApproveCallback in the context of a swap
export default function useTradeApproval(
  trade: Trade<Currency, Currency, TradeType> | undefined,
  addressToApprove: string,
  allowedSlippage: Percent,
  useIsPendingApproval: (token?: Token, spender?: string) => boolean,
  amount?: CurrencyAmount<Currency> // defaults to trade.maximumAmountIn(allowedSlippage)
) {
  const { chainId } = useWeb3React()

  const amountToApprove = useMemo(
    () => amount || (trade && trade.inputAmount.currency.isToken ? trade.maximumAmountIn(allowedSlippage) : undefined),
    [amount, trade, allowedSlippage]
  )
  const spender = chainId ? addressToApprove : undefined

  return useApproval(amountToApprove, spender, useIsPendingApproval)
}


export function useApproveCallbackFromMarginTrade(
  trade: Trade<Currency, Currency, TradeType> | undefined,
  addressToApprove: string,
  allowedSlippage: Percent
): [ApprovalState, () => Promise<void>] {
  const [approval, getApproval] = useTradeApproval(trade, addressToApprove, allowedSlippage, useHasPendingApproval)
  return [approval, useGetAndTrackApproval(getApproval)]
}


// wraps useApproveCallback in the context of a swap
export function useGeneralTradeApproval(
  trade: Trade<Currency, Currency, TradeType> | undefined,
  lendingInteraction: LendingProtocolInteraction,
  field: Field,
  addressToApprove: string,
  allowedSlippage: Percent,
  useIsPendingApproval: (token?: Token, spender?: string) => boolean,
  amount?: CurrencyAmount<Currency> // defaults to trade.maximumAmountIn(allowedSlippage)
) {
  const { chainId } = useWeb3React()

  const amountToApprove = useMemo(
    () => {
      // for a supply or withdraw the user has to approce the regular spending
      if (lendingInteraction === LendingProtocolInteraction.Supply || lendingInteraction === LendingProtocolInteraction.Repay)
        return amount || (trade && trade.inputAmount.currency.isToken ? trade.maximumAmountIn(allowedSlippage) : undefined)

      if (lendingInteraction === LendingProtocolInteraction.Withdraw) {
        // for a withdraw the user needs to actually approve the aToken
        // the aToken in question is in this case the output token

        let amountAToken: CurrencyAmount<Currency> | undefined = undefined
        if (field === Field.OUTPUT) {
          const aToken = safeGetAToken(
            chainId ?? 5,
            (trade?.outputAmount.currency.symbol ?? 'WETH') as SupportedAssets
          )
          amountAToken = CurrencyAmount.fromRawAmount(
            aToken,
            amount?.quotient ?? '0'
          )
          if (trade && trade.outputAmount.currency.isToken) {

            amountAToken = CurrencyAmount.fromRawAmount(
              aToken,
              trade.maximumAmountIn(allowedSlippage).quotient.toString()
            )
          }
        } else {
          const aToken = safeGetAToken(
            chainId ?? 5,
            (trade?.inputAmount.currency.symbol ?? 'WETH') as SupportedAssets
          )
          amountAToken = CurrencyAmount.fromRawAmount(
            aToken,
            amount?.quotient ?? '0'
          )
          if (trade && trade.inputAmount.currency.isToken) {

            amountAToken = CurrencyAmount.fromRawAmount(
              aToken,
              trade.maximumAmountIn(allowedSlippage).quotient.toString()
            )
          }

        }
        return amountAToken as CurrencyAmount<Currency>
      }
      return undefined
    },
    [amount, trade, allowedSlippage, lendingInteraction]
  )
  const spender = chainId ? addressToApprove : undefined

  return useApproval(amountToApprove, spender, useIsPendingApproval)
}

export function useApproveCallbackFromGeneralTrade(
  trade: Trade<Currency, Currency, TradeType> | undefined,
  lendingInteraction: LendingProtocolInteraction,
  field: Field,
  addressToApprove: string,
  allowedSlippage: Percent,
): [ApprovalState, () => Promise<void>] {
  const [approval, getApproval] = useGeneralTradeApproval(trade, lendingInteraction, field, addressToApprove, allowedSlippage, useHasPendingApproval)
  return [approval, useGetAndTrackApproval(getApproval)]
}

// wraps useApproveCallback in the context of a swap
export function useGeneralTradeBorrowDelegation(
  trade: Trade<Currency, Currency, TradeType> | undefined,
  lendingInteraction: LendingProtocolInteraction,
  field: Field,
  addressToApprove: string,
  allowedSlippage: Percent,
  useIsPendingApproval: (token?: Token, spender?: string) => boolean,
  interestMode: AaveInterestMode,
  amount?: CurrencyAmount<Currency>
) {
  const { chainId } = useWeb3React()

  const amountToApprove = useMemo(
    () => {
      if (lendingInteraction != LendingProtocolInteraction.Borrow)
        return undefined
      else {
        if (field === Field.OUTPUT)
          return amount || (trade && trade.outputAmount.currency.isToken ? trade.minimumAmountOut(allowedSlippage) : undefined)
        else
          return amount || (trade && trade.inputAmount.currency.isToken ? trade.maximumAmountIn(allowedSlippage) : undefined)
      }
    },
    [amount, trade, allowedSlippage, lendingInteraction]
  )
  const spender = chainId ? addressToApprove : undefined
  return useBorrowDelegation(interestMode, amountToApprove, spender, useIsPendingApproval)
}



export function useDelegateBorrowCallbackFromGeneralTrade(
  trade: Trade<Currency, Currency, TradeType> | undefined,
  lendingInteraction: LendingProtocolInteraction,
  field: Field,
  addressToApprove: string,
  allowedSlippage: Percent,
  interestMode: AaveInterestMode
): [ApprovalState, () => Promise<void>] {
  const [approval, getApproval] = useGeneralTradeBorrowDelegation(trade, lendingInteraction, field, addressToApprove, allowedSlippage, useHasPendingApproval, interestMode)
  return [approval, useGetAndTrackApproval(getApproval)]
}

