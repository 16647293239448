import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { addressesAaveATokens, addressesAaveSTokens, addressesAaveTokens, addressesAaveVTokens, getAddressesForChainIdFromAssetDict } from 'hooks/1delta/addresses'
import { getAavePoolContract, getAavePoolDataProviderContract } from 'hooks/1delta/use1DeltaContract'
import { SerializedBigNumber, SupportedAssets } from 'types/1delta'
import multicall, { Call } from 'utils/multicall'
import POOL_ABI from '../../hooks/1delta/abis/AAVEPoolV3.json'
import AAVE_POOL_DATA_PROVIDER_ABI from '../../hooks/1delta/abis/AAVEProtocolDataProvider.json'
import A_TOKEN_API from '../../hooks/1delta/abis/AToken.json'

export interface AAVEPoolV3UserResponse {
  totals: {
    totalCollateralBase: SerializedBigNumber
    totalDebtBase: SerializedBigNumber
    availableBorrowsBase: SerializedBigNumber
    currentLiquidationThreshold: SerializedBigNumber
    ltv: SerializedBigNumber
    healthFactor: SerializedBigNumber
    data: SerializedBigNumber
  }
  data: {
    [tokenSymbol: string]: {
      supplyBalance?: SerializedBigNumber
      borrowBalanceStable?: SerializedBigNumber
      borrowBalanceVariable?: SerializedBigNumber
    }
  }
}

export interface AAVEPoolV3UserQueryParams {
  chainId: number
  account: string
}

export const fetchAAVEUserDataAsync:
  AsyncThunk<AAVEPoolV3UserResponse, AAVEPoolV3UserQueryParams, any> =
  createAsyncThunk<AAVEPoolV3UserResponse, AAVEPoolV3UserQueryParams>(
    '1delta/fetchAAVEUserDataAsync',

    async ({ chainId, account }) => {
      const aTokens = Object.values(getAddressesForChainIdFromAssetDict(addressesAaveATokens, chainId))

      const names = Object.keys(getAddressesForChainIdFromAssetDict(addressesAaveATokens, chainId))

      const callsBase: Call[] = aTokens.map(tk => {
        return {
          address: tk,
          name: 'balanceOf',
          params: [account]
        }
      })

      const vTokens = Object.values(getAddressesForChainIdFromAssetDict(addressesAaveVTokens, chainId))
      const callsVariableDebt: Call[] = vTokens.map(tk => {
        return {
          address: tk,
          name: 'balanceOf',
          params: [account]
        }
      })

      const sTokens = Object.values(getAddressesForChainIdFromAssetDict(addressesAaveSTokens, chainId))
      const callsStableDebt: Call[] = sTokens.map(tk => {
        return {
          address: tk,
          name: 'balanceOf',
          params: [account]
        }
      })

      const calls = [
        ...callsBase,
        ...callsVariableDebt,
        ...callsStableDebt
      ]

      const multicallResult: any[] = await multicall(
        chainId,
        A_TOKEN_API,
        calls
      )
      const resA = multicallResult.slice(0, callsBase.length)
      const resV = multicallResult.slice(callsBase.length, 2 * callsBase.length)
      const resS = multicallResult.slice(2 * callsBase.length, multicallResult.length)

      // map arrays to objects
      const result = Object.assign({}, ...resA.map((entry, index) => {
        return {
          [names[index]]: {
            supplyBalance: entry[0]?.toString(),
            borrowBalanceStable: resV[index]?.toString(),
            borrowBalanceVariable: resS[index]?.toString(),
          }
        }
      }
      ))

      const poolContract = getAavePoolContract(chainId)

      const callData = {
        address: poolContract.address,
        name: 'getUserAccountData',
        params: [account]
      }

      const callConfig = {
        address: poolContract.address,
        name: 'getUserConfiguration',
        params: [account]
      }

      const [resultData, resultConfig] = await multicall(
        chainId,
        POOL_ABI,
        [callData, callConfig]
      )

      return {
        totals: {
          totalCollateralBase: resultData?.totalCollateralBase.toString(),
          totalDebtBase: resultData?.totalDebtBase.toString(),
          availableBorrowsBase: resultData?.availableBorrowsBase.toString(),
          currentLiquidationThreshold: resultData?.currentLiquidationThreshold.toString(),
          ltv: resultData?.ltv.toString(),
          healthFactor: resultData?.healthFactor.toString(),
          data: resultConfig[0]?.data.toHexString(),
        },
        data: result
      }
    }
  )



export interface AAVEPoolV3UserReserveResponse {
  data: {
    [tokenSymbol: string]: {
      currentATokenBalance: SerializedBigNumber
      currentStableDebt: SerializedBigNumber
      currentVariableDebt: SerializedBigNumber
      principalStableDebt: SerializedBigNumber
      scaledVariableDebt: SerializedBigNumber
      stableBorrowRate: SerializedBigNumber
      liquidityRate: SerializedBigNumber
      stableRateLastUpdated: number
      usageAsCollateralEnabled: boolean
    }
  }
}

export interface AAVEPoolV3UserReservesQueryParams {
  chainId: number
  account: string
  assetsToQuery: SupportedAssets[]
}

export const fetchAAVEUserReserveDataAsync:
  AsyncThunk<AAVEPoolV3UserReserveResponse, AAVEPoolV3UserReservesQueryParams, any> =
  createAsyncThunk<AAVEPoolV3UserReserveResponse, AAVEPoolV3UserReservesQueryParams>(
    '1delta/fetchAAVEUserReserveDataAsync',

    async ({ chainId, account, assetsToQuery }) => {
      const providerContract = getAavePoolDataProviderContract(chainId)
      const assets = assetsToQuery.map(a => getAddressesForChainIdFromAssetDict(addressesAaveTokens, chainId)[a])

      const names = assetsToQuery

      const calls: Call[] = assets.map(tk => {
        return {
          address: providerContract.address,
          name: 'getUserReserveData',
          params: [tk, account]
        }
      })

      const multicallResult: any[] = await multicall(
        chainId,
        AAVE_POOL_DATA_PROVIDER_ABI,
        calls
      )

      const result: any = Object.assign({}, ...multicallResult.map((entry: any, index) => {
        return {
          [names[index]]: {
            currentATokenBalance: entry?.currentATokenBalance.toString(),
            currentStableDebt: entry?.currentStableDebt.toString(),
            currentVariableDebt: entry?.currentVariableDebt.toString(),
            principalStableDebt: entry?.principalStableDebt.toString(),
            scaledVariableDebt: entry?.scaledVariableDebt.toString(),
            stableBorrowRate: entry?.stableBorrowRate.toString(),
            liquidityRate: entry?.liquidityRate.toString(),
            stableRateLastUpdated: entry?.stableRateLastUpdated,
            usageAsCollateralEnabled: entry?.usageAsCollateralEnabled
          }
        }
      }
      ))
      return {
        data: result
      }
    }
  )