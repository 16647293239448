import { useWeb3React } from '@web3-react/core'
import { useContract } from 'hooks/useContract'
import { Pool } from './types/Pool'
import AAVE_POOL_ABI from './abis/AAVEPoolV3.json'
import AAVE_POOL_DATA_PROVIDER_ABI from './abis/AAVEProtocolDataProvider.json'
import MARGIN_TRADER_ABI from './abis/MarginTrading.json'
import STABLE_DEBT_TOKEN from './abis/StableDebtToken.json'
import MONEY_MARKET_ABI from './abis/MoneyMarketOperator.json'
import { addressesAaveCore, addressesAaveCoreGoerli, addressesAaveGeneralGoerli } from './addresses'
import { getContract } from 'utils'
import { Contract } from 'ethers'
import { RPC_PROVIDERS } from '../../constants/providers'
import { SupportedChainId } from 'constants/chains'
import { diamondBrokerAddress } from './addresses1Delta'
import { AaveProtocolDataProvider } from './types/AaveProtocolDataProvider'

const defaultAddress = '0x41E9a4801D7AE2f032cF37Bf262339Eddd00a06c'
// account is optional
export function getAavePoolContract(chainId: number, account?: string, library?: any): Contract {
  return getContract(
    chainId === 5 ? addressesAaveCoreGoerli.PoolProxyAave[chainId] : defaultAddress,
    AAVE_POOL_ABI,
    library,
    account
  ) as Pool
}

// account is not optional
export function getMarginTraderContract(chainId: number, library: any, account?: string): Contract {
  return getContract(
    diamondBrokerAddress[chainId] ?? defaultAddress,
    MARGIN_TRADER_ABI,
    library,
    account
  )
}

// account is not optional
export function getMoneyMarketOperatorContract(chainId: number, library: any, account?: string): Contract {
  return getContract(
    diamondBrokerAddress[chainId] ?? defaultAddress,
    MONEY_MARKET_ABI,
    library,
    account
  )
}

// account is not optional
export function getDebtTokenContract(chainId?: number, address?: string, library?: any, account?: string): Contract | undefined {
  if (!chainId || !address) return undefined
  return getContract(
    address,
    STABLE_DEBT_TOKEN, // the relevant functions are the same for stable and variable debt tokens
    library,
    account
  )
}

export function getAavePoolDataProviderContract(chainId: number, account?: string): Contract {
  return getContract(
    chainId === 5 ? addressesAaveGeneralGoerli?.PoolDataProviderAave[chainId] : defaultAddress,
    AAVE_POOL_DATA_PROVIDER_ABI,
    RPC_PROVIDERS[chainId as SupportedChainId],
    account
  ) as AaveProtocolDataProvider
}



