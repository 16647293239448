import { RouteV3 } from "@uniswap/router-sdk"
import { Currency, CurrencyAmount, MaxUint256, TradeType } from "@uniswap/sdk-core"
import { ADDRESS_ZERO } from "@uniswap/v3-sdk"
import { Contract } from "ethers"
import { Field } from "state/swap/actions"
import { AaveInterestMode, encodePath, LendingProtocolInteraction, PositionSides } from "types/1delta"

export interface SimpleTransactioResponse {
  hash: string
}

type ContractCall = () => Promise<SimpleTransactioResponse>;

export interface ContractCallData {
  args: any
  method: any
  estimate: any
  call?: ContractCall | undefined
}

export const createMarginTradeCalldata = (
  parsedAmounts: {
    INPUT?: CurrencyAmount<Currency> | undefined;
    OUTPUT?: CurrencyAmount<Currency> | undefined;
  },
  marginTraderContract: Contract,
  sideIn: PositionSides,
  borrowInterestMode: AaveInterestMode,
  account?: string,
  v3Route?: RouteV3<Currency, Currency>,
  tradeType?: TradeType
): ContractCallData => {

  let args: any = {}
  let method: any
  let estimate: any
  let contractCall: ContractCall | undefined = undefined

  if (!v3Route || (tradeType == undefined) || !account) return {
    args: {}, method: undefined, estimate: undefined
  }

  const hasOnePool = v3Route.pools.length === 1

  if (sideIn === PositionSides.Borrow) { // Increase or create position
    if (tradeType === TradeType.EXACT_INPUT) {
      if (hasOnePool) {
        args = v3Route && {
          tokenIn: v3Route.input.wrapped.address,
          tokenOut: v3Route.output.wrapped.address,
          fee: v3Route.pools[0].fee,
          amountIn: parsedAmounts?.[Field.INPUT] && parsedAmounts?.[Field.INPUT]?.quotient.toString(),
          amountOutMinimum: 0,
          sqrtPriceLimitX96: 0,
          userAmountProvided: 0,
          interestRateMode: borrowInterestMode
        }
        method = marginTraderContract.openMarginPositionExactIn
        estimate = marginTraderContract.estimateGas.openMarginPositionExactIn
        contractCall = async () => await marginTraderContract.openMarginPositionExactIn(args)
      } else {
        args = v3Route && {
          path: encodePath(v3Route.path.map(p => p.address), v3Route.pools.map(pool => pool.fee)),
          amountIn: parsedAmounts?.[Field.INPUT] && parsedAmounts?.[Field.INPUT]?.quotient.toString(),
          userAmountProvided: 0,
          sqrtPriceLimitX96: 0,
          interestRateMode: borrowInterestMode
        }
        method = marginTraderContract.openMarginPositionExactInMulti
        estimate = marginTraderContract.estimateGas.openMarginPositionExactInMulti
        contractCall = async () => await marginTraderContract.openMarginPositionExactInMulti(args)
      }
    } else {
      if (hasOnePool) {
        args = v3Route && {
          tokenIn: v3Route.input.wrapped.address,
          tokenOut: v3Route.output.wrapped.address,
          fee: v3Route.pools[0].fee,
          amountOut: parsedAmounts?.[Field.OUTPUT] && parsedAmounts?.[Field.OUTPUT]?.quotient.toString(),
          userAmountProvided: 0,
          sqrtPriceLimitX96: 0,
          interestRateMode: borrowInterestMode
        }
        method = marginTraderContract.openMarginPositionExactOut
        estimate = marginTraderContract.estimateGas.openMarginPositionExactOut
        contractCall = async () => await marginTraderContract.openMarginPositionExactOut(args)
      } else {
        const structInp = v3Route && {
          path: encodePath(v3Route.path.map(p => p.address).reverse(), v3Route.pools.map(pool => pool.fee).reverse()),
          amountOut: parsedAmounts?.[Field.OUTPUT] && parsedAmounts?.[Field.OUTPUT]?.quotient.toString(),
          userAmountProvided: 0,
          sqrtPriceLimitX96: '0',
          interestRateMode: borrowInterestMode
        }
        args = v3Route && [
          structInp
        ]
        method = marginTraderContract.openMarginPositionExactOutMulti
        estimate = marginTraderContract.estimateGas.openMarginPositionExactOutMulti
        contractCall = async () => await marginTraderContract.openMarginPositionExactOutMulti(structInp)
      }
    }
  }
  // trimming positions
  else {
    if (tradeType === TradeType.EXACT_INPUT) {
      if (hasOnePool) {
        args = v3Route && {
          tokenIn: v3Route.input.wrapped.address,
          tokenOut: v3Route.output.wrapped.address,
          fee: v3Route.pools[0].fee,
          amountIn: parsedAmounts?.[Field.INPUT] && parsedAmounts?.[Field.INPUT]?.quotient.toString(),
          userAmountProvided: 0,
          sqrtPriceLimitX96: 0,
          interestRateMode: borrowInterestMode
        }
        method = marginTraderContract.trimMarginPositionExactIn
        estimate = marginTraderContract.estimateGas.trimMarginPositionExactIn
        contractCall = async () => await marginTraderContract.trimMarginPositionExactIn(args)
      } else {
        args = v3Route && {
          path: encodePath(v3Route.path.map(p => p.address), v3Route.pools.map(pool => pool.fee)),
          amountIn: parsedAmounts?.[Field.INPUT] && parsedAmounts?.[Field.INPUT]?.quotient.toString(),
          userAmountProvided: 0,
          sqrtPriceLimitX96: 0,
          interestRateMode: borrowInterestMode
        }
        method = marginTraderContract.trimMarginPositionExactInMulti
        estimate = marginTraderContract.estimateGas.trimMarginPositionExactInMulti
        contractCall = async () => await marginTraderContract.trimMarginPositionExactInMulti(args)
      }
    }
    else {
      if (hasOnePool) {
        args = v3Route && {
          tokenIn: v3Route.input.wrapped.address,
          tokenOut: v3Route.output.wrapped.address,
          fee: v3Route.pools[0].fee,
          amountOut: parsedAmounts?.[Field.OUTPUT] && parsedAmounts?.[Field.OUTPUT]?.quotient.toString(),
          userAmountProvided: 0,
          sqrtPriceLimitX96: 0,
          interestRateMode: borrowInterestMode
        }
        method = marginTraderContract.trimMarginPositionExactOut
        estimate = marginTraderContract.estimateGas.trimMarginPositionExactOut
        contractCall = async () => await marginTraderContract.trimMarginPositionExactOut(args)

      } else {
        const interestRateMode = borrowInterestMode
        const structInp = v3Route && {
          path: encodePath(v3Route.path.map(p => p.address).reverse(), v3Route.pools.map(pool => pool.fee).reverse()),
          amountOut: parsedAmounts?.[Field.OUTPUT] && parsedAmounts?.[Field.OUTPUT]?.quotient.toString(),
          interestRateMode,
          userAmountProvided: 0,
          sqrtPriceLimitX96: '0'
        }
        args = [structInp]
        method = marginTraderContract.trimMarginPositionExactOutMulti
        estimate = marginTraderContract.estimateGas.trimMarginPositionExactOutMulti
        contractCall = async () => await marginTraderContract.trimMarginPositionExactOutMulti(structInp)
      }
    }
  }

  return { args, method, estimate, call: contractCall }
}