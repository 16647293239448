import { MaxUint256 } from '@ethersproject/constants'
import type { TransactionResponse } from '@ethersproject/providers'
import { Currency, CurrencyAmount, Token } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { sendAnalyticsEvent } from 'analytics'
import { EventName } from 'analytics/constants'
import { getTokenAddress } from 'analytics/utils'
import { Contract } from 'ethers'
import { safeGetBorrowToken } from 'hooks/1delta/tokens'
import { StableDebtToken } from 'hooks/1delta/types/StableDebtToken'
import { getDebtTokenContract } from 'hooks/1delta/use1DeltaContract'
import { useBorrowAllowance } from 'hooks/useBorrowAllowance'
import { useTokenContract } from 'hooks/useContract'
import { useTokenAllowance } from 'hooks/useTokenAllowance'
import { useCallback, useMemo } from 'react'
import { AaveInterestMode, SupportedAssets } from 'types/1delta'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { ApprovalState } from './useApproval'


// we use the approval state also for the borrow delegation
function useBorrowDelegationStateForSpender(
  amountToApprove: CurrencyAmount<Currency> | undefined,
  spender: string | undefined,
  useIsPendingApproval: (token?: Token, spender?: string) => boolean
): ApprovalState {
  const { account } = useWeb3React()
  const token = amountToApprove?.currency?.isToken ? amountToApprove.currency : undefined

  const currentAllowance = useBorrowAllowance(token, account ?? undefined, spender)
  const pendingApproval = useIsPendingApproval(token, spender)

  return useMemo(() => {
    if (!amountToApprove || !spender) return ApprovalState.UNKNOWN
    if (amountToApprove.currency.isNative) return ApprovalState.APPROVED
    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance) return ApprovalState.UNKNOWN

    // amountToApprove will be defined if currentAllowance is
    return currentAllowance.lessThan(amountToApprove)
      ? pendingApproval
        ? ApprovalState.PENDING
        : ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED
  }, [amountToApprove, currentAllowance, pendingApproval, spender])
}

export function useBorrowDelegation(
  interestMode: AaveInterestMode,
  amountToApprove: CurrencyAmount<Currency> | undefined,
  spender: string | undefined,
  useIsPendingApproval: (token?: Token, spender?: string) => boolean
): [
    ApprovalState,
    () => Promise<{ response: TransactionResponse; tokenAddress: string; spenderAddress: string } | undefined>
  ] {
  const { chainId, provider, account } = useWeb3React()
  const token = safeGetBorrowToken(chainId ?? 5, (amountToApprove?.currency.symbol as SupportedAssets) ?? 'WETH', interestMode)
  const borrowTokenAmountToApprove = token && amountToApprove?.denominator && CurrencyAmount.fromRawAmount(token, amountToApprove?.quotient.toString())
  // check the current approval status
  const borrowApprovalState = useBorrowDelegationStateForSpender(
    borrowTokenAmountToApprove,
    spender,
    useIsPendingApproval
  )
  // has extended functions 
  const tokenContract = getDebtTokenContract(chainId, token?.address, provider, account) as StableDebtToken

  const approveBorrowDelegation = useCallback(async () => {
    function logFailure(error: Error | string): undefined {
      console.warn(`${token?.symbol || 'Token'} approval failed:`, error)
      return
    }

    // Bail early if there is an issue.
    if (borrowApprovalState !== ApprovalState.NOT_APPROVED) {
      return logFailure('approve was called unnecessarily')
    } else if (!chainId) {
      return logFailure('no chainId')
    } else if (!token) {
      return logFailure('no token')
    } else if (!tokenContract) {
      return logFailure('tokenContract is null')
    } else if (!borrowTokenAmountToApprove) {
      return logFailure('missing borrow samount to approve')
    } else if (!spender) {
      return logFailure('no spender')
    }

    let useExact = false
    const estimatedGas = await tokenContract.estimateGas.approveDelegation(spender, MaxUint256).catch(() => {
      // general fallback for tokens which restrict approval amounts
      useExact = true
      return tokenContract.estimateGas.approveDelegation(spender, borrowTokenAmountToApprove.quotient.toString())
    })

    return tokenContract
      .approveDelegation(spender, useExact ? borrowTokenAmountToApprove.quotient.toString() : MaxUint256, {
        gasLimit: calculateGasMargin(estimatedGas),
      })
      .then((response: any) => {
        const eventProperties = {
          chain_id: chainId,
          token_symbol: token?.symbol,
          token_address: getTokenAddress(token),
        }
        sendAnalyticsEvent(EventName.APPROVE_TOKEN_TXN_SUBMITTED, eventProperties)
        return {
          response,
          tokenAddress: token.address,
          spenderAddress: spender,
        }
      })
      .catch((error: Error) => {
        logFailure(error)
        throw error
      })
  }, [borrowApprovalState, token, tokenContract, amountToApprove, spender, chainId])

  return [borrowApprovalState, approveBorrowDelegation]
}
