import { Currency, Token } from "@uniswap/sdk-core"
import { getAssets, TOKEN_META } from "constants/1delta"
import { AaveInterestMode, SupportedAssets } from "types/1delta"
import { addressesAaveATokens, addressesAaveSTokens, addressesAaveTokens, addressesAaveVTokens } from "./addresses"

export const getAaveTokens = (chainId: number): { [address: string]: Token } => {
  return Object.assign({}, ...getAssets(chainId).map(asset => {
    return {
      [asset]: safeGetToken(chainId, asset)
    }
  }))
}

export const safeGetToken = (chainId: number, asset: SupportedAssets): Token => {
  return new Token(
    chainId,
    addressesAaveTokens[asset][chainId],
    TOKEN_META[asset].decimals,
    TOKEN_META[asset].symbol,
    TOKEN_META[asset].name
  )
}

export const safeGetAToken = (chainId: number, asset: SupportedAssets): Token => {
  return new Token(
    chainId,
    addressesAaveATokens[asset][chainId],
    TOKEN_META[asset].decimals,
    TOKEN_META[asset].symbol,
    TOKEN_META[asset].name
  )
}

export const safeGetBorrowToken = (chainId: number, asset: SupportedAssets, interestMode: AaveInterestMode): Token | undefined => {
  if (interestMode === AaveInterestMode.STABLE) {
    return new Token(
      chainId,
      addressesAaveSTokens[asset][chainId],
      TOKEN_META[asset].decimals,
      TOKEN_META[asset].symbol,
      TOKEN_META[asset].name
    )
  }

  if (interestMode === AaveInterestMode.VARIABLE) {
    return new Token(
      chainId,
      addressesAaveVTokens[asset][chainId],
      TOKEN_META[asset].decimals,
      TOKEN_META[asset].symbol,
      TOKEN_META[asset].name
    )
  }

  return undefined
}


export const filterSupportedAssets = (token0?: Currency, token1?: Currency): SupportedAssets[] => {
  const chainId = token0?.chainId ?? 5
  const arr: SupportedAssets[] = []
  if (token0 && safeGetToken(chainId, token0?.symbol as SupportedAssets).equals(token0))
    arr.push(token0?.symbol as SupportedAssets)

  if (token1 && safeGetToken(chainId, token1?.symbol as SupportedAssets).equals(token1))
    arr.push(token1?.symbol as SupportedAssets)

  return arr
}



export const isSupportedAsset = (chainId?: number, currency?: Currency | null | undefined): boolean => {
  if (!chainId || !currency) return false
  try {
    safeGetToken(chainId, currency.symbol as SupportedAssets)
    return true
  } catch (err) {
    // console.log(err)
    return false
  }
}